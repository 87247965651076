










































































































import { Component, Emit, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Emulator } from '@/models/EmulatorModels';
import AircraftType from '@/enums/user/aircraft';
import ImageFileMixin from '@/mixins/ImageFileMixin';
import { ImageType } from '@/enums/globalUI';
import { fetchMasterEmulatorByID, isProd } from '@/utils/common';
import { uuid } from 'vue-uuid';
import { mixins } from 'vue-class-component';

const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    ImageFileMixin,
  },
})
export default class EmulatorCreationForm extends mixins(ImageFileMixin) {

  @emulatorModule.Getter
  public emulators!: Emulator[];

  @emulatorModule.Getter
  public currentEmulator!: Emulator | null;

  @emulatorModule.Mutation
  public setCurrentEmulator!: (Emulator) => void;

  @emulatorModule.Action
  public createEmulator!: (emulator: Emulator) => Promise<void>;

  @emulatorModule.Action
  public fetchEmulators!: () => Promise<void>;

  @emulatorModule.Action
  public fetchEmulator!: (emulatorId: string) => Promise<void>;

  public isFormValid: boolean = false;

  public title: string = '';
  public description: string = '';
  public aircraft: any = null;
  public isEditable: boolean = true;
  public cloneTemplateId: string = '';
  public isSavingEmulator: boolean = false;

  public titleRules: any[] = [
    (v) => !!v || this.getErrorMessage('common.error.fieldRequired'),
    (v) => (v && v.length >= 3) || this.getErrorMessage('common.error.minCharacters', { number: 3 }),
    (v) => (v && v.length <= 100) || this.getErrorMessage('common.error.maxCharacters', { number: 100 }),
  ];

  public descriptionRules: any[] = [
    (v) => !!v || this.getErrorMessage('common.error.fieldRequired'),
  ];

  public aircraftRules: any[] = [
    (v) => !!v || this.getErrorMessage('common.error.fieldRequired'),
  ];

  public get aircraftItems(): any[] {
    const prodAircraft = [
      {
        id: AircraftType.G_650,
        name: AircraftType.getAircraftLabelByType(AircraftType.G_650),
      },
      {
        id: AircraftType.G_550,
        name: AircraftType.getAircraftLabelByType(AircraftType.G_550),
      },
      {
        id: AircraftType.G_450,
        name: AircraftType.getAircraftLabelByType(AircraftType.G_450),
      },
    ];
    if (!isProd()) {
      prodAircraft.push(
        {
          id: AircraftType.UFO,
          name: AircraftType.getAircraftLabelByType(AircraftType.UFO),
        },
      );
    }
    return prodAircraft;

  }

  public getErrorMessage(key: string, args?: any): string {
    return this.$t(key, args) as string;
  }

  @Emit()
  public onCloseHandler() {
    this.resetForm();
    return;
  }

  public async onSaveClick() {
    this.isSavingEmulator = true;
    this.isFormValid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.isFormValid) {
      const cleanTitle = this.title.replace(/[^a-zA-Z0-9 ]/g, '')
      const id = cleanTitle.replace(/ /g, '-').toLowerCase() + '-' + `${Date.now()}`
      const newEmulator: Emulator = {
        id: id,
        title: this.title,
        description: this.description,
        aircraftModel: this.aircraft,
        canBeModified: this.isEditable,
        cloneId: this.cloneTemplateId,
        imageUrl: this.currentFile?.url ? this.currentFile?.url : undefined,
      };

      if (this.aircraft !== AircraftType.UFO) {
        if (this.cloneTemplateId) {
          await this.fetchEmulator(this.cloneTemplateId);
          if (this.currentEmulator) {
            newEmulator.panes = this.currentEmulator.panes;
            newEmulator.state = this.currentEmulator.state;
            this.setCurrentEmulator(null);
          }
        }
      } else {
        const ufoEmulator = await fetchMasterEmulatorByID('ufo-base');
        newEmulator.id = uuid.v1();
        newEmulator.cloneId = 'ufo-base';
        newEmulator.panes = ufoEmulator.panes;
        newEmulator.state = ufoEmulator.state;
      }

      await this.createEmulator(newEmulator)
      if (this.imageFile !== null) {
        await this.uploadFile(ImageType.EMULATOR, id);
      }
      await this.fetchEmulators();
      this.onCloseHandler();
    }
    this.isSavingEmulator = false;
  }

  private get filteredEmulators(): Emulator[] {
    return this.emulators.filter((emulator: Emulator) => {
      return emulator.aircraftModel === this.aircraft;
    });
  }

  private resetForm() {
    if (this.$refs.form) {
      this.isFormValid = (this.$refs.form as Vue & { reset: () => boolean }).reset();
      this.isEditable = true;
    }
  }
}
