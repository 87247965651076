
























































import { Vue, Component, Emit } from 'vue-property-decorator';
import { CourseTemplate } from '@/models/CoursesModels';
import { namespace } from 'vuex-class';
import DateSelectorControl from '@/components/shared/DateSelectorControl.vue';
import { User } from '@/models/UserModels';
import AircraftType from '@/enums/user/aircraft';
import { Course } from '@/models/CoursesModels';
import RuleUtility from '@/utils/rules';
import moment from 'moment';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import UserRoleType from '@/enums/user';
import AutoCompleteControl from '@/components/shared/AutoCompleteControl.vue';
import { mixins } from 'vue-class-component';

const userModule = namespace('UserModule');
const coursesTemplateModule = namespace('CoursesTemplateModule');

@Component({
  components: {
    DateSelectorControl,
    AutoCompleteControl,
  },
})
export default class CoursePlannerForm extends mixins(ResponsiveMixin) {

  get AircraftType() {
    return AircraftType;
  }

  public ruleUtility = RuleUtility;

  @userModule.Getter
  public currentUser!: User;

  @coursesTemplateModule.Getter
  public autoCompleteTemplates!: CourseTemplate[];

  @coursesTemplateModule.Mutation
  public setResearchTemplates!: (templates: CourseTemplate[]) => Promise<void>;

  @coursesTemplateModule.Action
  public researchCourseTemplates!: (searchTerm: string) => Promise<void>;

  @userModule.Getter
  public autoCompleteUsers!: User[];

  @userModule.Action
  public searchAutoCompleteUsers!: (params?: string) => Promise<void>;

  public filteredInstructors: User[] = [];
  public filteredResearchTemplates: CourseTemplate[] = [];

  private isFormValid: boolean = false;

  private selectedCourseTemplate: CourseTemplate | null = null;
  private dates: string[] = [];
  private instructorIds: string[] = [];

  @Emit('onCancel')
  public cancel(): void {
    return;
  }

  @Emit('onSave')
  public save(course: Course): Course {
    return course;
  }

  private created(): void {
    this.fetchCourseTemplates('');
  }

  private beforeDestroy(): void {
    this.reset();
    this.setResearchTemplates([]);
  }

  private async fetchCourseTemplates(searchTerm: string): Promise<void> {
    await this.researchCourseTemplates(searchTerm);
    this.filteredResearchTemplates = (this.autoCompleteTemplates).filter((template: CourseTemplate) => {
      return UserRoleType.hasAccessForAircraft(
        template.aircraftModel as string,
        [UserRoleType.INSTRUCTOR, UserRoleType.FLEET_CHIEF],
        this.currentUser.roles);
    });
  }

  private async selectTemplate(item: CourseTemplate): Promise<void> {
    this.selectedCourseTemplate = item;
    await this.fetchInstructors('');
  }

  private async fetchInstructors(searchTerm: string): Promise<void> {
    await this.searchAutoCompleteUsers(searchTerm);
    this.filteredInstructors = (this.autoCompleteUsers as User[]).filter((user: User) => {
      return UserRoleType.hasAccessForAircraft(
        this.selectedCourseTemplate?.aircraftModel as string,
        [UserRoleType.INSTRUCTOR, UserRoleType.FLEET_CHIEF],
        user.roles);
    });
  }

  private async selectInstructors(items: User[]): Promise<void> {
    this.instructorIds = items.map((item: User) => {
      return item.id;
    });
  }

  private getInstructorName(item: User): string {
    return item.givenName ? item.givenName : item.email;
  }

  private onSaveClick(): void {
    let course: Course;
    this.isFormValid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.isFormValid && this.selectedCourseTemplate) {
      course = {
        courseId: this.selectedCourseTemplate.courseIdPrefix + ' '
          + (moment(this.dates[0]).format('DD-MMM-YYYY')).toLowerCase(),
        courseTemplateId: this.selectedCourseTemplate.id!,
        startDate: moment(this.dates[0]).toISOString(),
        endDate: moment(this.dates[1]).toISOString(),
        instructorIds: this.instructorIds,
        enabledEmulatorIds: [],
        archived: false,
      };
      this.save(course);
    }
  }

  private reset(): void {
    (this.$refs.form as HTMLFormElement).reset();
  }
}
