import Vue from 'vue';
import VueMermaid from 'vue-mermaid';

import '@/lib-config/axios'; // do not remove
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';

import App from './App.vue';
import router from './router';
import store from './store/store';

// import './registerServiceWorker';

Vue.use(VueMermaid as any);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
