import i18n from '@/plugins/i18n';
import {isProd} from '@/utils/common';

export default class AircraftType {
  public static G_450: string = 'g450';
  public static G_550: string = 'g550';
  public static G_650: string = 'g650';
  public static UFO: string = 'ufo';

  public static getAircraft(): string[] {
    const prodAircraft = [
      AircraftType.G_450,
      AircraftType.G_550,
      AircraftType.G_650,
    ];
    if (!isProd()) {
      prodAircraft.push(AircraftType.UFO);
    }
    return prodAircraft;
  }

  public static getAircraftLabelByType = (aircraftType: string): string => {
    switch (aircraftType) {
      case AircraftType.G_650:
        return i18n.t('common.aircraft.g650').toString();

      case AircraftType.G_550:
        return i18n.t('common.aircraft.g550').toString();

      case AircraftType.G_450:
        return i18n.t('common.aircraft.g450').toString();

      case AircraftType.UFO:
        return i18n.t('common.aircraft.ufo').toString();

      default:
        return 'Unknown Aircraft';
    }
  }
}
