























import { Vue, Component, Prop } from 'vue-property-decorator';
import { UserProfile } from '@/models/UserModels';

@Component({})
export default class ProfileAvatar extends Vue {

  @Prop({
    default: '',
  })
  public url!: string;

  @Prop({
    required: true,
  })
  public userProfile!: UserProfile;

  @Prop({
    default: 48,
  })
  public size!: number;

  @Prop({
    default: false,
  })
  public forceProfileText!: boolean;

  private get label(): string {
    let label: string = '';
    if (this.userProfile.firstName) {
      label = this.userProfile.firstName.charAt(0) + this.userProfile.lastName.charAt(0);
      if (label === '') {
        label = 'XX';
      }
    }
    return label;
  }

  private get fontSize(): number {
    return this.size / 2;
  }
}
