import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

import {
  ChangePasswordForm,
  CompletePasswordForm,
  ConfirmUser,
  ForgotPasswordForm,
  SignInUser,
  SignUpUser,
} from '@/models/AuthenticationModels';

import router from '@/router';

const COGNITO_REGION = process.env.VUE_APP_COGNITO_REGION;
const COGNITO_USERPOOLID = process.env.VUE_APP_COGNITO_USERPOOLID;
const COGNITO_USERPOOLWEBCLIENTID = process.env.VUE_APP_COGNITO_USERPOOLWEBCLIENTID;

Amplify.configure({
  Auth: {
    region: COGNITO_REGION,
    userPoolId: COGNITO_USERPOOLID,
    userPoolWebClientId: COGNITO_USERPOOLWEBCLIENTID,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
});

@Module({ namespaced: true, name: 'authModule' })
export default class AuthModule extends VuexModule {
  public $authenticated: boolean = false;
  public $currentUser: CognitoUser | null = null;
  public $signUpUser: SignUpUser = {};
  public $userToComplete: CognitoUser | null = null;

  get isAuthenticated() {
    return this.$authenticated;
  }

  get currentUser(): CognitoUser | null {
    return this.$currentUser;
  }

  get signUpUser(): SignUpUser {
    return this.$signUpUser;
  }

  public get userId(): string {
    if (this.currentUser) {
      return this.currentUser.getSignInUserSession()?.getIdToken().payload.sub;
    }

    return '';
  }

  get userToComplete(): CognitoUser | null {
    return this.$userToComplete;
  }

  public get isUserToCompleteNotNull(): boolean {
    return this.$userToComplete != null;
  }

  @Mutation
  public setAuthenticated(auth: boolean) {
    this.$authenticated = auth;
  }

  @Mutation
  public setSigningUser(signUpUser: SignUpUser) {
    this.$signUpUser = signUpUser;
  }

  @Mutation
  public setUserToComplete(userToComplete: CognitoUser) {
    this.$userToComplete = userToComplete;
  }

  @Mutation
  public setCurrentUser(cognitoUser: CognitoUser) {
    this.$currentUser = cognitoUser;
  }

  @Action({ commit: 'setAuthenticated' })
  public async login(authData: SignInUser) {
    try {
      const user: CognitoUser | any = await Auth.signIn(authData.email, authData.password);

      if (user) {

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.context.commit('setUserToComplete', user);
          await router.replace('/account/completePassword');
        } else {
          const userSession: CognitoUserSession | null = await user.getSignInUserSession();

          if (userSession) {
            this.context.commit('setCurrentUser', user);
            await this.context.dispatch('UserModule/fetchCurrentUser', null, { root: true });

            localStorage.setItem('expirationDate',
              new Date(userSession.getAccessToken().getExpiration() * 1000).toString());

            return userSession.isValid();
          }
        }
      }
    } catch (err) {
      let message: string = 'auth.error.' + err.code;
      if (err.code === 'NotAuthorizedException' && err.message === 'User is disabled.') {
        message = 'auth.error.' + err.code + 'Disabled';
      }

      await this.context.dispatch('GlobalUIModule/showSnackBar',
        {
          color: 'error',
          text: message,
        }, { root: true },
      );
      throw new Error(err.code);
    }

    return false;
  }

  @Action({ commit: 'setAuthenticated' })
  public async tryAutoLogin() {
    const expirationDate = new Date(localStorage.getItem('expirationDate') as string);

    if (expirationDate) {
      const now = new Date();

      if (now < new Date(expirationDate)) {
        const user: CognitoUser = await Auth.currentAuthenticatedUser();

        if (user) {
          const userSession: CognitoUserSession | null = await user.getSignInUserSession();

          if (userSession) {
            this.context.commit('setCurrentUser', user);
            await this.context.dispatch('UserModule/fetchCurrentUser', null, { root: true });

            return userSession.isValid();
          }
        }
      }
    }

    return false;
  }

  @Action({ commit: 'setAuthenticated' })
  public async logout() {
    if (this.context.getters.currentUser) {
      this.context.getters.currentUser.signOut();
    }
    localStorage.clear();
    await this.context.commit('setCurrentUser', null);
    await this.context.commit('UserModule/setCurrentUser', null, { root: true });
    await this.context.commit('UserModule/setCurrentUserProfile', null, { root: true });

    return false;
  }

  @Action({ commit: 'setSigningUser' })
  public async signUp(userData: SignUpUser) {
    await Auth.signUp({
      username: userData.email || '',
      password: userData.password || '',
      attributes: {
        email: userData.email || '',
        given_name: userData.givenName || '',
        phone_number: '+1' + userData.phoneNumber || '',
      },
    }).catch((err) => {
      this.context.dispatch('GlobalUIModule/showSnackBar',
        {
          color: 'error',
          text: 'auth.error.' + err.code,
        },
      );
      throw new Error(err.code);
    });

    userData.password = '';

    return userData;
  }

  @Action
  public async confirmSignUp(userConfirmData: ConfirmUser) {
    await Auth.confirmSignUp(userConfirmData.email, userConfirmData.code, {
      forceAliasCreation: true,
    }).then(() => {
      this.context.commit('setSigningUser', null);
    }).catch((err) => {
      this.context.dispatch('GlobalUIModule/showSnackBar',
        {
          color: 'error',
          text: 'auth.error.' + err.code,
        },
      );
      throw new Error(err.code);
    });
  }

  @Action
  public async changePassword(changePasswordData: ChangePasswordForm) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, changePasswordData.oldPassword, changePasswordData.newPassword);
      })
      .catch((err) => {
        this.context.dispatch('GlobalUIModule/showSnackBar',
          {
            color: 'error',
            text: 'auth.error.' + err.code,
          },
        );
        throw new Error(err.code);
      });
  }

  @Action
  public async forgotPassword(email: string) {
    await Auth.forgotPassword(email,
    ).catch((err) => {
      this.context.dispatch('GlobalUIModule/showSnackBar',
        {
          color: 'error',
          text: 'auth.error.' + err.code,
        },
      );
      throw new Error(err.code);
    });
  }

  @Action
  public async completePassword(completePasswordData: CompletePasswordForm) {
    if (this.context.getters.userToComplete) {
      Auth.completeNewPassword(this.context.getters.userToComplete, completePasswordData.newPassword, {
        email: this.context.getters.userToComplete.getUsername(),
      })
        .then(() => {
          this.context.commit('setUserToComplete', null);
        })
        .catch((err) => {
          this.context.dispatch('GlobalUIModule/showSnackBar',
            {
              color: 'error',
              text: 'auth.error.' + err.code,
            },
          );
          throw new Error(err.code);
        });
    } else {
      await router.replace('/');
    }
  }

  @Action({})
  public async forgotPasswordSubmit(forgotPasswordData: ForgotPasswordForm) {
    await Auth.forgotPasswordSubmit(forgotPasswordData.email, forgotPasswordData.code, forgotPasswordData.newPassword,
    ).then(() => {
      return true;
    }).catch((err) => {
      this.context.dispatch('GlobalUIModule/showSnackBar',
        {
          color: 'error',
          text: 'auth.error.' + err.code,
        },
      );
      throw new Error(err.code);
    });
  }

  @Action
  public async resendSignUpCode(email: string) {
    Auth.resendSignUp(email).catch((err) => {
      this.context.dispatch('GlobalUIModule/showSnackBar',
        {
          color: 'error',
          text: 'auth.error.' + err.code,
        },
      );
    });
  }
}
