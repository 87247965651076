import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { NavigationPayload } from '@/models/NavigationModels';
import {
  Session,
  SessionLesson,
  Evaluation,
  SessionInfo,
  SessionInfoParams,
  EvaluationParams,
} from '@/models/EvaluationModels';

@Module({ namespaced: true, name: 'evaluationModule' })
export default class EvaluationModule extends VuexModule {
  private defaultSession: Session = {
    id: null,
    userId: null,
    emulatorId: null,
    lessonId: null,
    creationDate: null,
    lessonCompletedOn: null,
    archived: false,
    courseId: '',
  };
  private $session: Session = Object.assign({}, this.defaultSession);
  private $sessions: Session[] = [];
  private $evaluationItems: NavigationPayload[] = [];
  private $clickCounter: number = 0;
  private $lessonState: string[] = [];
  private $sessionInfos: SessionInfo[] = [];
  private $sessionId: string = '';
  private $currentSessionInfo: SessionInfo | null = null;
  private $evaluation: Evaluation | null = null;

  get session(): Session {
    return this.$session;
  }

  get sessionId(): string {
    return this.$sessionId;
  }

  get evaluation(): Evaluation | null {
    return this.$evaluation;
  }

  get lessonState(): string[] {
    return this.$lessonState;
  }

  get currentSessionInfo(): SessionInfo | null {
    return this.$currentSessionInfo;
  }

  get sessions(): Session[] {
    return this.$sessions;
  }

  get sessionInfos(): SessionInfo[] {
    return this.$sessionInfos;
  }

  get evaluationItems(): NavigationPayload[] {
    return this.$evaluationItems;
  }

  get clickCounter(): number {
    return this.$clickCounter;
  }

  @Mutation
  public addEvaluationItem(evalItem: NavigationPayload) {
    this.$evaluationItems.push(evalItem);
  }

  @Mutation
  public setCurrentSessionInfo(currentSessionInfo: SessionInfo | null) {
    this.$currentSessionInfo = currentSessionInfo;
  }

  @Mutation
  public setEvaluation(evaluation: Evaluation | null) {
    this.$evaluation = evaluation;
  }

  @Mutation
  public emptyEvaluationItems() {
    this.$evaluationItems = [];
  }

  @Mutation
  public setClickCounter(value: number) {
    this.$clickCounter = value;
  }

  @Mutation
  public increaseClickCounter() {
    this.$clickCounter++;
  }

  @Mutation
  public setSession(stateData: Session) {
    this.$session = Object.assign({}, stateData);
  }

  @Mutation
  public setSessions(stateData: Session[]) {
    this.$sessions = stateData;
  }

  @Mutation
  public setSessionInfos(stateData: SessionInfo[]) {
    this.$sessionInfos = stateData;
  }

  @Mutation
  public setLessonState(lessonState: string[]) {
    this.$lessonState = lessonState;
  }

  @Mutation
  public resetSession() {
    this.$session = Object.assign({}, this.defaultSession);
  }

  @Mutation
  public setSessionId(sessionId: string) {
    this.$sessionId = sessionId;
  }

  @Action
  public async sendNavigationPayload(state: NavigationPayload) {
    state.sessionId = this.context.getters.sessionId;
    state.timestamp = Date.now();
    try {
      await axios.post('/sessionAction', state);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }

  @Action({ commit: 'addEvaluationItem' })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async sendEvaluationPayload(stateData: any) {
    // const payload: EvaluationPayload = {
    //   action: stateData.input.action as string,
    //   emulatorId: stateData.emulator.id,
    //   coursePlanId: stateData.coursePlan.id,
    //   timestamp: Date.now(),
    //   clickCounter: this.clickCounter,
    //   componentPath: getComponentPath(stateData.pane),
    //   sessionId: this.sessionID,
    //   data: stateData.input.data ? stateData.input.data : '',
    // };
    //
    // if (!process.env.VUE_APP_STATIC_DATA) {
    //   const { data } = await axios.post('/evaluation/evaluate', payload);
    // } else {
    //   const { data } = await new Promise((resolve) => {
    //     resolve(payload as any);
    //   });
    // }
    // this.context.commit('increaseClickCounter');
    // return payload;
  }

  @Action({ commit: 'setSession' })
  public async createSession(stateData: Session): Promise<Session> {
    const { data } = await axios.post('/session', stateData);
    return data;
  }

  @Action({ commit: 'setSession' })
  public async updateSession(stateData: Session): Promise<Session> {
    stateData.totalClicks = this.context.getters.clickCounter;
    const { data } = await axios.post('/session', stateData);
    return data;
  }

  @Action({commit: 'setSession'})
  public async fetchSessionById(sessionId: string): Promise<void> {
    try {
      const { data } = await axios.get(`/session?id=${sessionId}`);
      return data.content[0];
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }

  // Receive: Lesson & userID in the object as follow { lesson: Lesson, userId: string }
  @Action({ commit: 'setSessions' })
  public async getLastSessionForAUserAndLesson(sessionFor: SessionLesson): Promise<void> {
    const request = `/session?emulatorId=${sessionFor.lesson.emulatorId}&courseId=${sessionFor.courseId}&lessonIds=${sessionFor.lesson.id}&userId=${sessionFor.userId}`;
    try {
      const { data } = await axios.get(request);
      return data.content;
    } catch (e) {
      // eslint-disable-next-line
      console.error('Cannot search', e);
    }
  }

  @Action
  public async destroySession(sessionId: string) {
    try {
      await axios.patch(`/session/${sessionId}`, {
        archived: true,
      });
      await this.context.commit('resetSession');
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }

  @Action({ commit: 'setSessionInfos'})
  public async fetchSessionInfoByUser(evaluationParams: EvaluationParams): Promise<void> {
    try {
      const { data } = await axios.get(`/session/sessionInfo?userId=${evaluationParams.userId}&courseId=${evaluationParams.courseId}&lessonCompleted=true`);
      return data.content;
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }

  @Action({ commit: 'setSessionInfos'})
  public async fetchSessionInfoByLessonIds(evaluationParams: EvaluationParams): Promise<void> {
    try {
      const { data } = await axios.get(`/session/sessionInfo?lessonIds=${evaluationParams.lessonId}&courseId=${evaluationParams.courseId}&lessonCompleted=true`);
      return data.content;
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }

  @Action({ commit: 'setCurrentSessionInfo'})
  public async fetchSessionInfo(sessionInfoParams: SessionInfoParams): Promise<void> {
    try {
      const { data } = await axios.get(
        `/session/sessionInfo?lessonIds=${sessionInfoParams.lessonId}
          &userId=${sessionInfoParams.userId}&lessonCompleted=true`,
      );
      return data.content[0];
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }

  @Action({commit: 'setEvaluation'})
  public async fetchEvaluation(evaluationParams: EvaluationParams): Promise<void> {
    try {
      const { data } = await axios.get(`/evaluation?userId=${evaluationParams.userId}&lessonIds=${evaluationParams.lessonId}&courseId=${evaluationParams.courseId}`);
      if (data.length) {
        return data[0].evaluation;
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }

  @Action({commit: 'setEvaluation'})
  public async createEvaluation(state: Evaluation): Promise<void> {
    try {
      const { data } = await axios.post(`/evaluation`, state);
      return data;
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }
}
