import Vue from 'vue';
import VueI18n from 'vue-i18n';
import moment from 'moment';

import { getBrowserLanguage } from '@/utils/common';

import FR_LOCALES from '@/locales/fr';
import EN_LOCALES from '@/locales/en';

Vue.use(VueI18n);

const messages = {
  en: EN_LOCALES,
  fr: FR_LOCALES,
};

const language: string = getBrowserLanguage().substr(0, 2);

moment.locale(language);

const i18n = new VueI18n({
  locale: language,
  fallbackLocale: 'en',
  messages,
});

export default i18n;
