















































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Emulator, TableHeader } from '@/models/EmulatorModels';
import PaginationFooter from '@/components/paginationFooter/PaginationFooter.vue';
import AircraftType from '@/enums/user/aircraft';

@Component({
  components: {
    PaginationFooter,
  },
})
export default class EmulatorTable extends Vue {

  @Prop({
    required: true,
  })
  public template!: string;

  @Prop({
    required: true,
  })
  public canEdit!: boolean;

  @Prop({
    required: true,
  })
  public headers!: TableHeader[];

  @Prop({
    required: true,
    default: [],
  })
  public searchItems!: Emulator[];

  @Prop({
    required: true,
    default: 1,
  })
  public actualPage!: number;

  @Prop({
    required: true,
    default: 20,
  })
  public selectItemPerPage!: number;

  @Prop({
    required: true,
  })
  public totalPages!: number;

  @Prop({
    required: true,
  })
  public loading!: boolean;

  @Prop({
    required: true,
  })
  public itemsPerPageOptions!: string[];

  @Prop({
    required: true,
  })
  public totalElements!: number;

  private aircraftType = AircraftType;

  @Emit('onPreviewHandler')
  private onPreviewHandler(emulator: Emulator) {
    return emulator;
  }

  @Emit('onDownloadHandler')
  private onDownloadHandler(emulator: Emulator) {
    return emulator;
  }

  @Emit('onDeleteEmulatorClick')
  private onDeleteEmulatorClick(targetEmulator: Emulator) {
    return targetEmulator;
  }

  @Emit('updateItemsPerPage')
  private updateItemsPerPage(newValue: string) {
    return newValue;
  }

  @Emit('nextPage')
  private nextPage() {
    return this.actualPage + 1;
  }

  @Emit('previousPage')
  private previousPage() {
    return this.actualPage + 1;
  }
}
