const FR_LOCALES = {
  auth: {
    forgotPassword: {
      title: 'Mot de passe oublié',
      sendCode: 'Envoyer code',
      alert: {
        title: 'Ne pas fermer cet onglet.',
        text: 'Un courriel vous a été envoyé dans votre boîte de réception. Ceci peut prendre jusqu\'à 2 minutes.. ' +
          'Entrez votre code dans le champs ci-dessous et taper votre nouveau mot de passe.',
        junkCaption: 'Si vous n\'avez pas reçu de courriel, vérifiez dans vos indésirables.',
      },
    },
    error: {
      UserNotConfirmedException: 'Votre adresse courriel n\' a pas été confirmée. Vérifiez votre boîte de réception.',
      PasswordResetRequiredException: 'Votre mot de passe doit être réinitialisé.',
      UserNotFoundException: 'Vérifiez avec votre formateur si vous êtes inscrits avec ce courriel.',
      NotAuthorizedException: 'Votre courriel ou votre mot de passe n\'est pas valide.',
      NotAuthorizedExceptionDisabled: 'Votre compte a été désactivé.',
      UnknownException: 'Une erreur est survenue. Réessayez plus tars ou contactez votre formateur.',
    },
  },
  common: {
    roles: {
      guest: 'Invité',
      user: 'Utilisateur',
      instructor: 'Formateur',
      fleetChief: 'Chef de flotte',
      admin: 'Admin',
    },
    aircraft: {
      g450: 'G-450',
      g550: 'G-550',
      g650: 'G-650',
      ufo: 'UFO',
    },
    forms: {
      title: 'Titre',
      description: 'Description',
    },
    confirmDeletePopup: {
      deleteTitle: 'Supprimer {itemName} ?',
      archiveTitle: 'Archiver {itemName} ?',
      deleteDescription: 'Cette opération est immédiate et ne peut être annulée.',
      archiveDescription: 'Cette opération est immédiate, mais récupérable ultérieurement.',
    },
    tables: {
      itemsPerPage: 'Items par page',
      pageNumber: 'Page {number} de {total}',
    },
    error: {
      fieldRequired: 'Ce champ est requis.',
      minCharacters: 'Ce champ doit contenir plus de {number} caractères.',
      maxCharacters: 'Ce champ doit contenir moins de {number} caractères.',
      httpProblem: 'Un problème est survenue lors d\'une requête. Réessayez de nouveau.',
    },
    authentication: 'Authentification',
    email: 'Courriel',
    password: 'Mot de passe',
    validate: 'Valider',
    submit: 'Soumettre',
    save: 'Enregistrer',
    edit: 'Éditer',
    close: 'Fermer',
    downloading: 'Téléchargement',
    proceed: 'Procéder',
    delete: 'Supprimer',
    remove: 'Retirer',
    cancel: 'Annuler',
    resetForm: 'Effacer le formulaire',
    logout: 'Déconnexion',
    givenName: 'Nom complet',
    phoneNumber: 'Numéro de téléphone',
    code: 'Code',
    newPassword: 'Nouveau mot de passe',
    oldPassword: 'Ancien mot de passe',
    for: 'pour',
    search: 'Recherche',
    date: 'Date',
    from: 'de',
    to: 'à',
    welcome: 'Bienvenue',
    inactivityDialogTitle: 'Êtes-vous là?',
    inactivityDialogText1: 'En raison de votre inactivité, votre session de connexion va expirer. ' +
      'Cliquez sur Continuer pour rester connecté.',
    inactivityDialogText2: 'secondes restantes',
    inactivityDialogText3: 'Vous avez été déconnecté par cause d\'inactivité.',
    inactivityDialogButton: 'Continuer',
    noDataAvailable: 'Il n\'y a aucune donnée à afficher en ce moment.',
  },
  drawer: {
    instructorTools: 'Outils du formateur',
    fleetChiefTools: 'Outils du chef de flotte',
    administrationTools: 'Outils d\'administration',
  },
  emulatorView: {
    list: {
      aircraft: 'Avion',
      title: 'Titre',
      cloneOf: 'Clone de',
      actions: 'Actions',
    },
  },
  globalUI: {
    dialog: {
      firstLogin: {
        header: 'Première connexion',
        body: 'Vous devez compléter votre profile avant de pouvoir utiliser l\'application',
        error: 'Une erreur est survenue, veuillez vérifier votre connexion internet.',
      },
      profile: {
        header: 'Profil',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        company: 'Entreprise',
        passwordChange: 'Changement de mot de passe',
        oldPassword: 'Old Password',
        password: 'Mot de passe',
        confirmPassword: 'Confirmer le mot de passe',
        image: 'Image',
        error: 'Une erreur est survenue, veuillez vérifier votre connexion internet.',
      },
    },
  },
  login: {
    noAccount: 'Pas un membre ?',
    forgotPassword: 'Mot de passe oublié?',
    changePassword: 'Changer mot de passe',
    confirmAccount: 'Confirmer le compte',
  },
  signUp: {
    signUpForm: 'Formulaire d\'inscription',
    haveAccount: 'Déjà membre?',
    confirmPassword: 'Confirmer le mot de passe',
    setPassword: 'Choisir un mot de passe',
  },
  confirmSignUp: {
    confirmSignUpForm: 'Confirmation du compte',
    confirmationCode: 'Code',
    resendCode: 'Renvoyer le code',
  },
  changePassword: {
    title: 'Changer mot de passe',
    error: 'Une erreur est survenue, veuillez vérifier votre connexion internet.',
    success: 'Mot de passe modifié avec succès!',
  },
  completePassword: {
    title: 'Compléter le compte',
  },
  dashboard: {
    title: 'Tableau de bord',
    footer: 'Propulsé par ©Menya',
  },
  profile: {
    title: 'Profil',
    avatar: 'Avatar',
    pickAvatarLabel: 'Choisir un avatar',
  },
  editor: {
    title: 'Éditeur d\'émulateurs',
    createFormTitle: 'Créer un émulateur',
    emulatorId: 'ID de l\'émulateur',
    titleInput: 'Titre',
    descriptionInput: 'Description',
    aircraftInput: 'Modèle de l\'avion',
    templateIDInput: 'Clone de l\'émulateur...',
    componentId: 'Identifiant du composant',
    editSwitch: 'Permettre l\'édition?',
    imageLabel: 'Image',
    addImageLabel: 'Choisir une image (Optionnel)',
    add: 'Ajouter un nouvel émulateur',
    addPanes: 'Ajouter un nouveau panneau',
    addComponent: 'Ajouter un nouveau composant',
    editComponentTitle: 'Éditer un composant',
    nameInput: 'Nom',
    nameInputHint: 'Pour affichage seulement. Les clients ne verront pas ceci.',
    paneTypeInput: 'Type de panneau',
    componentTypeInput: 'Type de composant',
    valueInput: 'Valeur',
    statusesInput: 'Statuts',
    fullEditMode: 'Edition complète',
    addTrigger: 'Ajouter une nouvelle action',
    triggersTitle: 'Actions',
    triggerType: 'Type d\'action',
    triggerLocation: 'Destination',
    triggerExpandPanel: 'ID de l\'item ouvert',
    triggerDirection: 'Direction',
    addViewCondition: 'Ajouter une condition d\'affichage',
    viewConditionTitle: 'Conditions d\'affichage',
    addViewConditionButton: 'Ajouter une nouvelle condition',
    unknownPane: 'Panneau non nommé',
    unknownComponent: 'Composant non nommé',
    emulatorListRefresh: 'Mise à jour de la liste d\'émulateurs',
    emulatorListUpdated: 'Liste d\'émulateurs mise à jour',
    emulatorListError: 'Une erreur est survenue pendant la mise à jour de la liste d\'émulateurs',
  },
  emulator: {
    emulators: 'Emulateurs',
    panes: 'Panneaux',
    components: 'Composants',
    clickNotSupportedDialog: {
      title: 'Clic non supporté',
      description: 'La fonctionnalité de ce bouton a été désactivée.',
    },
  },
  navigationReport: {
    title: 'Rapport de navigation',
    selectedAction: 'Action sélectionnée: ',
    type: 'Type: ',
    data: 'Données: à ',
    start: 'Début',
    currentPane: 'De',
    destinationPane: 'à',
    expands: 'Menu ouvert dans',
    previous: 'Précédent',
    prev: 'Retour vers',
    of: 'de',
    navigate: 'Navigate',
    change_page: 'Change Page',
    expand_panel: 'Expand Panel',
    scroll_bar: 'Scroll Bar',
    scroll_item: 'Scroll Item',
    click_not_supported: 'Click Not Supported',
  },
  courseTemplates: {
    title: 'Canvas de cours',
    templatePageTitle: 'Détail du canvas de cours',
    lesson: {
      add: 'Ajouter une leçon',
      cockpit: 'Outils de cockpit',
      edit: 'Modifier une leçon',
      end: 'Fin de la lesson',
      exploratory: 'Exploration',
      type: 'Type de leçon',
      popup: {
        title: 'Voulez-vous terminer de votre session?',
        desc: 'Votre progression sera sauvegardée et vos formateurs en seront informés.',
        agree: 'Oui',
        disagree: 'Non',
      },
    },
    templateForm: {
      addTitle: 'Ajouter un canvas',
      editTitle: 'Modifier le canvas',
      prefix: 'Préfixe du canvas de cours',
      aircraft: 'Choisissez l\'avion',
    },
    emulatorsForm: {
      addEmulators: 'Ajouter des émulateurs',
    },
  },
  coursesPlanner: {
    title: 'Planificateur de cours',
    planCourse: 'Planifier un cours',
    course: 'Détail du cours',
    usersProfile: {
      student: 'Étudiant',
      completedCourses: 'Cours complétés',
      courses: 'Cours',
      emulator: 'Émulateur',
      infos: 'Informations',
      usersProfileCard: {
        noCourses: 'Aucun cours n\'est encore terminé',
      },
    },
    form: {
      title: 'Planification d\'un cours',
      chooseTemplate: 'Choisir un canvas',
      noData: 'Chercher le canvas...',
      noTemplate: 'Aucun canvas trouvé.',
      instructors: 'Choisir un formateur',
      noInstructor: 'Aucun intructeur trouvé.',
      rangeError: 'La date de fin doit être après la date de début.',
    },
    usersList: {
      selectLabel: 'Selectionner les utilisateurs',
    },
    archiveLabel: 'Archiver le cours',
    enabled: 'activés',
    disabled: 'désactivés',
    clients: 'clients',
    instructors: 'formateurs',
    addClientLabel: 'Ajouter/Supprimer des clients',
    addClientSuccess: 'Liste des clients mise à jour',
    addClientError: 'Erreur en mettant à jour la liste des clients',
    addInstructorLabel: 'Ajouter/Supprimer des formateurs',
    addInstructorSuccess: 'Liste des formateurs mise à jour',
    addInstructorError: 'Erreur en mettant à jour la liste des formateurs',
    fetchCoursePlansSuccess: 'Liste de plans de cours mise à jour',
    fetchCoursePlansError: 'Erreur en mettant à jour la liste des plans de cours',
    choseCoursePlanSuccess: 'Plan de cours chargé',
    choseCoursePlanError: 'Erreur en chargeant le plan de cours',
    deleteCoursePlanSuccess: 'Plan de cours supprimé',
    deleteCoursePlanError: 'Erreur en supprimant le plan de cours',
    noCourse: 'Il n\'y a aucun cours à afficher pour le moment.',
    noOnGoingCourse: 'Vous n\'avez pas de cours en ce moment.',
  },
  contactUs: {
    title: 'Contactez-nous',
    clipboardMessage: '"{copiedItem}" a été copié dans votre presse-papiers.',
  },
  access: {
    noAccess: {
      title: 'Oups!',
      description: 'Il semble que vous n\'ayez pas la permission d\'accéder au contenu de ce site web. ' +
        'Veuillez contacter votre formateur.',
    },
  },
  pageNotFound: {
    title: 'Page non trouvée.',
    link: 'Retour au Tableau de bord',
  },
  loader: {
    text: 'Veuillez patienter',
  },
  user: {
    manager: {
      title: 'Administration',
      fetchUserPageSuccess: 'Liste d\'utilisateur mise à jour',
      fetchUserPageError: 'Erreur en chargeant la liste d\'utilisateur',
      deleteDialogTitle: 'Êtes-vous sur que vous voulez supprimer l\'utilisateur?',
      deleteDialogText1: 'Vous êtes sur le point de supprimer l\'utilisateur ',
      deleteDialogText2: '. Cette action est définitive et irréversible. Veuillez faire preuve de prudence.',
      deleteDialogDeleteButton: 'Supprimer',
      deleteDialogCancelButton: 'Annuler',
      addRoleDialogTitle: 'Quels rôles voulez-vous ajouter?',
      removeRoleDialogTitle: 'Quels rôles voulez-vous enlever?',
      duplicateElement: 'Vous ne pouvez pas ajouter le même rôle deux fois.',
      addRoles: 'Ajouter',
      removeRoles: 'Retirer',
      setRoleDialogTitle: 'Quels rôles voulez-vous assigner?',
      setAircraftDialogTitle: 'Quels avions voulez-vous assigner?',
      addPeople: 'Ajouter des utilisateurs',
      actionActivateUser: 'Activer',
      actionDeactivateUser: 'Désactiver',
      actionSetRoles: 'Définir les rôles',
      actionSetAircraft: 'Définir les avions',
      actionAddRoles: 'Ajouter rôle(s)',
      actionRemoveRoles: 'Enlever rôle(s)',
      studentIdLabel: 'ID d\'étudiant',
      aircraftLabel: 'Avion',
      givenNameLabel: 'Nom',
      emailLabel: 'Courriel',
      rolesLabel: 'Rôles',
      enabledLabel: 'Activée',
      searchLabel: 'Recherche',
      clearLabel: 'Annuler la sélection',
      createUsersSuccess: 'Utilisteur(s) créé(s) avec succès',
      createUsersError: 'Erreur lors de la création',
      createUsersTitle: 'Ajouter des gens',
      createUsersTitleText1: 'Une personne par ligne.',
      createUsersTitleText2: 'Dans le cas d\'un étudiant, ajouter l\'identifiant d\'étudiant après le courriel.',
      createUsersTitleText3: 'Ajouter une virgule entre l\'identifiant d\'étudiant et le courriel.',
      createUsersTitleText4: 'Personnes sur le point d\'être ajoutées',
      createUsersTitleText5: 'Un courriel sera envoyé aux nouveaux utilisateurs contenant le mot de passe temporaire.',
      createUsersTitleText6: 'Vous ne serez plus en mesure de voir les mots de passe après la confirmation.',
      createUsersLabel1: 'Courriel (requis), ID_Étudiant (optionnel)',
      createUsersValidate: 'Valider',
      createUsersCancel: 'Annuler',
      createUsersEmail: 'Email',
      createUsersStudentID: 'ID Étudiant',
      createUsersTempPW: 'MDP Temporaire',
      createUsersConfirm: 'Confirmer',
      createUsersEdit: 'Modifier',
      createUsersAddRolesLabel: 'Choisir rôle...',
      createUsersAddAircraftLabel: 'Choisir avions...',
      profileUnavailable: 'Le profil n\'est pas disponible',
      profileAvailable: 'Afficher le profil',
      updateRoleError: 'Erreur lors de la mise à jour de(s) rôle(s)',
      updateRoleSuccess: 'Succès de la mise à jour de(s) rôle(s)',
      updateAircraftError: 'Erreur lors de la mise à jour de(s) avion(s)',
      updateAircraftSuccess: 'Succès de la mise à jour de(s) avion(s)',
      userExists: 'Utilisateur existant',
      userExistsHint: 'Astuce',
      useOldPassword: 'Il faut utiliser le mot de passe existant.',
      NA: '<ND>',
    },
  },
  enum: {
    emulatorPaneType: {
      mainMenu: 'Menu principal',
      secondaryMenu: 'Menu secondaire',
      endOfFlight: 'Fin de Vol',
      paginator: 'Pagination',
      paginatorList: 'Liste de pagination',
      fanStatusDu: 'Statut ventilateur DU',
      fanStatusMau: 'Statut ventilateur MAU',
      fault: 'Panneau de faute',
      fde: 'FDE',
      info: 'Panneau d\'informations',
      flightDeckEffects: 'Effectifs du poste de pilotage',
      table: 'Tableau',
      tableStatusPane: 'Tableau Statut',
      dataAcSystemPane: 'Système de Données',
      testInitializing: 'Initialisation du test',
      tcasPane: 'Tcas Système Panneau',
    },
    emulatorComponentType: {
      text: 'Texte',
      list: 'Liste',
      listItem: 'Item de liste',
      button: 'Bouton',
      sideMenu: 'Menu latéral',
      sideMenuItem: 'Item de menu latéral',
      table: 'Tableau',
      tableHeader: 'Entête de tableau',
      tableBody: 'Corps de tableau',
      tableRow: 'Rangée de tableau',
      tableRowItem: 'Item de tableau',
      image: 'Image',
      boolean: 'Booléen',
      indicator: 'Indicateur carré Booléen',
      round_indicator: 'Indicateur rond Booléen',
      rectangle_indicator: 'Indicateur rectangle Booléen',
      cross_indicator: 'Indicateur croisé Booléen',
      style: 'Style',
      listGauge: 'Liste Jauge',
      gauge: 'Jauge',
      tcas: 'Tcas',
      tcas_row: 'Rangée de tcas',
      tcas_item: 'Item de tcas',
    },
    emulatorComponentStatus: {
      faulted: 'En Faute',
      external: 'Externe',
      empty: 'Vide',
      idle: 'Inoccupé',
      disabled: 'Désactivé',
      standby: 'En attente',
      match: 'Égaler',
      nomatch: 'Aucune concordance',
      not_supported: 'Non Supporté',
    },
    emulatorTriggerType: {
      navigate: 'Navigate',
      changePage: 'Change Page',
      expandPanel: 'Expand Panel',
      scrollBar: 'Scroll Bar',
      scrollItem: 'Scroll Item',
      clickNotSupported: 'Click Not Supported',
    },
    lessonType: {
      exploratory: 'Exploratoire',
      monitored: 'Enregistré',
      evaluation: 'Évaluation',
    },
    unknown: 'Type inconnu',
  },
  dateStatus: {
    notStarted: 'Non débuté',
    inProgress: 'Commencé',
    hadEnded: 'Terminé',
  },
  evaluation: {
    title: 'Évaluation',
    form: {
      hasSucceeded: 'Réussite',
      feedback: 'Commentaires',
      grade: 'Note',
      save: 'Enregistrer',
    },
    statistics: {
      title: 'Statistiques',
      clicks: 'Clics',
      time: 'Durée (min)',
    },
    lesson: {
      title: 'Leçon',
    },
  },
  view: {
    welcome: 'Bienvenue {name}!',
    loggedAs: 'Connecté en tant que {name}',
    loading: 'Chargement...',
    error: {
      oops: 'Désolé {name}.',
      genericMessage: 'Nous sommes désolés de cette difficulé technique. Notre équipe réglera ce problème le plus rapidement possible.<br>Contactez votre instructeur si vous avez besoin d\'aide.',
      noEmulatorId: {
        message: 'Aucun identifiant n\'a été fourni pour charger un émulateur.',
      },
      emulatorNotFound: {
        message: 'L\'émulateur que vous tentez de charger n\'existe pas.',
      },
      dataNotFound: {
        message: 'Les donn/es de \'émulateur ne semblent pas exister ou ne peuvent être chargées.',
      },
    },
  },
};

export default FR_LOCALES;
