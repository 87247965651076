










































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { EmulatorVar } from '@/models/EmulatorModels';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import { mixins } from 'vue-class-component';

@Component({})
export default class EmulatorViewConditionForm extends mixins(ResponsiveMixin) {

  public isFormValid: boolean = false;
  public name: string = '';
  public value: string = '';

  // TODO: Not used, use the the rule utility instead if this is needed.
  public requiredRules: any[] = [
    (v) => !!v || this.getErrorMessage('common.error.fieldRequired'),
  ];

  @Prop()
  public editedViewCondition!: EmulatorVar | null;

  @Emit('onCloseHandler')
  public onCloseHandler() {
    this.resetForm();
    return;
  }

  public getErrorMessage(key: string, args?: any): string {
    return this.$t(key, args) as string;
  }

  public async onSaveClick() {
    this.isFormValid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.isFormValid) {
      this.onAddTrigger();
    }
  }

  @Emit('onAddViewConditionHandler')
  public onAddTrigger() {
    const newViewConditions: EmulatorVar = {
      name: this.name,
      value: this.parsedValue,
    };
    this.resetForm();
    return newViewConditions;
  }

  protected created() {
    if (this.editedViewCondition) {
      this.name = this.editedViewCondition.name;
      this.value = this.editedViewCondition.value as string;
    }
  }

  private get parsedValue(): any {
    if (this.value === 'true') {
      return true;
    } else if (this.value === 'false') {
      return false;
    }
    return this.value;
  }

  private resetForm() {
    this.isFormValid = (this.$refs.form as Vue & { reset: () => boolean }).reset();
  }
}
