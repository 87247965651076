























import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({})
export default class AutoCompleteControl extends Vue {
  @Prop({
    required: true,
  })
  public label!: string;

  @Prop({
    default: '',
  })
  public noDataLabel!: string;

  @Prop({
    default: () => [],
  })
  public items!: any[];

  @Prop({
    default: () => [],
  })
  public rules!: any[];

  @Prop({
    default: false,
  })
  public disabled!: boolean;

  @Prop({
    default: false,
  })
  public multiple!: boolean;

  @Prop({
    default: 'text',
  })
  public itemText!: string | any[] | (() => []);

  @Prop({
    default: () => null,
  })
  public selectedItems!: any;

  private isLoading: boolean = false;
  private timer!: number;

  private searchTermValue: string = '';

  private selectedItemsLocal: any = null;
  private itemsLocal: any[] = [];

  @Emit('onSearchTermChanged')
  public emitSearchTerm(): string {
    return this.searchTermValue;
  }

  @Emit('onItemSelected')
  public emitSelectedItem(): string {
    return this.selectedItemsLocal;
  }

  protected created(): void {
    this.selectedItemsLocal = this.selectedItems;
    this.itemsLocal = this.items;
  }

  @Watch('searchTermValue')
  private searchCourseTemplates(): void {
    if (!this.selectedItemsLocal || this.searchTermValue !== this.selectedItemsLocal[this.itemText as string]) {
      if (this.timer) {
        window.clearTimeout(this.timer);
      }
      this.isLoading = true;
      this.itemsLocal = [];
      this.timer = window.setTimeout(async () => {
        this.emitSearchTerm();
        window.clearTimeout(this.timer);
      }, 1000);
    }
  }

  @Watch('selectedItemsLocal')
  private selectItem(): void {
    this.emitSelectedItem();
  }

  @Watch('items')
  private updateItems(): void {
    this.isLoading = false;
    this.itemsLocal = this.items;
  }

  @Watch('disabled')
  private disableChanged(): void {
    if (!this.disabled && this.items.length === 0) {
      this.isLoading = true;
    }
  }

}
