import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import ExternalViewService from '@/store/services/externalViewService';
import { TokenInfo, TokenValidationInfo } from '@/models/ExternalViewModels';

@Module({ namespaced: true, name: 'externalViewModule' })
export default class ExternalViewModule extends VuexModule {
  private $encryptedToken!: TokenValidationInfo;
  private $tokenInfo!: TokenInfo;

  public get encryptedToken(): TokenValidationInfo {
    return this.$encryptedToken;
  }

  public get tokenInfo(): TokenInfo {
    return this.$tokenInfo;
  }

  @Mutation
  public setEncryptedToken(token: TokenValidationInfo): void {
    this.$encryptedToken = token;
  }

  @Mutation
  public setTokenInfo(token: TokenInfo): void {
    this.$tokenInfo = token;
  }

  @Action({ commit: 'setTokenInfo' })
  public async validateEncryptedToken(tokenValidationInfo: TokenValidationInfo): Promise<TokenInfo> {
    this.context.commit('setEncryptedToken', tokenValidationInfo);
    const results: any = await ExternalViewService.validateExternalToken(tokenValidationInfo);
    return results as TokenInfo;
  }

  @Action
  public async fetchExternalViewEmulator(emulatorId: string) {
    const { tokenInfo } = this.context.getters;
    const results: any = await ExternalViewService.getExternalEmulator(emulatorId, tokenInfo.token);
    this.context.commit('EmulatorModule/setCurrentEmulator', results, { root: true });
    this.context.commit('EmulatorModule/setCurrentEmulatorState', results.state, { root: true });
  }
}
