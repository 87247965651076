


















































import { Component, Prop } from 'vue-property-decorator';
import CourseTemplatesEmulatorsList from '@/components/courses/CourseEmulatorsList.vue';
import { CourseInfo } from '@/models/CoursesModels';
import EmulatorViewer from '@/components/viewers/EmulatorViewer.vue';
import { namespace } from 'vuex-class';
import UserMixin from '@/mixins/UserMixin.ts';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import EvaluationsListView from '@/components/coursesPlanner/evaluation/EvaluationsListView.vue';
import { UserProfile } from '@/models/UserModels';
import { mixins } from 'vue-class-component';

const emulatorModule = namespace('EmulatorModule');

const UserProfileCoursesTab = {
  emulator: {
    key: 'coursesPlanner.usersProfile.emulator',
    name: 'emulator',
  },
  evaluation: {
    key: 'evaluation.title',
    name: 'evaluation',
  },
  info: {
    key: 'coursesPlanner.usersProfile.infos',
    name: 'info',
  },
};

@Component({
  components: {
    CourseTemplatesEmulatorsList,
    EmulatorViewer,
    EvaluationsListView,
  },
})
export default class UsersCoursesTabInfo extends mixins(UserMixin, ResponsiveMixin) {
  public componentKey: number = 0;

  @Prop()
  public courseInfo!: CourseInfo;

  @Prop()
  public user!: UserProfile;

  @Prop({
    default: false,
  })
  public canOpenEmulator!: boolean;

  @emulatorModule.Mutation
  public setOnEndLesson!: (isOnEndLessonClicked: boolean) => void;

  private isEmulatorLoading: boolean = false;
  private userProfileCoursesTab: any = UserProfileCoursesTab;
  private tab = null;
  private emulatorKey: string = '';
  private lessonKey: string = '';

  public playEmulatorOnClick(lessonEmulator: any): void {
    if (this.canOpenEmulator) {
      this.emulatorKey = lessonEmulator.emulatorKey;
      this.lessonKey = lessonEmulator.lessonKey;
      this.isEmulatorLoading = true;
    }
  }

  public onCloseViewer(): void {
    if (this.canOpenEmulator) {
      this.isEmulatorLoading = false;
      this.emulatorKey = '';
      this.lessonKey = '';
      this.setOnEndLesson(false);
      this.componentKey++;
    }
  }
}
