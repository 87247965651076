









































import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { UIDialog } from '@/models/UIModels';
import { DialogComponentFactory } from '@/components/componentFactory/DialogComponentFactory';
import { DialogContentType } from '@/enums/globalUI';
import DialogContentNone from '@/components/globalUI/DialogContentNone.vue';

const auth = namespace('AuthModule');
const globalUI = namespace('GlobalUIModule');

@Component({
  components: { DialogContentNone },
})
export default class DialogContentFirstLogin extends Vue {
  private registerDialog!: UIDialog;

  @globalUI.Mutation
  private setDialogVisible!: (isVisible: boolean) => void;

  @globalUI.Action
  private hideDialog!: () => void;

  @globalUI.Action
  private showDialog!: (dialog: UIDialog) => boolean;

  @auth.Action
  private logout!: () => Promise<void>;

  protected created() {
    this.registerDialog = DialogComponentFactory.getDialogByType(DialogContentType.PROFILE);
  }

  private cancelHandler() {
    // TODO: Consider extracting this method.
    this.logout().then(() => {
      this.$router.replace('/account/signin');
    });
    this.hideDialog();
  }
}
