



















import { Component, Vue } from 'vue-property-decorator';
import CMCDataControl from '@/mixins/CMCDataControl.ts';

@Component({
  mixins: [
    CMCDataControl,
  ],
  props: {
    units: {
      required: false,
      default: '',
    },
  },
})
export default class CMCDataSimple extends Vue {
}
