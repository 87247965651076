









































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { UserRoleLine } from '@/models/UserModels';
import RoleSelectionInput from '@/components/user/roleSelection/RoleSelectionForm.vue';
import UserRoleType from '@/enums/user';
import AircraftType from '@/enums/user/aircraft';
import { UISnackBar } from '@/models/UIModels';
import { namespace } from 'vuex-class';

const globalUI = namespace('GlobalUIModule');

@Component({
  components: {
    RoleSelectionForm: RoleSelectionInput,
  },
})

export default class RoleSelectionList extends Vue {

  @globalUI.Action
  public showSnackBar!: (snackBar: UISnackBar) => void;

  @Prop()
  public readonly value!: UserRoleLine[];

  public displayElements: UserRoleLine[] = [];
  public targetElement: UserRoleLine | null = null;

  public isFormOpen: boolean = false;
  public index: number | null = null;
  private userRoleType = UserRoleType;
  private aircraftType = AircraftType;

  public onFormClosedHandler() {
    this.isFormOpen = false;
  }

  public onAddItemHandler(roleLine: UserRoleLine): void {
    if (this.index !== null) {
      this.displayElements[this.index] = roleLine;
      this.index = null;
      this.targetElement = null;

    } else {

      const elementIndex: number = this.displayElements.findIndex((element: UserRoleLine) => {
        return element.role === roleLine.role;
      });

      if (elementIndex >= 0) {
        this.displayElements.splice(elementIndex, 1);
      }
      this.displayElements.push(roleLine);
    }

    this.isFormOpen = false;
    this.emitValue();
  }

  @Emit('input')
  public emitValue(): UserRoleLine[] {
    return this.displayElements;
  }

  public onRemoveRoleClick(index: number) {
    this.displayElements.splice(index, 1);
  }

  private created(): void {
    if (this.value) {
      this.displayElements = this.value;
    }
  }
}
