











































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ListItemCardTitle extends Vue {

  @Prop({
    default: false,
  })
  public noGutter!: boolean;

  @Prop({
    required: true,
  })
  public title!: string;

  @Prop({
    required: true,
  })
  public subtitle!: string;

  @Prop({})
  public chips!: string[];
}
