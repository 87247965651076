import Vue from 'vue';
import Router from 'vue-router';
import qs from 'qs';

import store from '@/store/store';

import SignIn from '@/components/auth/SignIn.vue';
import DashboardHome from '@/components/dashboard/DashboardHome.vue';
import ChangePassword from '@/components/auth/ChangePassword.vue';
import ForgotPassword from '@/components/auth/ForgotPassword.vue';
import CompletePassword from './components/auth/CompletePassword.vue';
import NoAccess from '@/components/views/NoAccess.vue';
import Error404 from '@/components/views/Error404.vue';
import UserManager from '@/components/user/UserManager.vue';
import EmulatorPanesLanding from '@/components/editor/EmulatorPanesLanding.vue';
import EmulatorEditorLanding from '@/components/editor/EmulatorEditorLanding.vue';
import EmulatorComponentsLanding from '@/components/editor/EmulatorComponentsLanding.vue';
import UserRoleType from '@/enums/user';
import ContactUs from '@/components/views/ContactUs.vue';

let router: Router = new Router();

if (process.env.VUE_APP_STATIC_STAND_ALONE !== 'true') {

  Vue.use(Router);
  router = new Router({
    routes: [
      {
        path: '/',
        beforeEnter(to, from, next) {
          if (isAuthenticated()) {
            next();
          } else {
            next('/account/signin');
          }
        },
      },
      {
        path: '/account',
        component: () => import(/* webpackChunkName: "account" */ './components/auth/Account.vue'),
        children: [
          {
            path: '/',
            beforeEnter(to, from, next) {
              if (isAuthenticated()) {
                next('/account/changepassword');
              } else {
                next('/account/signin');
              }
            },
          },
          {
            path: 'signin',
            name: 'signin',
            component: SignIn,
            beforeEnter(to, from, next) {
              if (isAuthenticated()) {
                next('/dashboard');
              } else {
                next();
              }
            },
          },
          {
            path: 'completepassword',
            name: 'completepassword',
            component: CompletePassword,
          },
          {
            path: 'changepassword',
            name: 'changepassword',
            component: ChangePassword,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'forgotpassword',
            name: 'forgotpassword',
            component: ForgotPassword,
          },
        ],
      },
      {
        path: '/dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ './components/dashboard/Dashboard.vue'),
        children: [
          {
            path: '',
            name: 'dashboard',
            component: DashboardHome,
            meta: {
              requiresAuth: true,
              limitedRoles: [],
            },
          },
        ],
      },
      {
        path: '/user-manager',
        component: UserManager,
        meta: {
          requiresAuth: true,
          limitedRoles: [UserRoleType.FLEET_CHIEF, UserRoleType.INSTRUCTOR, UserRoleType.ADMIN],
        },
      },
      {
        path: '/external',
        component: () => import(/* webpackChunkName: "external-page-view" */ './components/viewers/EmulatorSinglePage.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: '/view/:emulatorId',
        component: () => import(/* webpackChunkName: "single-page-view" */ './components/viewers/EmulatorSinglePage.vue'),
      },
      {
        path: '/editor',
        component: () => import(/* webpackChunkName: "editor" */ './components/editor/base/EmulatorEditorBase.vue'),
        children: [
          {
            path: '',
            component: EmulatorEditorLanding,
            meta: {
              requiresAuth: true,
              limitedRoles: [UserRoleType.ADMIN, UserRoleType.FLEET_CHIEF, UserRoleType.INSTRUCTOR],
            },
          },
          {
            path: ':id/',
            component: EmulatorPanesLanding,
            meta: {
              requiresAuth: true,
              limitedRoles: [UserRoleType.ADMIN, UserRoleType.FLEET_CHIEF],
            },
          },
          {
            path: ':id/:idPane',
            component: EmulatorComponentsLanding,
            meta: {
              requiresAuth: true,
              limitedRoles: [UserRoleType.ADMIN, UserRoleType.FLEET_CHIEF],
            },
          },
        ],
      },
      {
        path: '/courses-templates',
        component: () => import(/* webpackChunkName: "course-templates" */ './components/coursesTemplates/CoursesTemplatesBase.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "course-templates" */ './components/coursesTemplates/CoursesTemplatesLanding.vue'),
            meta: {
              requiresAuth: true,
              limitedRoles: [UserRoleType.ADMIN, UserRoleType.FLEET_CHIEF, UserRoleType.INSTRUCTOR],
            },
          },
          {
            path: ':id/',
            component: () => import(/* webpackChunkName: "course-templates" */ './components/coursesTemplates/CourseTemplateSingleView.vue'),
            meta: {
              requiresAuth: true,
              limitedRoles: [UserRoleType.ADMIN, UserRoleType.FLEET_CHIEF, UserRoleType.INSTRUCTOR],
            },
          },
        ],
      },
      {
        path: '/courses-planner',
        component: () => import(/* webpackChunkName: "course-planner" */ './components/coursesPlanner/CoursesPlannerBase.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "course-planner" */ './components/coursesPlanner/CoursePlannerLanding.vue'),
            meta: {
              requiresAuth: true,
              limitedRoles: [UserRoleType.ADMIN, UserRoleType.FLEET_CHIEF, UserRoleType.INSTRUCTOR],
            },
          },
          {
            path: ':id/',
            component: () => import(/* webpackChunkName: "course-templates" */ './components/coursesPlanner/CourseSingleView.vue'),
            meta: {
              requiresAuth: true,
              limitedRoles: [UserRoleType.ADMIN, UserRoleType.FLEET_CHIEF, UserRoleType.INSTRUCTOR],
            },
          },
          {
            path: ':id/:userId',
            component: () => import(/* webpackChunkName: "course-templates" */ './components/coursesPlanner/usersProfile/UsersProfileView.vue'),
            meta: {
              requiresAuth: true,
              limitedRoles: [UserRoleType.FLEET_CHIEF, UserRoleType.INSTRUCTOR],
            },
          },
        ],
        meta: {
          requiresAuth: true,
          limitedRoles: [UserRoleType.FLEET_CHIEF, UserRoleType.INSTRUCTOR, UserRoleType.ADMIN],
        },
      },
      {
        path: '/navigation-report/:courseId/:lessonId/:userId',
        component: () => import(/* webpackChunkName: "navigation-report" */ './components/navigationReport/NavigationReportLanding.vue'),
        meta: {
          requiresAuth: true,
          limitedRoles: [],
        },
      },
      // {
      //   path: '/instructor',
      //   component: () => import(/* webpackChunkName: "instructor" */ './components/dashboard/Dashboard.vue'),
      //   children: [
      //     {
      //       path: 'editor/create',
      //       name: 'editor_create',
      //       component: Editor,
      //       meta: {
      //         requiresAuth: true,
      //         limitedRoles: [UserRoleType.FLEET_CHIEF],
      //       },
      //       beforeEnter(to, from, next) {
      //         store.dispatch('resetSelectedEmulator');
      //         next();
      //       },
      //     },
      //     {
      //       path: 'editor/:id',
      //       name: 'editor_edit',
      //       component: Editor,
      //       meta: {
      //         requiresAuth: true,
      //         limitedRoles: [UserRoleType.FLEET_CHIEF],
      //       },
      //     },
      //     {
      //       path: 'reporting',
      //       name: 'reporting',
      //       component: Reporting,
      //       meta: {
      //         requiresAuth: true,
      //         limitedRoles: [UserRoleType.FLEET_CHIEF],
      //       },
      //     },
      //   ],
      // },
      {
        path: '/contact-us',
        name: 'contactus',
        component: ContactUs,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/noaccess',
        name: 'noaccess',
        component: NoAccess,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/area51',
        name: 'area51',
        component: () => import(/* webpackChunkName: "area51" */ './components/area51/Area51.vue'),
        meta: {
          requiresAuth: true,
          limitedRoles: [UserRoleType.ADMIN, UserRoleType.FLEET_CHIEF],
        },
      },
      {path: '*', component: Error404},
    ],
    // set custom query resolver
    parseQuery(query) {
      return qs.parse(query);
    },
    stringifyQuery(query) {
      const result = qs.stringify(query);
      return result ? ('?' + result) : '';
    },
  });

  router.beforeEach((to, from, next) => {
      verifyAuthentication(to, next, () => {
        if (to.matched.some((record) => record.meta.limitedRoles)) {
          if (to.meta.limitedRoles.length === 0) {
            if (!isGuest()) {
              next();
            } else {
              next('/noaccess');
            }
          } else {
            if (UserRoleType.hasAccessByRoles(to.meta.limitedRoles, store.getters['UserModule/userRoles'])) {
              next();
            } else {
              next('/noaccess');
            }
          }
        } else {
          next();
        }
      });
    },
  );

  const verifyAuthentication = (to, next, callback): void => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (isAuthenticated()) {
        callback();
      } else {
        store.dispatch('AuthModule/tryAutoLogin').then(() => {
          if (!isAuthenticated()) {
            next('/account/signin');
          } else {
            callback();
          }
        });
      }
    } else {
      callback();
    }
  };

  const isGuest = (): boolean => {
    return UserRoleType.isGuest(store.getters['UserModule/userRoles']);
  };

// const isAdmin = (): boolean => {
//   return UserRoleType.isAdmin(store.getters['UserModule/userRoles']);
// };

  const isAuthenticated = (): boolean => {
    return store.getters['AuthModule/isAuthenticated'];
  };

}

export default router;
