


















































import Vue from 'vue';
import Component from 'vue-class-component';
import { CompletePasswordForm } from '@/models/AuthenticationModels';
import { namespace } from 'vuex-class';
import TheFooter from '@/components/shared/TheFooter.vue';

const auth = namespace('AuthModule');

@Component({
  components: {
    TheFooter,
  },
})
export default class CompletePassword extends Vue {

  @auth.Action
  public completePassword!: (completePasswordData: CompletePasswordForm) => Promise<void>;

  @auth.Getter
  public isUserToCompleteNotNull!: boolean;

  @auth.Action
  public logout!: () => Promise<void>;

  private valid: boolean = false;
  private confirmPassword = '';

  private form: CompletePasswordForm = {
    newPassword: '',
  };

  private passwordRules: any[] = [
    (v: string) => !!v || 'The password is required',
    (v: string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/.test(v) ||
      'Password must have at least eight characters, one uppercase letter,' +
      ' one lowercase letter and one number',
  ];

  private confirmPasswordRules: any[] = [
    (v: string) => !!v || 'You must confirm the password.',
    (v: string) => this.matchPassword(v) || 'Passwords should match.',
  ];

  public created() {
    if (!this.isUserToCompleteNotNull) {
      // this.logout().then(() => this.$router.replace('/account/signin'));
    }
  }

  private matchPassword(value: string) {
    return value === this.form.newPassword;
  }

  private validate(): void {
    const formToSend: CompletePasswordForm = {
      newPassword: this.form.newPassword,
    };

    this.completePassword(formToSend).then(() => {
      this.logout().then(() => {
        this.$router.replace('/account/signin');
      });
    }).catch(() => {
      // removing trace
    });
  }
}
