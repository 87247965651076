

























































































import { Component, Emit, Prop } from 'vue-property-decorator';
import { Emulator } from '@/models/EmulatorModels';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';
import { namespace } from 'vuex-class';
import { getAircraftLabel, getImagePath } from '@/utils/labels_utils';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import { mixins } from 'vue-class-component';

const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    ConfirmationModal,
  },
})
export default class EmulatorsListCardItem extends mixins(ResponsiveMixin) {

  public tempDeleteConfirmationPopUp = false;

  @Prop()
  public emulator!: Emulator;

  @Prop()
  public canEdit!: boolean;

  @emulatorModule.Action
  public deleteEmulator!: (emulator: Emulator) => Promise<void>;

  @emulatorModule.Action
  public fetchEmulators!: () => Promise<void>;

  @Emit('previewButtonHandler')
  public previewButtonClick() {
    return this.emulator;
  }

  @Emit('downloadButtonHandler')
  public downloadButtonClick() {
    return this.emulator;
  }

  @Emit('editButtonHandler')
  public editButtonClick() {
    return this.emulator;
  }

  public get imagePath(): string {
    if (this.emulator.imageUrl) {
      return this.emulator.imageUrl;
    } else {
      return getImagePath(this.emulator);
    }
  }

  public get aircraftLabel(): string {
    return getAircraftLabel(this.emulator);
  }

  public get canBeDeleted(): boolean {
    return this.emulator.canBeModified; // Put this section back when BE is working
    // return this.emulator.canBeModified || (!this.emulator.canBeModified && this.emulator.cloneId !== undefined);
  }

  public deleteEmulatorClick() {
    this.tempDeleteConfirmationPopUp = true;
  }

  public onDeleteConfirmationHandler(isDeleted: boolean) {
    if (isDeleted) {
      this.deleteEmulator(this.emulator).then(() => {
        this.fetchEmulators();
      });
    }
    this.tempDeleteConfirmationPopUp = false;
  }
}
