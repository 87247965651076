









































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { UserRoleLine } from '@/models/UserModels';
import { VSelectField } from '@/models/GlobalModels';
import AircraftType from '@/enums/user/aircraft';
import UserRoleType from '@/enums/user';
import { namespace } from 'vuex-class';

const userModule = namespace('UserModule');

@Component({})
export default class RoleSelectionInput extends Vue {

  @Prop()
  public targetElement!: UserRoleLine | null;

  @userModule.Getter
  public userRoles!: { [index: string]: string[] };

  // Role Dialog
  private selectableRoles: VSelectField[] = [];
  private selectableAircraft: VSelectField[] = [];
  private selectedRole: string = '';
  private selectedAircraft: string[] = [];

  private isSelectionValid = false;
  private aircraftVSelectDisabled = true;

  @Emit('onCloseHandler')
  public onCloseHandler() {
    this.resetForm();
    return;
  }

  protected created() {
    this.generateRoleList();

    if (this.targetElement) {
      this.selectedRole = this.targetElement.role;
      this.selectedAircraft = this.targetElement.aircraft;
    }
  }

  private generateRoleList() {
    const array: VSelectField[] = [];

    const roles = UserRoleType.getRoles();
    const selectableRoles = UserRoleType.getAssignableRolesForUser(this.userRoles);

    roles.forEach((value, index) => {
      array.push({
        text: UserRoleType.getRoleLabelByType(roles[index]),
        value: roles[index],
        disabled: !selectableRoles.includes(roles[index]),
      });
    });

    this.selectableRoles = array;
  }

  private generateAircraftList() {
    const array: VSelectField[] = [];

    const aircraft = AircraftType.getAircraft();
    const selectableAircraft: string[] = UserRoleType.getAssignableAircraftForUser(this.userRoles);

    aircraft.forEach((value, index) => {
      array.push({
        text: AircraftType.getAircraftLabelByType(aircraft[index]),
        value: aircraft[index],
        disabled: !selectableAircraft.includes(aircraft[index]),
      });
    });

    this.selectableAircraft = array;
  }

  private submit() {
    this.onSubmit();
    this.resetForm();
    this.isSelectionValid = false;
  }

  @Emit('onAddItemHandler')
  private onSubmit() {
    const roleLine: UserRoleLine = {
      role: this.selectedRole,
      aircraft: this.selectedAircraft,
    };

    this.resetForm();
    return roleLine;
  }

  private resetForm() {
    this.selectedRole = '';
    this.selectedAircraft = [];
  }

  private onRoleSelected() {
    this.checkIfSelectionValid();

    // Enable aircraft selection after a choice is made.
    if (this.aircraftVSelectDisabled) {
      this.generateAircraftList();
      this.aircraftVSelectDisabled = false;

    } else {
      if (this.selectedRole.length === 0) {
        this.aircraftVSelectDisabled = true;
      }
    }
  }

  private onAircraftSelected() {
    this.checkIfSelectionValid();
  }

  private checkIfSelectionValid() {
    this.isSelectionValid = this.selectedRole !== '' && this.selectedAircraft.length > 0;
  }
}
