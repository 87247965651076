





























































































































































































































import { Component, Vue } from 'vue-property-decorator';
// import { getDate } from '@/utils/common';
import CMCSideMenu from '@/components/cmc/sidemenu/CMCSideMenu.vue';
import CMCDataDial from '@/components/cmc/controls/CMCDataDial.vue';
import CMCDataSimple from '@/components/cmc/controls/CMCDataSimple.vue';
import CMCDataMotor from '@/components/cmc/controls/CMCDataMotor.vue';
// import { EmulatorComponent, EmulatorActionItem } from '@/models/EmulatorModels';
import CMCLayoutSideMenu from '@/components/cmc/sidemenu/CMCLayoutSideMenu.vue';
// import CMCSideMenuLayoutMixin from '@/components/cmc/sidemenu/CMCSideMenuLayoutMixin.vue';

@Component({
  components: {
    CMCSideMenu,
    CMCDataDial,
    CMCDataSimple,
    CMCDataMotor,
    CMCLayoutSideMenu,
  },
})
export default class CMCEndOfFlightPane extends Vue {
  // @Prop({ required: true })
  // private currentEmulatorPane!: EmulatorComponent;
  //
  // private today: string = '';
  // private flightLeg?: EmulatorActionItem;
  // private originFlight?: EmulatorActionItem;
  // private destinationFlight?: EmulatorActionItem;
  // private totalFlight?: EmulatorActionItem;
  // private landingTime?: EmulatorActionItem;
  // private engineStatistics?: EmulatorActionItem;
  // private fqmsFuelStatistics?: EmulatorActionItem;
  // private maxAccelerations?: EmulatorActionItem;
  // private exceedancesTriggered?: EmulatorActionItem;
  //
  // protected created(): void {
  //   this.createSideMenu(this.currentEmulatorPane);
  //   this.today = getDate().format('M D YYYY HH:MM') as string;
  //   this.flightLeg = this.currentEmulatorPane!.items!.flightLeg;
  //   this.originFlight = this.currentEmulatorPane!.items!.origin;
  //   this.destinationFlight = this.currentEmulatorPane!.items!.destination;
  //   this.totalFlight = this.currentEmulatorPane!.items!.totalFlight;
  //   this.landingTime = this.currentEmulatorPane!.items!.landingTime;
  //   this.engineStatistics = this.currentEmulatorPane!.items!.engineStatistics;
  //   this.fqmsFuelStatistics = this.currentEmulatorPane!.items!.fqmsFuelStatistics;
  //   this.maxAccelerations = this.currentEmulatorPane!.items!.maxAccelerations;
  //   this.exceedancesTriggered = this.currentEmulatorPane!.items!.exceedancesTriggered;
  // }
}
