

















import { Component, Vue } from 'vue-property-decorator';
import CMCDataControl from '@/mixins/CMCDataControl.ts';

@Component({
  mixins: [
    CMCDataControl,
  ],
})
export default class CMCDataDial extends Vue {
}
