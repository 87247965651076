








































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponentsMap, EmulatorStyle } from '@/models/EmulatorModels';
import { EmulatorComponentStatus, EmulatorComponentType } from '@/enums/emulator';
import { itemHasStyles } from '@/utils/common';

interface GaugeData {
  values: any[];
  orderValues: any[];
  thresholdMin: number;
  thresholdMax: number;
  leftGauge: any[];
  rightGauge: any[];
  minValue: number;
  maxValue: number;
}

@Component({
  name: 'CMCDataGauge',
})


export default class CMCDataGauge extends Vue {

  public gaugeData: GaugeData = {
    values: [],
    orderValues: [],
    leftGauge: [],
    rightGauge: [],
    thresholdMin: 0,
    thresholdMax: 0,
    minValue: 0,
    maxValue: 0,
  };

  @Prop({ required: true })
  private gauge!: EmulatorComponentsMap;

  public getPosition(value: number): any {
    const style: any = {};
    if (this.gaugeData.minValue === value) {
      style.top = 0;
    } else if (this.gaugeData.maxValue === value) {
      style.top = '90%';
    } else {
      let c = 100 / ((this.gaugeData.maxValue - this.gaugeData.minValue) / (value - this.gaugeData.minValue));
      if (c > 4) {
        c = c - 6;
      }
      style.top = `${c}%`;
    }
    return style;
  }

  public setBackgroundColorGauge(value: any): boolean {
    if (value) {
      return (parseFloat(value) >= this.gaugeData.thresholdMin && parseFloat(value) <= this.gaugeData.thresholdMax);
    }
    return false;
  }

  public getStyles(styles: EmulatorStyle): any {
    return itemHasStyles(styles);
  }

  public checkExternal(value): boolean {
    return (value === EmulatorComponentStatus.EXTERNAL);
  }

  protected created(): void {
    this.getData();
  }

  private getData(): void {
    for (const i in this.gauge.components) {
      if (this.gauge.components[i].type === EmulatorComponentType.LIST_GAUGE) {
        for (const a in this.gauge.components[i].components) {
          if (this.gauge.components[i].components[a].type === EmulatorComponentType.GAUGE) {
            this.gaugeData.values.push(this.gauge.components[i].components[a].value);
          }
        }

        this.gaugeData.orderValues = this.gaugeData.values.sort((a, b) => a - b);
        this.gaugeData.minValue = this.gaugeData.orderValues[0];
        this.gaugeData.maxValue = this.gaugeData.orderValues[this.gaugeData.orderValues.length - 1];
        this.gaugeData.thresholdMin = parseInt(this.gaugeData.orderValues[1], 10);
        this.gaugeData.thresholdMax = parseInt(this.gaugeData.orderValues[2], 10);

      } else if (this.gauge.components[i].type === EmulatorComponentType.GAUGE) {

        const rightGauge: any = {
          value: this.gauge.components[i].value,
        };

        let gaugeClass = 'indicator i-red';
        if (parseInt(rightGauge.value, 10) >= this.gaugeData.thresholdMin
          && parseInt(rightGauge.value, 10) <= this.gaugeData.thresholdMax) {
          gaugeClass = gaugeClass.replace('i-red', 'i-green');
        }

        if (parseInt(rightGauge.value, 10) === 0 && rightGauge.value !== '') {
          gaugeClass += ' top';
        }

        rightGauge.style = {
          class: gaugeClass,
          css: this.getPosition(rightGauge.value),
        };

        this.gaugeData.rightGauge.push(rightGauge);
      }
    }

    if (this.gaugeData.orderValues.length > 0) {

      const level1 = this.getSizeLevel(
        this.gaugeData.orderValues[0],
        this.gaugeData.orderValues[1],
        this.gaugeData.orderValues[0],
      );
      let val;
      if (this.gaugeData.orderValues[1] === 0) {
        val = this.gaugeData.orderValues[2];
      } else {
        val = this.gaugeData.orderValues[1];
      }
      const level2 = this.getSizeLevel(
        val,
        this.gaugeData.orderValues[2],
        this.gaugeData.orderValues[1],
      );
      const level3 = this.getSizeLevel(
        this.gaugeData.orderValues[3],
        this.gaugeData.orderValues[3],
        this.gaugeData.orderValues[2],
      );
      this.addLevel(level1);
      this.addLevel(level2);
      this.addLevel(level3);
    }
  }

  private getSizeLevel(pos: number, max: number, min: number): { value: number, porcentage: any } {
    return {
      value: pos,
      porcentage: this.setHeight((max - min)),
    };
  }

  private setHeight(value: number): any {
    if (value) {
      return value * (100 / (this.gaugeData.maxValue - this.gaugeData.minValue));
    }
  }

  private addLevel(level: any): void {
    if (level) {
      this.gaugeData.leftGauge.push(level);
    }
  }
}
