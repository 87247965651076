































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { CourseInfo } from '@/models/CoursesModels';
import ElementsCountCard from '@/components/shared/ElementsCountCard.vue';
import AircraftType from '@/enums/user/aircraft';
import DateStatus from '@/components/shared/DateStatus.vue';

@Component({
  components: {
    ElementsCountCard,
    DateStatus,
  },
})
export default class CoursesListItem extends Vue {
  @Prop({
    required: true,
  })
  public courseInfo!: CourseInfo;

  @Emit('courseClick')
  public onCourseClick(): CourseInfo {
    return this.courseInfo;
  }

  public getEmulatorCounter(courseInfo: CourseInfo): number {
    if (typeof courseInfo.emulatorIds !== 'undefined' && courseInfo.emulatorIds.length > 0) {
      const $return = courseInfo.emulatorIds.length - courseInfo.enabledEmulatorIds.length;
      if ($return > 0) {
        return $return;
      }
    }
    return 0;
  }

  public get tags(): string[] {
    return [AircraftType.getAircraftLabelByType(this.courseInfo.aircraftModel)];
  }
}
