





















































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { EmulatorVar } from '@/models/EmulatorModels';
import ResizableAddItemCard from '@/components/shared/ResizableAddItemCard.vue';
import ListItemCardTitle from '@/components/shared/ListItemCardTitle.vue';
import EmulatorViewConditionForm from '@/components/editor/forms/complexListControl/EmulatorViewConditionForm.vue';

@Component({
  components: {
    ListItemCardTitle,
    ResizableAddItemCard,
    EmulatorViewConditionForm,
  },
})
export default class EmulatorViewConditionList extends Vue {
  public viewConditions: EmulatorVar[] = [];

  public isViewConditionFormOpen: boolean = false;
  public editedCondition: EmulatorVar | null = null;
  public index: number | null = null;

  @Prop()
  public readonly value!: EmulatorVar[];

  @Emit('input')
  public emitValue(): EmulatorVar[] {
    return this.viewConditions;
  }

  public onAddItemClick(): void {
    this.isViewConditionFormOpen = true;
  }

  public onAddItemHandler(emulatorVar: EmulatorVar): void {
    if (this.index !== null) {
      this.viewConditions[this.index] = emulatorVar;
      this.index = null;
      this.editedCondition = null;
    } else {
      this.viewConditions.push(emulatorVar);
    }

    this.isViewConditionFormOpen = false;
    this.emitValue();
  }

  public onCloseHandler() {
    this.isViewConditionFormOpen = false;
  }

  public onDeleteViewConditionClick(index: number) {
    this.viewConditions.splice(index, 1);
  }

  public onCardClick(emulatorVar: EmulatorVar, index: number): void {
    this.editedCondition = emulatorVar;
    this.index = index;
    this.isViewConditionFormOpen = true;
  }

  protected created(): void {
    this.viewConditions = this.value;
  }
}
