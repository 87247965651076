

























import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
})
export default class Loader extends Vue {
}
