

















import { Component, Prop } from 'vue-property-decorator';
import ViewConditionsMixin from '@/components/cmc/mixins/ViewConditionsMixin';
import { mixins } from 'vue-class-component';

@Component({})
export default class TestModeSwitch extends mixins(ViewConditionsMixin) {

  private static TEST_MODE: string = 'TEST_MODE';
  private isOn: boolean = false;

  @Prop({
    default: false,
  })
  public dark!: boolean;

  public switchMode(value: boolean) {
    this.changeViewConditions(TestModeSwitch.TEST_MODE, value);
  }

  protected created(): void {
    this.isOn = this.currentEmulatorState ?
      this.returnViewCondition(TestModeSwitch.TEST_MODE)?.value as boolean : false;
  }

  public get onOffLabel(): string {
    return this.isOn ? 'ON' : 'OFF';
  }

}
