
















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class AvatarDisplay extends Vue {
  @Prop({
    default: '',
  })
  public url!: string;

  @Prop({
    default: '',
  })
  public username!: string;

  @Prop({
    default: 48,
  })
  public size!: number;

  private get label(): string {
    let label: string = '';
    const usernameArr = this.username.split(' ');
    if (usernameArr.length > 0) {
      usernameArr.forEach((item, key) => {
        if (key <= 1) {
          label += item.charAt(0);
        }
      });
    }
    return label;
  }

  private get fontSize(): number {
    return this.size / 2;
  }
}
