




























































import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EvaluationParams, Session, SessionInfo } from '@/models/EvaluationModels';
import { UserProfile } from '@/models/UserModels';
import ToolbarTitle from '@/components/shared/ToolbarTitle.vue';
import moment from 'moment';
import { CourseInfo, Lesson } from '@/models/CoursesModels';
import { LessonType } from '@/enums/courses';
import AvatarDisplay from '@/components/coursesPlanner/evaluation/AvatarDisplay.vue';
import UserMixin from '@/mixins/UserMixin';
import { mixins } from 'vue-class-component';

const evaluationModule = namespace('EvaluationModule');
const coursesTemplateModule = namespace('CoursesTemplateModule');
const UserModule = namespace('UserModule');

@Component({
  components: {
    ToolbarTitle,
    AvatarDisplay,
  },
  filters: {
    format: (date: Date) => {
      return moment(date).local(true).format('YYYY-MM-DD, hh:mm A');
    },
  },
})
export default class EvaluationsListView extends mixins(UserMixin) {
  public loadingSessionInfos: boolean = true;

  @Prop()
  public user!: UserProfile;

  @Prop()
  public course!: CourseInfo;

  @Prop({
    default: false,
  })
  public showToolbarTitle!: boolean;

  @Prop({
    default: false,
  })
  public canEdit!: boolean;

  @UserModule.Getter
  public selectedUserProfile!: UserProfile | null;

  @UserModule.Action
  public fetchUserProfile!: (idUser: string) => Promise<void>;

  @evaluationModule.Getter
  public sessions!: Session[];

  @evaluationModule.Getter
  public sessionInfos!: SessionInfo[];

  @evaluationModule.Getter
  public currentSessionInfo!: SessionInfo;

  @evaluationModule.Mutation
  public setSessionInfos!: (sessionInfos: SessionInfo[]) => void;

  @evaluationModule.Mutation
  public setCurrentSessionInfo!: (sessionInfo: SessionInfo) => void;

  @evaluationModule.Mutation
  public setSessions!: (sessions: Session[]) => void;

  @coursesTemplateModule.Mutation
  public setCurrentCourseInfo!: (courseInfo: CourseInfo | null) => void;

  @evaluationModule.Action
  public fetchSessionInfoByUser!: (evaluationParam: EvaluationParams) => Promise<void>;

  @evaluationModule.Action
  public fetchSessionInfoByLessonIds!: (evaluationParam: EvaluationParams) => Promise<void>;

  @coursesTemplateModule.Action
  public fetchLessonById!: (lessonId: string) => Promise<Lesson>;

  @coursesTemplateModule.Action
  public fetchLessonsIdsByEmulatorsIds!: (emulatorIds: string[]) => Promise<string[]>;

  @coursesTemplateModule.Action
  public fetchCoursesInfosById!: (courseId: string) => Promise<CourseInfo>;

  public created(): void {
    if (typeof this.userId !== 'undefined' || this.isClient) {
      this.fetchSessionsForUser();
    } else {
      this.fetchSessionForCourse();
    }
  }

  public fetchSessionsForUser(): void {
    this.fetchSessionInfoByUser({ userId: this.userId, courseId: this.courseId })
      .then(() => {
        this.setSessionInfos(this.sessionInfos.filter((info) => info.lessonType !== LessonType.EXPLORATORY));
        this.loadingSessionInfos = false;
      });
  }

  public fetchSessionForCourse(): void {
    this.fetchLessonsIdsByEmulatorsIds(this.course.enabledEmulatorIds).then((lessonIds) => {
      this.fetchSessionInfoByLessonIds({ lessonId: lessonIds.toString(), courseId: this.courseId }).then(() => {
        this.setSessionInfos(this.sessionInfos.filter((info) => info.lessonType === LessonType.EVALUATION));
        // this.setSessionInfos(this.sessionInfos.filter((info) =>
        //         this.course.clients.filter((client) => client.id === info.userId).length));
        this.loadingSessionInfos = false;
      });
    });
  }

  public isLessonTypeMonitored(lessonType: string): boolean {
    return lessonType === LessonType.MONITORED;
  }

  public isLessonTypeEvaluation(lessonType: string): boolean {
    return lessonType === LessonType.EVALUATION;
  }

  public goToNavigateReport(sessionInfo: SessionInfo): void {
    this.setCurrentSessionInfo(sessionInfo);
    this.$router.replace(`/navigation-report/${this.courseId}/${sessionInfo.lessonId}/${sessionInfo.userId}`);
  }

  public destroyed() {
    this.setCurrentCourseInfo(null);
  }

  // Getting User ID from params
  private get userId(): string {
    if (this.isClient) {
      return this.currentUser!.id;
    }
    return this.$route.params.userId;
  }

  private get courseId(): string {
    if (this.isClient) {
      return this.course.id!;
    }
    return this.$route.params.id;
  }
}
