import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { UIDialog, UISnackBar } from '@/models/UIModels';
import { DialogContentType } from '@/enums/globalUI';
import { DialogComponentFactory } from '@/components/componentFactory/DialogComponentFactory';
import router from '@/router';
import store from '@/store/store';

@Module({ namespaced: true, name: 'globalUIModule' })
class GlobalUIModule extends VuexModule {
  private $isDemoMode: boolean = true;

  private $dialog: UIDialog = {};
  private $isDialogVisible: boolean = false;

  private $snackBar: UISnackBar = {};
  private $isSnackBarVisible: boolean = false;

  // Inactivity vars
  private $inactivityBaseAmount: number = 1800;
  private $inactivityThreshold: number = 300;
  private $inactivityInterval: any = null;

  public get isDialogVisible(): boolean {
    return this.$isDialogVisible;
  }

  public get dialog() {
    return this.$dialog;
  }

  public get inactivityBaseAmount() {
    return this.$inactivityBaseAmount;
  }

  public get inactivityThreshold() {
    return this.$inactivityThreshold;
  }

  public get inactivityInterval() {
    return this.$inactivityInterval;
  }

  public get isDemoMode() {
    return this.$isDemoMode;
  }

  @Mutation
  public setDialog(newDialog: UIDialog) {
    this.$dialog = newDialog;
  }

  @Mutation
  public setDialogByContent(newContent: DialogContentType) {
    this.$dialog = DialogComponentFactory.getDialogByType(newContent);
  }

  @Mutation
  public setDialogVisible(isVisible: boolean) {
    this.$isDialogVisible = isVisible;
  }

  @Mutation
  public setInactivityInterval(value: any) {
    this.$inactivityInterval = value;
  }

  @Mutation
  public setDemoMode(value: boolean) {
    this.$isDemoMode = value;
  }

  @Action({ commit: 'setDialogVisible' })
  public showDialog(dialog: UIDialog): boolean {
    this.context.commit('setDialog', dialog);
    return true;
  }

  @Action({ commit: 'setDialogVisible' })
  public showDialogByContent(content: DialogContentType): boolean {
    this.context.commit('setDialogByContent', content);
    return true;
  }

  @Action({ commit: 'setDialog' })
  public hideDialog(): UIDialog {
    this.context.commit('setDialogVisible', false);
    return {};
  }

  public get snackBar(): UISnackBar {
    return this.$snackBar;
  }

  public get isSnackBarVisible(): boolean {
    return this.$isSnackBarVisible;
  }

  @Mutation
  public setSnackBar(snack: UISnackBar) {
    this.$snackBar = snack;
  }

  @Mutation
  public setSnackBarVisible(isVisible: boolean) {
    this.$isSnackBarVisible = isVisible;
  }

  @Action({ commit: 'setSnackBarVisible' })
  public showSnackBar(snackbar: UISnackBar): boolean {
    this.context.commit('setSnackBar', snackbar);
    return true;
  }

  @Action({ commit: 'setSnackBar' })
  public hideSnackBar(): UISnackBar {
    this.context.commit('setSnackBarVisible', false);
    return {};
  }

  @Action
  public async logoutHandler() {
    store.dispatch('AuthModule/logout').then(() => {
      router.replace('/account/signin').then(() =>
        clearInterval(store.getters.inactivityInterval));
    });
  }
}

export default GlobalUIModule;
