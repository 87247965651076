



























import { Vue, Component, Prop } from 'vue-property-decorator';
import { getAircraftImageLabel, getAircraftImagePath } from '@/utils/labels_utils';
import { CourseInfo } from '@/models/CoursesModels';
import { UserProfile } from '@/models/UserModels';
import UsersCoursesTabInfo from '@/components/coursesPlanner/usersProfile/UsersCoursesTabInfo.vue';

@Component({
  components: {
    UsersCoursesTabInfo,
  },
})
export default class CourseClientCard extends Vue {

  @Prop({
    required: true,
  })
  public currentCourseInfo!: CourseInfo;

  @Prop({
    required: true,
  })
  public userProfile!: UserProfile;

  @Prop({
    default: false,
  })
  public canOpenEmulator!: boolean;

  public getAircraftImage(): string {
    return getAircraftImagePath(this.currentCourseInfo!.aircraftModel);
  }

  public getAircraftLabel(): string {
    return getAircraftImageLabel(this.currentCourseInfo!.aircraftModel);
  }
}
