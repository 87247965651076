







































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CMCLayoutSideMenu from '@/components/cmc/sidemenu/CMCLayoutSideMenu.vue';
import { EmulatorComponent } from '@/models/EmulatorModels';

@Component({
  components: {
    CMCLayoutSideMenu,
  },
})
export default class CMCDuFanStatusPane extends Vue {
  @Prop(
    {
      required: true,
    },
  )
  private currentEmulatorPane!: EmulatorComponent;
  private unit!: EmulatorComponent;
}
