import { EmulatorPaneType } from '@/enums/emulator';
import { EmulatorComponent } from '@/models/EmulatorModels';
import {ComponentFactory} from "@/components/componentFactory/ComponentFactory";

export class CMCComponentFactory implements ComponentFactory<EmulatorComponent> {

  public static isPaginator(currentEmulatorPane: EmulatorComponent): boolean {
    return CMCComponentFactory.isPaneOfType(currentEmulatorPane, EmulatorPaneType.PAGINATOR);
  }

  public static getComponentByType(pane: EmulatorComponent | null): string {
    return this.localInstance.getComponentByType(pane);
  }

  private static localInstance = new CMCComponentFactory();

  private static isPaneOfType(pane: EmulatorComponent | null, paneType: EmulatorPaneType): boolean {
    let isPaneOfType: boolean = false;
    if (pane) {
      isPaneOfType = pane.type === paneType;
    }
    return isPaneOfType;
  }

  public getComponentByType(pane: EmulatorComponent | null): string {
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.PAGINATOR)) {
      return 'CMCPaginator';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.MAIN_MENU)) {
      return 'CMCMainMenuPane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.SECONDARY_MENU)) {
      return 'CMCSecondaryMenuPane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.END_OF_FLIGHT)) {
      return 'CMCEndOfFlightPane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.FAN_STATUS_DU)) {
      return 'CMCDuFanStatusPane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.FAN_STATUS_MAU)) {
      return 'CMCMauFanStatusPane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.FAULT)) {
      return 'CMCFaultPane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.FDE)) {
      return 'CMCFDEPane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.INFO)) {
      return 'CMCInfoPane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.FLIGHTDECKEFFECTS_NEW_PANE)) {
      return 'CMCFlightDeckEffectsPane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.TABLE)) {
      return 'CMCTablePane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.TABLE_STATUS)) {
      return 'CMCTableStatusPane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.DATA_AC_SYSTEM)) {
      return 'CMCDataAcSystemPane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.INDICATOR_STATUS)) {
      return 'CMCIndicatorStatusPane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.TEST_INITIALIZING)) {
      return 'CMCTestInitializing';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.TCAS_PANE)) {
      return 'CMCTcasSystemPane';
    }
    if (CMCComponentFactory.isPaneOfType(pane, EmulatorPaneType.DATA_PRECONDITION_PANE)) {
      return 'CMCDataPreconditionPane';
    }
    return '';
  }
}
