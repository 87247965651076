


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/models/UserModels';

@Component
export default class UserDeleteDialog extends Vue {

  @Prop(Function) public readonly proceedCallback!: () => void;
  @Prop(Function) public readonly cancelCallback!: () => void;
  @Prop() public userToBeDeleted!: User;
}
