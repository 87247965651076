












































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorComponentsMap } from '@/models/EmulatorModels';
import CMCLayoutSideMenu from '@/components/cmc/sidemenu/CMCLayoutSideMenu.vue';
import CMCCharacterProgressBar from '@/components/cmc/controls/CMCCharacterProgressBar.vue';
import CMCFanDataBlock from '@/components/cmc/controls/CMCFanDataBlock.vue';

@Component({
  props: {
    currentEmulatorPane: {
      required: true,
    },
  },
  components: {
    CMCLayoutSideMenu,
    CMCCharacterProgressBar,
    CMCFanDataBlock,
  },
})
export default class CMCMauFanStatusPane extends Vue {
  get legend(): EmulatorComponentsMap {
    return this.currentEmulatorPane.components!.legend!.components!;
  }

  get labels(): any {
    return this.currentEmulatorPane!.components!.labels!.components;
  }

  get currentFans(): any {
    return this.currentEmulatorPane!.components!.fanBlocks!.components;
  }

  @Prop({ required: true })
  public currentEmulatorPane!: EmulatorComponent;
}
