































































import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { UserProfile } from '@/models/UserModels';

const globalUI = namespace('GlobalUIModule');
const userModule = namespace('UserModule');

@Component
export default class DialogContentSelectedProfile extends Vue {

  @globalUI.Mutation
  private setDialogVisible!: (isVisible: boolean) => void;

  @userModule.Getter
  private selectedUserProfile!: UserProfile | null;

  // Data
  private firstName: string = '';
  private lastName: string = '';
  private company: string = '';

  protected created() {

    if (this.selectedUserProfile) {
      this.firstName = this.selectedUserProfile.firstName ? this.selectedUserProfile.firstName : '';
      this.lastName = this.selectedUserProfile.lastName ? this.selectedUserProfile.lastName : '';
      this.company = this.selectedUserProfile.company ? this.selectedUserProfile.company : '';
    }
  }
}
