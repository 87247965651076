import { namespace } from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';
import { EmulatorTriggerType } from '@/enums/emulator';
import { EmulatorTrigger } from '@/models/EmulatorModels';

const emulatorModule = namespace('EmulatorModule');

@Component
export class KeyboardNavigationMixin extends Vue {

  public className!: string;

  @emulatorModule.Getter
  private currentIndexItemScroll!: number;

  @emulatorModule.Mutation
  private setMaxIndexItemScroll!: (MaxIdx: number) => void;

  @emulatorModule.Mutation
  private resetCurrentIndexItemScroll!: () => void;

  @emulatorModule.Action
  private dispatchActions!: (triggers: EmulatorTrigger[]) => void;

  private listItems!: HTMLCollectionOf<Element>;
  private idxToScroll!: number;
  private element!: Element;
  private main: boolean = false;
  private second: boolean = false;

  public created() {
    this.setClassName('selected');
    window.addEventListener('keydown', this.onkeypress);
  }

  public mounted() {
    if (document.getElementsByClassName('main-menu').length > 0) {
      this.initValueMainMenu();
      this.$on('actionTriggered', this.forceUpdateMainValue);
    } else if (document.getElementsByClassName('secondary-menu-expandable').length > 0) {
      this.initValueSecondaryMenu();
      this.$parent!.$on('expandableMenu', this.forceUpdateSecondaryValue);
    } else {
      this.initBasicValue();
    }
  }

  public beforeDestroy() {
    this.$off('actionTriggered');
    this.$parent!.$off('expandableMenu');
    this.resetCurrentIndexItemScroll();
    window.removeEventListener('keydown', this.onkeypress);
  }

  public setClassOnItem(location: string | undefined) {
    if (location === 'page_down') {
      this.selectItemByKeyDown();
    } else if (location === 'page_up') {
      this.selectItemByKeyUp();
    }
  }

  public setScrollBar(location: string | undefined) {
    if (this.element !== undefined) {
      if (location === 'page_down') {
        this.element.scrollTop = this.element.scrollHeight;
      } else if (location === 'page_up') {
        this.element.scrollTop = 0;
      }
    }
  }

  private forceUpdateMainValue() {
    this.$nextTick(() => this.initValueMainMenu());
  }

  private async forceUpdateSecondaryValue() {
    this.$nextTick(() => this.initValueSecondaryMenu());
  }

  private initValueMainMenu() {
    this.main = true;
    this.second = false;
    const refs = document.getElementsByClassName('container no-gutters align-content');
    this.$nextTick(() => {
      if (document.getElementsByClassName('main-menu').length > 0) {
        return this.setVariables(refs[0], refs[0].getElementsByClassName('cmc-main-menu-btn'));
      }
    });
  }

  private initValueSecondaryMenu() {
    this.main = false;
    this.second = true;
    const refs = document.getElementsByClassName('v-list');
    this.$nextTick(() => {
      if (document.getElementsByClassName('secondary-menu-expandable').length > 0) {
        if (refs[0]) {
          this.setVariables(refs[0], refs[0].getElementsByClassName('secondary-menu-expandable__button'));
        }
      }
    });
  }

  private initBasicValue() {
    this.main = false;
    this.second = false;
    const refs = document.getElementsByClassName('v-list');
    this.setVariables(refs[1], refs[1].getElementsByClassName('row justify-start'));
  }

  private onkeypress(e: KeyboardEvent) {
    const key = e.code;
    e.preventDefault();
    if (key === 'ArrowUp' || key === 'ArrowDown') {
      const location = key === 'ArrowUp' ? 'page_up' : 'page_down';
      const triggers = [
        {
          payload: {
            location,
          },
          type: EmulatorTriggerType.SCROLL_ITEM,
        },
      ];
      this.dispatchActions(triggers);
      this.setClassOnItem(location);
    } else if (key === 'Enter') {
      this.handleEnter();
    }
  }

  private setClassName(className: string) {
    this.className = className;
  }

  private setListItems(listItems: HTMLCollectionOf<Element>) {
    this.listItems = listItems;
  }

  private setElement(element: Element) {
    this.element = element;
  }

  private setVariables(element: Element, items: HTMLCollectionOf<Element>) {
    this.setElement(element);
    this.setListItems(items);
    const maxItems = items !== undefined ? (items.length - 1) : 0;
    this.setMaxIndexItemScroll(maxItems);
    this.idxToScroll = maxItems > 25 ? (Math.round((maxItems - 1) / 3) * 2) : 20;
  }

  private selectItemByKeyUp() {
    if (this.listItems && this.listItems.length > 0) {
      if (this.currentIndexItemScroll === (this.listItems.length - this.idxToScroll)) {
        this.setScrollBar('page_up');
      }
      this.deleteClass(document.getElementsByClassName(this.className));
      this.listItems[this.currentIndexItemScroll].classList.add(this.className);
    }
  }

  private selectItemByKeyDown() {
    if (this.listItems && this.listItems.length > 0) {
      if (this.currentIndexItemScroll === this.idxToScroll) {
        this.setScrollBar('page_down');
      }
      this.deleteClass(document.getElementsByClassName(this.className));
      this.listItems[this.currentIndexItemScroll].classList.add(this.className);
    }
  }

  private deleteClass(items: HTMLCollectionOf<Element>) {
    if (items !== undefined) {
      Array.from(items).map((elt: Element) => elt.classList.remove(this.className));
    }
  }

  private handleEnter() {
    const selectElement: Element = document.getElementsByClassName(this.className)[0];
    if (selectElement) {
      if (this.main) {
        (selectElement as HTMLElement).click();
        this.resetCurrentIndexItemScroll();
      } else if (this.second) {
        (selectElement as HTMLElement).click();
      } else {
        selectElement?.parentElement?.click();
      }
    }
  }
}

export default KeyboardNavigationMixin;
