






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'go-back',
})
export default class GoBack extends Vue {
  @Prop({
    default: 'text',
  })
  public $attrs!: any;

  public backOnClick(): void {
    return this.$router.go(-1);
  }

}
