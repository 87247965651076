const EN_LOCALES = {
  auth: {
    forgotPassword: {
      title: 'Forgot password',
      sendCode: 'Send Code',
      alert: {
        title: 'Don\'t close this tab.',
        text: 'An email with a reset code will be sent to your inbox. This can take up to 2 minutes. ' +
          'Enter your code in the field below and type your new password.',
        junkCaption: 'If you didn\'t receive the email, please check your junk mail.',
      },
    },
    error: {
      UserNotConfirmedException: 'Your email has not been confirmed yet. Verify your inbox.',
      PasswordResetRequiredException: 'You need to reset your password.',
      UserNotFoundException: 'Verify with your instructor if you have subscribed with this email address.',
      NotAuthorizedException: 'You have entered an invalid username or password.',
      NotAuthorizedExceptionDisabled: 'Your account has been disabled.',
      UnknownException: 'An error occurred. Retry later or contact your instructor.',
    },
  },
  common: {
    roles: {
      guest: 'Guest',
      user: 'Client',
      instructor: 'Instructor',
      fleetChief: 'Fleet Chief',
      admin: 'Admin',
    },
    aircraft: {
      g450: 'G-450',
      g550: 'G-550',
      g650: 'G-650',
      ufo: 'UFO',
    },
    forms: {
      title: 'Title',
      description: 'Description',
    },
    confirmDeletePopup: {
      deleteTitle: 'Delete {itemName} ?',
      archiveTitle: 'Archive {itemName} ?',
      deleteDescription: 'This operation is immediate and cannot be undone.',
      archiveDescription: 'This operation is immediate. The data can be retrieved later.',
    },
    tables: {
      itemsPerPage: 'Items per page',
      pageNumber: 'Page {number} of {total}',
    },
    error: {
      fieldRequired: 'This field is required.',
      minCharacters: 'This field must have more than {number} characters.',
      maxCharacters: 'This field must have less than {number} characters.',
      httpProblem: 'A problem occurred while retrieving data. Try again.',
    },
    authentication: 'Log in',
    email: 'E-mail',
    password: 'Password',
    validate: 'Validate',
    submit: 'Submit',
    save: 'Save',
    edit: 'Edit',
    close: 'Close',
    downloading: 'Downloading',
    proceed: 'Proceed',
    delete: 'Delete',
    remove: 'Remove',
    cancel: 'Cancel',
    resetForm: 'Reset Form',
    logout: 'Log out',
    givenName: 'Full Name',
    phoneNumber: 'Phone Number',
    code: 'Code',
    newPassword: 'New Password',
    oldPassword: 'Old Password',
    for: 'for',
    search: 'Search',
    date: 'Date',
    from: 'from',
    to: 'to',
    welcome: 'Welcome',
    inactivityDialogTitle: 'Are you there?',
    inactivityDialogText1: 'Due to inactivity your login session will expire. ' +
      'Click continue to remain logged in.',
    inactivityDialogText2: 'seconds remaining',
    inactivityDialogText3: 'You\'ve been logged out due to inactivity.',
    inactivityDialogButton: 'Continue',
    noDataAvailable: 'There is no data to display at the moment.',
  },
  drawer: {
    instructorTools: 'Instructor Tools',
    fleetChiefTools: 'Fleet Chief Tools',
    administrationTools: 'Administration Tools',
  },
  emulatorView: {
    list: {
      aircraft: 'Aircraft',
      title: 'Title',
      cloneOf: 'Clone Of',
      actions: 'Actions',
    },
  },
  globalUI: {
    dialog: {
      firstLogin: {
        header: 'First Connection',
        body: 'You need to complete your profile information in order to be able to start using this application.',
        error: 'Something went wrong, please check your connection and try again.',
      },
      profile: {
        header: 'Account Details',
        firstName: 'First Name',
        lastName: 'Last Name',
        company: 'Company',
        passwordChange: 'Change Password',
        oldPassword: 'Old Password',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        image: 'Avatar',
        error: 'Something went wrong, please check your connection and try again.',
      },
    },
  },
  login: {
    noAccount: 'No Account?',
    forgotPassword: 'Forgot Password?',
    changePassword: 'Change Password',
    confirmAccount: 'Confirm Account',
  },
  signUp: {
    signUpForm: 'Sign Up',
    haveAccount: 'Have An Account?',
    confirmPassword: 'Confirm Password',
    setPassword: 'Set your password',
  },
  confirmSignUp: {
    confirmSignUpForm: 'Confirm Account',
    confirmationCode: 'Code',
    resendCode: 'Resend Code',
  },
  changePassword: {
    title: 'Change password',
    error: 'Something went wrong, please check your connection and try again.',
    success: 'Password changed!',
  },
  completePassword: {
    title: 'Complete account',
  },
  dashboard: {
    title: 'Dashboard',
    footer: 'Powered by ©Menya',
  },
  profile: {
    title: 'Profile',
    avatar: 'Avatar',
    pickAvatarLabel: 'Pick An Avatar',
  },
  editor: {
    title: 'Emulator Editor',
    createFormTitle: 'Create New Emulator',
    emulatorId: 'Emulator ID',
    titleInput: 'Title',
    descriptionInput: 'Description',
    aircraftInput: 'Aircraft Model',
    templateIDInput: 'Clone from Emulator...',
    componentId: 'Component ID',
    editSwitch: 'Allow edit ?',
    imageLabel: 'Image',
    addImageLabel: 'Choose an image (Optional)',
    add: 'Add New Emulator',
    addPanes: 'Add A New Pane',
    addComponent: 'Add A New Component',
    editComponentTitle: 'Edit a component',
    nameInput: 'Name',
    nameInputHint: 'Used for naming purpose. The clients will not see this.',
    paneTypeInput: 'Type of Pane',
    componentTypeInput: 'Type of Component',
    valueInput: 'Value',
    statusesInput: 'Statuses',
    fullEditMode: 'Full Edit Mode',
    addTrigger: 'Add A New Trigger',
    triggerType: 'Type of Trigger',
    triggersTitle: 'Triggers',
    triggerLocation: 'Location',
    triggerExpandPanel: 'ID of Expanded Item',
    triggerDirection: 'Direction',
    addViewCondition: 'Add View Condition',
    viewConditionTitle: 'View Conditions',
    addViewConditionButton: 'Add A New Condition',
    unknownPane: 'Unnamed Pane',
    unknownComponent: 'Unnamed Component',
    emulatorListRefresh: 'Refreshing emulator list...',
    emulatorListUpdated: 'Emulators list updated',
    emulatorListError: 'Error while refreshing emulator list',
  },
  emulator: {
    emulators: 'Emulators',
    panes: 'Panes',
    components: 'Components',
    clickNotSupportedDialog: {
      title: 'Click Not Supported',
      description: 'The intended functionality of this button has been deactivated by the instructor.',
    },
  },
  navigationReport: {
    title: 'Navigation Reporting',
    selectedAction: 'Selected Action: ',
    type: 'Type: ',
    data: 'Data: to ',
    start: 'Start',
    currentPane: 'From',
    destinationPane: 'to',
    expands: 'Expanding menu in',
    previous: 'Previous',
    prev: 'Going back to',
    of: 'of',
    navigate: 'Navigate',
    change_page: 'Change Page',
    expand_panel: 'Expand Panel',
    scroll_bar: 'Scroll Bar',
    scroll_item: 'Scroll Item',
    click_not_supported: 'Click Not Supported',
  },
  courseTemplates: {
    title: 'Course Templates',
    templatePageTitle: 'Course Template View',
    lesson: {
      add: 'Add Lesson',
      cockpit: 'Cockpit Tools',
      edit: 'Edit Lesson',
      end: 'End Lesson',
      exploratory: 'Exploratory',
      lessonType: 'Lesson Type',
      popup: {
        title: 'Do you want to end your session?',
        desc: 'Ending the session will save your progress and notify your instructors.',
        agree: 'Agree',
        disagree: 'Disagree',
      },
    },
    templateForm: {
      addTitle: 'Add Template',
      editTitle: 'Edit Template',
      prefix: 'Course Template Prefix',
      aircraft: 'Choose the aircraft',
    },
    emulatorsForm: {
      addEmulators: 'Add Emulators',
    },
  },
  coursesPlanner: {
    title: 'Courses Planner',
    planCourse: 'Plan a course',
    course: 'Course View',
    usersProfile: {
      student: 'Student',
      completedCourses: 'Completed courses',
      courses: 'Courses',
      emulator: 'Emulators',
      infos: 'Infos',
      usersProfileCard: {
        noCourses: 'No course has been completed yet',
      },
    },
    form: {
      title: 'Planning Information',
      chooseTemplate: 'Choose a course template',
      noData: 'Start to type the name of the template...',
      noTemplate: 'Cannot find the template.',
      instructors: 'Select instructors',
      noInstructor: 'Cannot find this instructor.',
      rangeError: 'The end date must be after the start date.',
    },
    usersList: {
      selectLabel: 'Select users',
    },
    archiveLabel: 'Archive Course',
    enabled: 'enabled',
    disabled: 'disabled',
    clients: 'clients',
    instructors: 'instructors',
    addClientLabel: 'Add/Remove Clients',
    addClientSuccess: 'Clients list updated',
    addClientError: 'Error while fetching clients list',
    addInstructorLabel: 'Add/Remove Instructors',
    addInstructorSuccess: 'Instructors list updated',
    addInstructorError: 'Error while fetching Instructors list',
    fetchCoursePlansSuccess: 'Course Plans list updated',
    fetchCoursePlansError: 'Error while fetching coursePlan list',
    choseCoursePlanSuccess: 'Course Plans loaded',
    choseCoursePlanError: 'Error while loading coursePlan',
    deleteCoursePlanSuccess: 'Course Plan deleted',
    deleteCoursePlanError: 'Error while deleting coursePlan',
    noCourse: 'There are no courses to display.',
    noOnGoingCourse: 'You have no ongoing courses right now.',
  },
  contactUs: {
    title: 'Contact Us',
    clipboardMessage: '"{copiedItem}" has been copied to the clipboard.',
  },
  access: {
    noAccess: {
      title: 'Oops!',
      description: 'It seems like you don\'t have the permissions to see the content of this website. Please contact ' +
        'your instructor for access.',
    },
  },
  pageNotFound: {
    title: 'Page not found.',
    link: 'Back to Dashboard',
  },
  loader: {
    text: 'Please stand by',
  },
  user: {
    manager: {
      title: 'User Manager',
      fetchUserPageSuccess: 'Users list updated',
      fetchUserPageError: 'Error while fetching user list',
      deleteDialogTitle: 'Are you sure you want to delete this user?',
      deleteDialogText1: 'You\'re about to delete user ',
      deleteDialogText2: '. This action is definitive and irreversible. Proceed with caution.',
      deleteDialogDeleteButton: 'Delete',
      deleteDialogCancelButton: 'Cancel',
      addRoleDialogTitle: 'Which roles do you want to add?',
      removeRoleDialogTitle: 'Which roles do you want to remove?',
      addRoles: 'Add',
      removeRoles: 'Remove',
      duplicateElement: 'You cannot add the same role twice.',
      setRoleDialogTitle: 'Which roles do you want to assign?',
      setAircraftDialogTitle: 'Which aircraft do you want to assign?',
      addPeople: 'Add Users',
      actionActivateUser: 'Activate',
      actionDeactivateUser: 'Deactivate',
      actionSetRoles: 'Set Roles',
      actionSetAircraft: 'Set Aircraft',
      actionAddRoles: 'Add Role(s)',
      actionRemoveRoles: 'Remove Role(s)',
      studentIdLabel: 'Student ID',
      aircraftLabel: 'Aircraft',
      givenNameLabel: 'Given Name',
      emailLabel: 'Email',
      rolesLabel: 'Roles',
      enabledLabel: 'Activated',
      searchLabel: 'Search',
      clearLabel: 'Clear selection',
      createUsersSuccess: 'User(s) created',
      createUsersError: 'Error while creating user(s)',
      createUsersTitle: 'Add Users',
      createUsersTitleText1: 'One person per line.',
      createUsersTitleText2: 'In case of student, add the student ID after the email address.',
      createUsersTitleText3: 'Add a comma between the email address and the student ID.',
      createUsersTitleText4: 'People to be added.',
      createUsersTitleText5: 'An email will be sent to every user containing their temporary password.',
      createUsersTitleText6: 'You won\'t be able to retrieve passwords after you confirm.',
      createUsersLabel1: 'EMAIL (required), STUDENT_ID (optional)',
      createUsersValidate: 'Validate',
      createUsersCancel: 'Cancel',
      createUsersEmail: 'Email',
      createUsersStudentID: 'Student ID',
      createUsersTempPW: 'Temporary Password',
      createUsersConfirm: 'Confirm',
      createUsersEdit: 'Edit',
      createUsersAddRolesLabel: 'Select role...',
      createUsersAddAircraftLabel: 'Select aircraft...',
      profileUnavailable: 'Profile is not available',
      updateRoleError: 'Error while updating role(s)',
      updateRoleSuccess: 'Role(s) updated',
      updateAircraftError: 'Error while updating aircraft',
      updateAircraftSuccess: 'Aircraft updated',
      profileAvailable: 'Show profile',
      userExists: 'User already signed up.',
      userExistsHint: 'Hint',
      useOldPassword: 'They can user their old password.',
      NA: '<NA>',
    },
  },
  enum: {
    emulatorPaneType: {
      mainMenu: 'Main Menu',
      secondaryMenu: 'Secondary Menu',
      endOfFlight: 'End Of Flight',
      paginator: 'Paginator',
      paginatorList: 'Paginator List',
      fanStatusDu: 'Fan Status DU',
      fanStatusMau: 'Fan Status MAU',
      fault: 'Fault Pane',
      fde: 'FDE',
      info: 'Info Pane',
      flightDeckEffects: 'Flight Deck Effects',
      table: 'Table Pane',
      tableStatusPane: 'Table Status Pane',
      dataAcSystemPane: 'Data System Pane',
      testInitializing: 'Test Initializing',
      tcasPane: 'Tcas System Pane',
    },
    emulatorComponentType: {
      text: 'Text',
      list: 'List',
      listItem: 'List Item',
      button: 'Button',
      sideMenu: 'Side Menu',
      sideMenuItem: 'Side Menu Item',
      table: 'Table',
      tableHeader: 'Table Header',
      tableBody: 'Table Body',
      tableRow: 'Table Row',
      tableRowItem: 'Table Row Item',
      image: 'Image',
      boolean: 'Boolean',
      indicator: 'Square Indicator Boolean',
      round_indicator: 'Round Indicator Boolean',
      rectangle_indicator: 'Rectangle Indicator Boolean',
      cross_indicator: 'Cross Indicator Boolean',
      style: 'Style',
      listGauge: 'List Gauge',
      gauge: 'Gauge',
      tcas: 'Tcas',
      tcas_row: 'Tcas row',
      tcas_item: 'Tcas item',
    },
    emulatorComponentStatus: {
      faulted: 'Faulted',
      external: 'External',
      empty: 'Empty',
      idle: 'Internal idle',
      standby: 'Stand by',
      disabled: 'Disabled',
      match: 'Match',
      nomatch: 'No match',
      not_supported: 'Not Supported',
    },
    emulatorTriggerType: {
      navigate: 'Navigate',
      changePage: 'Change Page',
      expandPanel: 'Expand Panel',
      scrollBar: 'Scroll Bar',
      scrollItem: 'Scroll Item',
      clickNotSupported: 'Click Not Supported',
    },
    lessonType: {
      exploratory: 'Exploratory',
      monitored: 'Monitored',
      evaluation: 'Evaluation',
    },
    unknown: 'Unknown Type',
  },
  dateStatus: {
    notStarted: 'Not Started',
    inProgress: 'In Progress',
    hadEnded: 'Has Ended',
  },
  evaluation: {
    title: 'Evaluation',
    form: {
      hasSucceeded: 'Passed',
      feedback: 'Comments',
      grade: 'Grade',
      save: 'Save',
    },
    statistics: {
      title: 'Statistics',
      clicks: 'Clicks',
      time: 'Time (min)',
    },
    lesson: {
      title: 'Lesson',
    },
  },
  view: {
    welcome: 'Welcome {name}!',
    loggedAs: 'Logged as {name}',
    loading: 'Loading...',
    error: {
      oops: 'We\'re sorry {name}.',
      genericMessage: 'We are sorry for this technical issue. We will fix the problem as soon as possible.<br>Please contact your instructor for help.',
      noEmulatorId: {
        message: 'No ID was provided to load an emulator.',
      },
      emulatorNotFound: {
        message: 'The emulator you are trying to load cannot be found.',
      },
      dataNotFound: {
        message: 'The emulator data cannot be found or loaded.',
      },
    },
  },
};

export default EN_LOCALES;
