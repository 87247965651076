import moment from 'moment';
import {
  Emulator,
  EmulatorComponent,
  EmulatorComponentsMap,
  EmulatorStyle,
  EmulatorTrigger,
} from '@/models/EmulatorModels';
import { EmulatorComponentStatus, EmulatorTriggerType } from '@/enums/emulator';
import axios from 'axios';
import { NavigationPayload } from '@/models/NavigationModels';

export const isProd = (): boolean => {
  return process.env.NODE_ENV === 'production';
};

export const getBrowserLanguage = (): string => {
  const nav: any = navigator;
  if (nav.languages && nav.languages.length) {
    return nav.languages[0];
  } else {
    return nav.userLanguage || nav.language || nav.browserLanguage || 'en';
  }
};

// Timing and Dates functions

export const getDate = (): moment.Moment => {
  return moment();
};

export const getHours = (time: Date): string => {
  return moment(time).format('HH');
};

export const getMinutes = (time: Date): string => {
  return moment(time).format('mm');
};

export const wait = (ms: number) => new Promise((r) => setTimeout(r, ms));

// eslint-disable-next-line
const calculateRemainingSessionTime = (expirationDate: Date): number => {
  const now = new Date();
  const delay = expirationDate.getTime() - now.getTime();
  return Math.round(delay / 1000);
};

// Emulator Flags

export const getComponentPath = (pane: EmulatorComponent): string => {
  let componentPath: string = '';

  if (pane.id) {
    componentPath = pane.id;
  } else if (pane.type) {
    componentPath = pane.type;
  }

  return componentPath;
};

export const itemHasStatus = (item: EmulatorComponent, testedStatus: EmulatorComponentStatus): boolean => {
  let hasStatus = false;
  if (item && item.statuses) {
    item.statuses.forEach((status: EmulatorComponentStatus) => {
      if (status === testedStatus) {
        hasStatus = true;
      }
    });
  }
  return hasStatus;
};

export const itemHasTrigger = (item: EmulatorComponent, testedTrigger: EmulatorTriggerType): boolean => {
  let hasTrigger = false;
  if (item.triggers) {
    item.triggers.forEach((trigger: EmulatorTrigger) => {
      if (trigger.type === testedTrigger) {
        hasTrigger = true;
      }
    });
  }
  return hasTrigger;
};

// Emulator css

export const itemHasStyles = (styles: EmulatorStyle): any => {
  const st: any = {};
  if (styles) {
    st.id = (styles.id !== undefined ? st.id = styles.id : st.id = '');
    st.class = (styles.class !== undefined ? st.class = styles.class : st.class = '');
    st.style = (styles.css !== undefined ? st.style = styles.css : st.style = {});
  }
  return st;
};

export const fetchAllMasterEmulators = async (): Promise<Emulator[]> => {
  let emulators: Emulator[];
  let mockRequest = await axios.get('base/g650.json', { baseURL: 'http://localhost:8080' });
  emulators = [mockRequest.data];
  mockRequest = await axios.get('base/g550.json', { baseURL: 'http://localhost:8080' });
  emulators = [...emulators, mockRequest.data];
  mockRequest = await axios.get('base/g450.json', { baseURL: 'http://localhost:8080' });
  emulators = [...emulators, ...[mockRequest.data]];
  mockRequest = await axios.get('base/ufo.json', { baseURL: 'http://localhost:8080' });
  emulators = [...emulators, ...[mockRequest.data]];
  return emulators;
};

export const fetchMasterEmulatorByID = async (emulatorId: string): Promise<Emulator> => {
  const emulators: Emulator[] = await fetchAllMasterEmulators();
  return (emulators).find((emulatorItem: Emulator) => {
    return emulatorItem.id === emulatorId;
  }) as Emulator;
};

export const fetchSCORMStandAloneData = async (): Promise<Emulator | null> => {
  const fileData: Response = await fetch('./data.json', {
    mode: 'no-cors',
  });
  if(fileData.status === 200){
    return await fileData.json() as Emulator;
  } else {
    return null;
  }
};

export const fetchAllNavigationReport = async (): Promise<NavigationPayload[]> => {
  const mockRequest = await axios.get('base/navigationReport.json', { baseURL: 'http://localhost:8080' });
  return mockRequest.data.report as NavigationPayload[];
};

export const getObjectPath = (navigationPath: string[]) => {
  const safePathString = navigationPath.map((path: string) => path.replace(/\./g, '_'));
  // const objectPath: string = safePathString.toString().replace(/\,/g, '.');
  const objectPath: string = safePathString.toString().replace(/,/g, '.');
  return objectPath;
};

export const objHasKey = (obj: EmulatorComponentsMap, key: string): boolean => {
  return Object.keys(obj).includes(key);
};
