




















































































































import Vue from 'vue';
import { namespace } from 'vuex-class';
import Component from 'vue-class-component';
import { ForgotPasswordForm } from '@/models/AuthenticationModels';
import GoBack from '@/components/shared/GoBack.vue';
import TheFooter from '@/components/shared/TheFooter.vue';

const auth = namespace('AuthModule');

@Component({
  components: {
    GoBack,
    TheFooter,
  },
})
export default class ForgotPassword extends Vue {

  @auth.Action
  public forgotPassword!: (email: string) => Promise<void>;

  @auth.Action
  public forgotPasswordSubmit!: (forgotPasswordData: ForgotPasswordForm) => Promise<void>;

  private codeSent: boolean = false;
  private valid: boolean = false;
  private sendCodeValid: boolean = false;
  private confirmPassword = '';

  private form: ForgotPasswordForm = {
    email: '',
    code: '',
    newPassword: '',
  };

  private emailRules: any[] = [
    (v: string) => !!v || 'The e-mail is required',
    (v: string) => /.+@.+/.test(v) || 'E-mail must be valid',
  ];

  private codeRules: any[] = [
    (v: string) => !!v || 'The confirmation code is required',
  ];

  private passwordRules: any[] = [
    (v: string) => !!v || 'The password is required',
    (v: string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/.test(v) ||
      'Password must have at least eight characters, one uppercase letter,' +
      ' one lowercase letter and one number',
  ];

  private confirmPasswordRules: any[] = [
    (v: string) => !!v || 'You must confirm the password.',
    (v: string) => this.matchPassword(v) || 'Passwords should match.',
  ];

  public mounted() {
    if (this.$route.query.code && typeof this.$route.query.code === 'string') {
      this.form.code = this.$route.query.code as string;
      this.codeSent = true;
    }
  }

  private matchPassword(value: string) {
    return value === this.form.newPassword;
  }

  private validate(): void {
    const formToSend: ForgotPasswordForm = {
      email: this.form.email.toLowerCase(),
      code: this.form.code,
      newPassword: this.form.newPassword,
    };

    this.forgotPasswordSubmit(formToSend).then(() => {
      this.$router.push('/account/signin');
    }).catch(() => {
      // removing trace
    });
  }

  private resend(): void {
    const self = this;
    this.forgotPassword(this.form.email.toLowerCase()).then(() => {
      self.codeSent = true;
    }).catch(() => {
      // removing trace
    });
  }
}
