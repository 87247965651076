






















import { Component, Prop } from 'vue-property-decorator';
import { UIBreadcrumb } from '@/models/UIModels';
import UserMixin from "@/mixins/UserMixin";
import {mixins} from "vue-class-component";

@Component({})
export default class BreadCrumbs extends mixins(UserMixin) {

  @Prop()
  public listItems!: UIBreadcrumb[];

  private get homeLink(): string {
    return this.isOnlyAdmin ? '/user-manager' : '/dashboard';
  }
}
