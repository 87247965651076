






























import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Emulator, EmulatorComponent, EmulatorTrigger } from '@/models/EmulatorModels';
import CMCMainMenuPane from '@/components/cmc/panes/CMCMainMenuPane.vue';
import CMCSecondaryMenuPane from '@/components/cmc/panes/CMCSecondaryMenuPane.vue';
import CMCEndOfFlightPane from '@/components/cmc/panes/CMCEndOfFlightPane.vue';
import CMCDuFanStatusPane from '@/components/cmc/panes/CMCDuFanStatusPane.vue';
import CMCMauFanStatusPane from '@/components/cmc/panes/CMCMauFanStatusPane.vue';
import CMCFaultPane from '@/components/cmc/panes/CMCFaultPane.vue';
import CMCPaginator from '@/components/cmc/paginator/CMCPaginator.vue';
import { CMCComponentFactory } from '@/components/componentFactory/CMCComponentFactory';
import CMCLayoutSideMenu from '@/components/cmc/sidemenu/CMCLayoutSideMenu.vue';
import CMCFlightDeckEffectsPane from '@/components/cmc/panes/CMCFlightDeckEffectsPane.vue';
import CMCTablePane from '@/components/cmc/panes/CMCTablePane.vue';
import CMCTableStatusPane from '@/components/cmc/panes/CMCTableStatusPane.vue';
import CMCFDEPane from '@/components/cmc/panes/CMCFDEPane.vue';
import CMCDataAcSystemPane from '@/components/cmc/panes/CMCDataAcSystemPane.vue';
import ClickNotSupportedDialog from '@/components/cmc/common/ClickNotSupportedDialog.vue';
import CMCIndicatorStatusPane from '@/components/cmc/panes/CMCIndicatorStatusPane.vue';
import CMCTestInitializing from '@/components/cmc/panes/CMCTestInitializing/CMCTestInitializing.vue';
import CMCTcasSystemPane from '@/components/cmc/panes/CMCTcasSystemPane.vue';
import CMCDataPreconditionPane from '@/components/cmc/panes/CMCDataPreconditionPane.vue';

const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    ClickNotSupportedDialog,
    CMCMainMenuPane,
    CMCSecondaryMenuPane,
    CMCEndOfFlightPane,
    CMCDuFanStatusPane,
    CMCMauFanStatusPane,
    CMCFDEPane,
    CMCFaultPane,
    CMCPaginator,
    CMCLayoutSideMenu,
    CMCFlightDeckEffectsPane,
    CMCTablePane,
    CMCTableStatusPane,
    CMCDataAcSystemPane,
    CMCIndicatorStatusPane,
    CMCTestInitializing,
    CMCTcasSystemPane,
    CMCDataPreconditionPane,
  },
})
export default class CMCEmulator extends Vue {

  @emulatorModule.Getter
  public dialogNotSupportedOpen!: boolean;

  @emulatorModule.Action
  public dispatchActions!: (triggers: EmulatorTrigger[]) => void;

  @Prop()
  public currentEmulator!: Emulator;

  @Prop()
  public currentEmulatorPane!: EmulatorComponent;

  private currentEmulatorComponent: string = '';

  get isPaginator(): boolean {
    return CMCComponentFactory.isPaginator(this.currentEmulatorPane!);
  }

  get mainClasses(): string {
    return 'cmc-emulator ' + this.currentEmulator?.aircraftModel;
  }

  get hasSideMenu(): boolean {
    return this.currentEmulatorPane!.components!.sideMenu
      && this.currentEmulatorPane!.components!.sideMenu.components !== {};
  }

  @Watch('currentEmulatorPane', { immediate: true, deep: true })
  public switchPane(val: EmulatorComponent) {
    if (val) {
      this.currentEmulatorComponent = CMCComponentFactory.getComponentByType(val);
    }
  }

  public actionTriggeredHandler(triggers: EmulatorTrigger[]): void {
    this.dispatchActions(triggers);
  }
}
