export default class RuleUtility {

  public static emailRules: any[] = [
    (v: string) => !!v || 'The e-mail is required',
    (v: string) => /.+@.+/.test(v) || 'E-mail must be valid',
  ];

  public static nonNullFieldRules: any[] = [
    (v: string) => !!v || 'This field is required',
  ];

  public static nonEmptyArray: any[] = [
    (v: any) => {
      return v.length > 0 || 'This field cannot be empty.';
    },
  ];

  public static phoneNumberRules: any[] = [
    (v: string) => !!v || 'The phone number is required',
    (v: string) => v.length === 10 || 'Phone number must be valid (ex. (123) 456 - 7890 )',
  ];

  public static oldPasswordRules: any[] = [
    (v: string) => !!v || 'The password is required',
  ];

  public static passwordRules: any[] = [
    (v: string) => !!v || 'The password is required',
    (v: string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/.test(v) ||
      'Password must have at least eight characters, one uppercase letter,' +
      ' one lowercase letter and one number',
  ];

  public static confirmPasswordRules(password: string): any[] {
    return [
      (v: string) => !!v || 'You must confirm the password.',
      (v: string) => v === password || 'Passwords should match.',
    ];
  }
}
