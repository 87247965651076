





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorComponentsMap, EmulatorTrigger } from '@/models/EmulatorModels';
import * as eventManager from '@/components/shared/SideMenuEventManager';
import CMCMainMenuPane from '@/components/cmc/panes/CMCMainMenuPane.vue';
import CMCSecondaryMenuPane from '@/components/cmc/panes/CMCSecondaryMenuPane.vue';
import CMCEndOfFlightPane from '@/components/cmc/panes/CMCEndOfFlightPane.vue';
import CMCDuFanStatusPane from '@/components/cmc/panes/CMCDuFanStatusPane.vue';
import CMCMauFanStatusPane from '@/components/cmc/panes/CMCMauFanStatusPane.vue';
import CMCFDEPane from '@/components/cmc/panes/CMCFDEPane.vue';
import { EmulatorTriggerType } from '@/enums/emulator';
import CMCInfoPane from '@/components/cmc/panes/CMCInfoPane.vue';
import CMCLayoutSideMenu from '@/components/cmc/sidemenu/CMCLayoutSideMenu.vue';
import { itemHasTrigger } from '@/utils/common';
import CMCFlightDeckEffectsPane from '@/components/cmc/panes/CMCFlightDeckEffectsPane.vue';
import { namespace } from 'vuex-class';
import { CMCComponentFactory } from '@/components/componentFactory/CMCComponentFactory';

const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    CMCMainMenuPane,
    CMCSecondaryMenuPane,
    CMCEndOfFlightPane,
    CMCDuFanStatusPane,
    CMCMauFanStatusPane,
    CMCFDEPane,
    CMCInfoPane,
    CMCPaginator,
    CMCLayoutSideMenu,
    CMCFlightDeckEffectsPane,
  },
})
export default class CMCPaginator extends Vue {

  @emulatorModule.Action
  public dispatchActions!: (triggers: EmulatorTrigger[]) => void;

  @Prop({
    required: true,
  })

  private currentEmulatorPane!: EmulatorComponent;

  private currentPage: number = 0;
  private currentPagePane!: EmulatorComponent;
  private currentEmulatorComponent: string = '';

  get totalPages(): number {
    return Object.keys(this.orderedPanes).length;
  }

  public get components(): EmulatorComponentsMap {
    return this.currentEmulatorPane.components!.pages!.components!;
  }

  @Watch('orderedPanes', { immediate: true, deep: true })
  public selectPane(val: EmulatorComponentsMap, oldVal: EmulatorComponentsMap) {
    if (val && val !== oldVal) {
      this.currentPagePane! = this.orderedPanes[Object.keys(this.orderedPanes)[0]];
    }
  }

  @Watch('currentPagePane', { immediate: true, deep: true })
  public switchPane(val: EmulatorComponent, oldVal: EmulatorComponent) {
    this.currentEmulatorComponent = CMCComponentFactory.getComponentByType(val);
  }

  get orderedPanes(): EmulatorComponentsMap {
    const ref: any = this;
    const tempOrderedPanes: EmulatorComponentsMap = {};
    Object.keys(this.components).sort().forEach((key) => {
      tempOrderedPanes[key] = ref.components[key];
    });
    return tempOrderedPanes;
  }

  protected created() {
    this.subscribeToSideMenu();
  }

  private subscribeToSideMenu(): void {
    eventManager.eventBus.$on('sideMenuClickDispatch', (component: EmulatorComponent) => {
      if (itemHasTrigger(component, EmulatorTriggerType.CHANGE_PAGE)) {

        const pageTrigger: EmulatorTrigger | undefined = component.triggers?.filter((trigger: EmulatorTrigger) => {
          return trigger.type === EmulatorTriggerType.CHANGE_PAGE;
        })[0];

        if (pageTrigger && pageTrigger.payload) {
          if (pageTrigger.payload.direction! > 0) {
            this.nextPage();
          } else if (pageTrigger.payload.direction! < 0) {
            this.previousPage();
          }
        }
      } else {
        if (component.triggers) {
          this.dispatchActions(component.triggers);
        }
      }
    });
  }

  private previousPage(): void {
    if (this.currentPage <= 0) {
      this.currentPage = 0;
      this.$emit('paginatorStart');
    } else {
      this.currentPage--;
    }

    this.currentPagePane! = this.orderedPanes[Object.keys(this.orderedPanes)[this.currentPage]];
    if (this.currentPagePane) {
      this.currentEmulatorComponent = '';
      this.$nextTick(() => {
        this.switchPane(this.currentPagePane, this.currentPagePane);
      });
    }
  }

  private nextPage(): void {
    if (this.currentPage + 1 >= this.totalPages) {
      this.$emit('paginatorEnd');
    } else {
      this.currentPage++;
    }
    this.currentPagePane! = this.orderedPanes[Object.keys(this.orderedPanes)[this.currentPage]];

    if (this.currentPagePane) {
      this.currentEmulatorComponent = '';
      this.$nextTick(() => {
        this.switchPane(this.currentPagePane, this.currentPagePane);
      });
    }
  }

  private actionTriggeredHandler(): void {
    if (this.currentPagePane.triggers) {
      this.dispatchActions(this.currentPagePane.triggers);
    }
  }

}
