import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import {
  AddUserRolesParams,
  CreateUserParams,
  RemoveUserRolesParams,
  UpdateUserAircraftParams,
  UpdateUserProfile,
  UpdateUserRolesParams,
  User,
  UserAction,
  UserActionType,
  UserPage,
  UserProfile,
  UserSearchParams,
} from '@/models/UserModels';
import { SearchType } from '@/enums/globalUI';
import { stripSpecialCharacters } from '@/utils/labels_utils';

@Module({ namespaced: true, name: 'userModule' })
export default class UserModule extends VuexModule {
  private $currentUser: User | null = null;
  private $currentUserProfile: UserProfile | null = null;
  private $selectedUserProfile: UserProfile | null = null;
  private $selectedUser: User | null = null;
  private $userPage: UserPage | null = null;
  private $selectedUserId: string = '';
  private $autoCompleteUsers: User[] = [];

  // Page Number For Search
  private $itemsPerPageUsers: number = 20;

  private $isLoadingUserPage: boolean = false;

  public get currentUser(): User | null {
    return this.$currentUser;
  }

  public get selectedUser(): User | null {
    return this.$selectedUser;
  }

  public get userRoles(): { [index: string]: string[] } | null {
    return this.$currentUser ? this.$currentUser.roles! : null;
  }

  public get currentUserProfile(): UserProfile | null {
    return this.$currentUserProfile;
  }

  public get selectedUserProfile(): UserProfile | null {
    return this.$selectedUserProfile;
  }

  public get selectedUserId(): string {
    return this.$selectedUserId;
  }

  public get userPage(): UserPage | null {
    return this.$userPage;
  }

  public get currentUserId(): string {
    return this.context.rootGetters['AuthModule/userId'];
  }

  public get isLoadingUserPage(): boolean {
    return this.$isLoadingUserPage;
  }

  public get autoCompleteUsers(): User[] {
    return this.$autoCompleteUsers;
  }

  public get itemsPerPageUsers(): number {
    return this.$itemsPerPageUsers;
  }

  @Mutation
  public setSelectedUserId(userId: string) {
    this.$selectedUserId = userId;
  }

  @Mutation
  public setCurrentUser(user: User | null) {
    this.$currentUser = user;
  }

  @Mutation
  public setSelectedUser(user: User) {
    this.$selectedUser = user;
  }

  @Mutation
  public setCurrentUserProfile(userProfile: UserProfile | null) {
    this.$currentUserProfile = userProfile;
  }

  @Mutation
  public setSelectedUserProfile(userProfile: UserProfile) {
    this.$selectedUserProfile = userProfile;
  }

  @Mutation
  public setUserPage(userPage: UserPage) {
    this.$userPage = userPage;
  }

  @Mutation
  public setLoadingUserPage(isLoading: boolean) {
    this.$isLoadingUserPage = isLoading;
  }

  @Mutation
  public setAutoCompleteUsers(users: User[]) {
    this.$autoCompleteUsers = users;
  }

  @Mutation
  public setItemsPerPageUsers(itemsPerPage: number) {
    this.$itemsPerPageUsers = itemsPerPage;
  }

  @Action
  public async fetchUser(userId: string) : Promise<User | null>{
    const { data } = await axios.get('/user/' + userId);
    return data;
  }

  @Action({ commit: 'setSelectedUser' })
  public async fetchSelectedUser(userId: string) {
    try {
      const { data } = await axios.get('/user/' + userId);
      return data;
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  }

  @Action({ commit: 'setCurrentUser' })
  public async fetchCurrentUser(): Promise<User> {
    const { data } = await axios.get('/user/' + this.context.getters.currentUserId);
    return data as User;
  }

  @Action({ commit: 'setUserPage' })
  public async fetchUserPage(userSearchParams: UserSearchParams): Promise<UserPage | null> {
    const { data } = await axios.get('/user?size=' + userSearchParams.pageSize
      + '&page=' + userSearchParams.pageNumber);

    return data;
  }

  @Action({ commit: 'setSelectedUserProfile' })
  public async fetchUserProfile(userId: string) {
    const { data } = await axios.get('/user/' + userId + '/profile');
    return data;
  }

  @Action({ commit: 'setCurrentUserProfile' })
  public async fetchCurrentUserProfile() {

    const { data } = await axios.get('/user/' + this.context.getters.currentUserId + '/profile');

    const userProfile: UserProfile | null = data;

    return userProfile;
  }

  @Action
  public async updateCurrentUser(updateUserBody: UpdateUserProfile) {
    let userId = this.context.getters.selectedUserId;
    if (userId === '' || typeof userId === 'undefined') {
      userId = this.context.getters.currentUserId;
    }
    await axios.patch('/user/' + userId, updateUserBody);
    if (this.context.getters.currentUserId === userId) {
      await this.context.dispatch('fetchCurrentUser');
      await this.context.dispatch('fetchCurrentUserProfile');
    } else {
      await this.context.dispatch('fetchSelectedUser', userId);
      await this.context.dispatch('fetchUserProfile', userId);
      await this.context.dispatch('SearchModule/fetchItemsPerPage', SearchType.USER, { root: true });
    }
  }

  @Action
  public async updateUserAircraft(params: UpdateUserAircraftParams) {
    await axios.patch('/user/' + params.userId, { privateEmulatorIds: params.privateEmulatorIds });
  }

  @Action
  public async enableUser(userId: string) {
    const userAction: UserAction = {
      type: UserActionType.ENABLE,
    };

    await axios.put('/user/' + userId + '/action', userAction);
  }

  @Action
  public async disableUser(userId: string) {
    const userAction: UserAction = {
      type: UserActionType.DISABLE,
    };

    await axios.put('/user/' + userId + '/action', userAction);
  }

  @Action
  public async deleteUser(userId: string) {
    try {
      await axios.patch(`/user/archive/${userId}`);
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  }

  @Action
  public async createUsers(params: CreateUserParams[]) {
    await axios.post('/user', params);
  }

  // Roles
  @Action
  public async updateUserRoles(params: UpdateUserRolesParams) {
    await axios.patch('/user/' + params.userId, { roles: params.roles });
  }

  @Action
  public async addUserRoles(params: AddUserRolesParams) {
    await axios.patch('/user/addroles', params);
  }

  @Action
  public async removeUserRoles(params: RemoveUserRolesParams) {
    await axios.patch('/user/removeroles', params);
  }

  // Courses
  @Action
  public async updateUserCourses(user: User) {
    await axios.patch('/user/' + user.id, { courseIds: user.courseIds });
  }

  // Search

  @Action({ commit: 'setAutoCompleteUsers' })
  public async searchAutoCompleteUsers(params: string = ''): Promise<User[]> {
    let request: string = `/user?size=100&page=0`;

    if (params && stripSpecialCharacters(params) !== '') {
      request = `/user/quicksearch/${stripSpecialCharacters(params)}`;
    }
    try {
      const { data } = await axios.get(request);
      return data.content;
    } catch (e) {
      // eslint-disable-next-line
      console.error('Cannot search', e);
      return [];
    }
  }

  @Action
  public async searchUserByEmail(params: string): Promise<User | null> {
    const requestEmail: string = `/user/quicksearch/${params}`;
    try {
      const { data } = await axios.get(requestEmail);
      return (data.content.length > 0) ? data.content[0] : null;
    } catch (e) {
      // eslint-disable-next-line
      console.error('Cannot search', e);
      return null;
    }
  }

}
