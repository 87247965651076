




























import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EmulatorVar } from '@/models/EmulatorModels';

const emulatorModule = namespace('EmulatorModule');

@Component({})
export default class ClickNotSupportedDialog extends Vue {

  @emulatorModule.Mutation
  public setDialogNotSupportedOpen!: (isOpen: boolean) => void;

  @Prop({
    default: false,
  })
  private isOpen!: boolean;
}
