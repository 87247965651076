
































import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator';
import moment from 'moment';
import RuleUtility from '@/utils/rules';
import { getDurationLabel } from '@/utils/labels_utils';

@Component({})
export default class DateSelectorControl extends Vue {

  public ruleUtility = RuleUtility;
  public rangeMessage: string = '';

  private get dateFormatted(): string {
    let theDate: string = '';
    if (this.dates.length > 0) {
      theDate = getDurationLabel(this.dates[0], this.dates[1]);
    }
    return theDate;
  }

  @Prop()
  public readonly value!: string[];

  @Prop({
    default: true,
  })
  public readonly required!: boolean;

  private datePickerMenu: boolean = false;
  private dates: string[] = [];

  @Emit('input')
  public emitValue(): string[] {
    return this.dates;
  }

  protected created(): void {
    this.dates = this.value;
  }

  protected destroyed(): void {
    this.dates = [];
  }

  @Watch('dates')
  private checkCloseMenu(): void {
    if (this.dates.length === 2) {
      if (moment(this.dates[0]).isBefore(moment(this.dates[1]))) {
        this.rangeMessage = '';
        this.datePickerMenu = false;
        this.emitValue();
      } else {
        this.rangeMessage = this.$t('coursesPlanner.form.rangeError') as string;
        this.datePickerMenu = false;
      }
    }
  }
}
