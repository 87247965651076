



























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorComponentsMap, EmulatorNode } from '@/models/EmulatorModels';
import ResizableAddItemCard from '@/components/shared/ResizableAddItemCard.vue';
import EmulatorComponentListItem from '@/components/editor/componentsList/EmulatorComponentListItem.vue';
import { wait } from '@/utils/common';
import EmulatorComponentForm from '@/components/editor/forms/EmulatorComponentForm.vue';

@Component({
  components: {
    ResizableAddItemCard,
    EmulatorComponentListItem,
    EmulatorComponentForm,
    EmulatorComponentList: () => import('./EmulatorComponentList.vue'),
  },
})
export default class EmulatorComponentList extends Vue {
  @Prop({
    required: true,
  })
  public component!: EmulatorComponent;

  @Prop()
  public title!: string;

  public get components(): EmulatorComponentsMap {
    return this.component.components!;
  }

  public isAddNewFormOpen: boolean = false;
  public nodeName: string = '';
  public idNode: string = '';
  public selectedComponent: EmulatorComponent | null = null;

  public async openComponents(node: EmulatorNode): Promise<void> {
    this.idNode = Date.now().toString();
    this.nodeName = node.key;
    this.selectedComponent = node.component!;
    await wait(200);
    const element: HTMLElement | null = (document.getElementById(this.idNode));
    if (element) {
      await this.$vuetify.goTo(element);
    }
  }

  @Emit('onDataChanged')
  public onDataChanged(change: EmulatorNode): EmulatorNode {
    const parentComponent = this.component;

    if (change.component) {
      parentComponent.components![change.key] = change.component;
    } else {
      delete parentComponent.components![change.key];
    }

    if (this.selectedComponent && change.key === this.selectedComponent.id) {
      this.selectedComponent = null;
    }
    return {
      key: '',
      component: parentComponent,
    };
  }

  public onListDataChanged(change: EmulatorNode): void {
    const newChange: EmulatorNode = {
      key: this.nodeName,
      component: change.component,
    };
    this.onDataChanged(newChange);
  }

  public onAddItemHandler(): void {
    this.isAddNewFormOpen = true;
  }

  public onNewComponent(component: EmulatorComponent): void {
    this.onDataChanged({
      component,
      key: component.id!,
    });
    this.isAddNewFormOpen = false;
  }

  public onCloseHandler(): void {
    this.isAddNewFormOpen = false;
  }
}
