
















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PaginationFooter extends Vue {

  @Prop({
    required: true,
    default: 1,
  })
  public actualPage!: number;

  @Prop({
    required: true,
    default: 20,
  })
  public selectItemPerPage!: number;

  @Prop({
    required: true,
  })
  public totalPages!: number;

  @Prop({
    required: true,
  })
  public itemsPerPageOptions!: string[];

  @Emit('updateItemsPerPage')
  private updateItemsPerPage(newValue: string) {
    return newValue;
  }

  @Emit('nextPage')
  private nextPage() {
    return this.actualPage + 1;
  }

  @Emit('previousPage')
  private previousPage() {
    return this.actualPage + 1;
  }
}
