










































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CourseInfo } from '@/models/CoursesModels';
import { UserProfile } from '@/models/UserModels';
import CourseClientCard from '@/components/coursesPlanner/usersProfile/CourseClientCard.vue';
import { namespace } from 'vuex-class';

const coursesTemplateModule = namespace('CoursesTemplateModule');
const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    CourseClientCard,
  },
})
export default class CoursesViewer extends Vue {
  @coursesTemplateModule.Getter
  public currentCourseInfo!: CourseInfo | null;

  @coursesTemplateModule.Mutation
  public setCurrentCourseInfo!: (course: CourseInfo | null) => Promise<void>;

  @emulatorModule.Action
  public fetchEmulatorsByIds!: (emulatorsIds: string[]) => Promise<void>;

  @Prop({
    required: true,
  })
  public currentUserProfile!: UserProfile;

  @Prop({
    required: true,
  })
  public coursesInfos!: CourseInfo[];

  private isLoadingCourse: boolean = false;
  private currentCourseIndex: number = 1;

  private created(): void {
    this.setCurrentCourse();
  }

  private beforeDestroy(): void {
    this.setCurrentCourseInfo(null);
  }

  @Watch('coursesInfos')
  private currentCoursesChanged(): void {
    this.changeCurrentCourse();
  }

  @Watch('currentCourseIndex')
  private changeCurrentCourse(): void {
    this.setCurrentCourse();
  }

  private async setCurrentCourse(): Promise<void> {
    await this.setCurrentCourseInfo(null);
    this.isLoadingCourse = true;
    await this.setCurrentCourseInfo(this.coursesInfos[this.currentCourseIndex - 1]);
    this.isLoadingCourse = false;
  }
}
