import { Component, Vue } from 'vue-property-decorator';
import { getHours, getMinutes } from '@/utils/common';

@Component({
  props: {
    label: {
      required: true,
    },
    data: {
      required: true,
    },
  },
})
export default class CMCDataControl extends Vue {
  private hours(time: Date): string {
    return getHours(time);
  }

  private minutes(time: Date): string {
    return getMinutes(time);
  }
}
