import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { CourseInfo, CourseTemplate, CourseInfoPatch } from '@/models/CoursesModels';
import axios from 'axios';
import { Course, EmulatorLessons, Lesson } from '@/models/CoursesModels';
import { Emulator } from '@/models/EmulatorModels';
import { stripSpecialCharacters } from '@/utils/labels_utils';

@Module({ namespaced: true, name: 'coursesTemplateModule' })
class CoursesTemplateModule extends VuexModule {
  private $isLoadingTemplates: boolean = false;
  private $templates: CourseTemplate[] = [];
  private $autoCompleteTemplates: CourseTemplate[] = [];
  private $currentTemplate: CourseTemplate | null = null;
  private $emulatorLessons: EmulatorLessons[] = [];
  private $coursesInfos: CourseInfo[] = [];
  private $currentCourseInfo: CourseInfo | null = null;
  private $isCurrentLesson: boolean = true;
  private $coursesClient: CourseInfo[] = [];
  private $courseFinishByClient: CourseInfo[] = [];

  // Page Number For Search
  private $itemsPerPageCourseInfo: number = 20;
  private $itemsPerPageCourseTemplate: number = 20;

  public get isLoadingTemplates(): boolean {
    return this.$isLoadingTemplates;
  }

  public get templates(): CourseTemplate[] {
    return this.$templates;
  }

  public get autoCompleteTemplates(): CourseTemplate[] {
    return this.$autoCompleteTemplates;
  }

  public get currentTemplate(): CourseTemplate | null {
    return this.$currentTemplate;
  }

  public get emulatorLessons(): EmulatorLessons[] {
    return this.$emulatorLessons;
  }

  public get coursesInfos(): CourseInfo[] {
    return this.$coursesInfos;
  }

  public get coursesClient(): CourseInfo[] {
    return this.$coursesClient;
  }

  public get courseFinishByClient(): CourseInfo[] {
    return this.$courseFinishByClient;
  }

  public get currentCourseInfo(): CourseInfo | null {
    return this.$currentCourseInfo;
  }

  public get isCurrentLesson(): boolean {
    return this.$isCurrentLesson;
  }

  public get itemsPerPageCourseInfo(): number {
    return this.$itemsPerPageCourseInfo;
  }

  public get itemsPerPageCourseTemplate(): number {
    return this.$itemsPerPageCourseTemplate;
  }

  @Mutation
  public setLoadingTemplates(isLoading: boolean) {
    this.$isLoadingTemplates = isLoading;
  }

  @Mutation
  public setTemplates(templates: CourseTemplate[]) {
    this.$templates = templates;
  }

  @Mutation
  public setResearchTemplates(templates: CourseTemplate[]) {
    this.$autoCompleteTemplates = templates;
  }

  @Mutation
  public setCurrentTemplate(template: CourseTemplate | null) {
    this.$currentTemplate = template;
  }

  @Mutation
  public setLessons(emulatorLesson: EmulatorLessons[]) {
    this.$emulatorLessons = emulatorLesson;
  }

  @Mutation
  public setCoursesInfos(coursesInfos: CourseInfo[]) {
    this.$coursesInfos = coursesInfos;
  }

  @Mutation
  public setCourseFinishByClient(coursesInfos: CourseInfo[]) {
    this.$courseFinishByClient = coursesInfos.filter((coursesInfo) => {
      const courseEndDate = new Date(coursesInfo.endDate);
      return courseEndDate < new Date();
    });
  }

  @Mutation
  public setCoursesClient(coursesClient: CourseInfo[]) {
    this.$coursesClient = coursesClient;
  }

  @Mutation
  public setCurrentCourseInfo(courseInfo: CourseInfo | null) {
    this.$currentCourseInfo = courseInfo;
  }

  @Mutation
  public setIsCurrentLesson(isCurrent: boolean) {
    this.$isCurrentLesson = isCurrent;
  }

  @Mutation
  public setItemsPerPageCourseInfo(itemsPerPage: number) {
    this.$itemsPerPageCourseInfo = itemsPerPage;
  }

  @Mutation
  public setItemsPerPageCourseTemplate(itemsPerPage: number) {
    this.$itemsPerPageCourseTemplate = itemsPerPage;
  }

  // Server Functions

  @Action
  public async createTemplate(template: CourseTemplate) {
    await axios.post('/course/courseTemplate/', template);
  }

  @Action
  public async editTemplate(template: CourseTemplate) {
    try {
      await axios.patch('/course/courseTemplate/' + template.id, template);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;
    }
  }

  @Action
  public async deleteTemplate(templateId: string) {
    try {
      await axios.patch('/course/courseTemplate/archive/' + templateId);
    } catch (e) {
      // eslint-disable-next-line
      console.error('Cannot Patch', e);
    }
  }

  @Action({ commit: 'setResearchTemplates' })
  public async researchCourseTemplates(searchTerm: string = '') {
    await this.context.commit('setLoadingTemplates', true);
    let templates: CourseTemplate[] = [];
    try {
      if (searchTerm && stripSpecialCharacters(searchTerm) !== '') {
        const { data } = await axios.get('/course/courseTemplate/quicksearch/' + stripSpecialCharacters(searchTerm));
        templates = data.content;
      } else {
        const { data } = await axios.get('/course/courseTemplate');
        templates = data.content;
      }
      await this.context.commit('setLoadingTemplates', false);
      return templates;
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;
      await this.context.commit('setLoadingTemplates', false);
      return [];
    }

  }

  @Action({ commit: 'setCurrentTemplate' })
  public async fetchCourseTemplate(id: string) {
    await this.context.commit('setLoadingTemplates', true);
    const { data } = await axios.get('/course/courseTemplate?id=' + id);
    const template: CourseTemplate = data.content[0];
    await this.context.commit('setLoadingTemplates', false);

    return template;
  }

  @Action
  public async fetchLessonsByEmulator(emulator: Emulator): Promise<Lesson[]> {
    try {
      const { data } = await axios.get('/lesson?emulatorId=' + emulator.id + '&sort=order,asc');
      return data.content as Lesson[];
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;
      return [];
    }
  }

  @Action
  public async fetchLessonById(lessonId: string): Promise<Lesson | null> {
    try {
      const { data } = await axios.get('/lesson?id=' + lessonId + '&sort=order,asc');
      return data.content[0];
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;
      return null;
    }
  }

  @Action
  public async addLesson(lesson: Lesson) {
    try {
      await axios.post('/lesson/', lesson);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;
    }
  }

  @Action
  public async updateLesson(lesson: Lesson) {
    try {
      await axios.patch('/lesson/' + lesson.id, lesson);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;
    }
  }

  @Action
  public async archiveLesson(lesson: Lesson) {
    try {
      await axios.patch('/lesson/archive/' + lesson.id);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;
    }
  }

  @Action
  public async addCourse(course: Course) {
    try {
      await axios.post('/course/', course);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;
    }
  }

  @Action
  public async updateCourse(course: Course) {
    try {
      await axios.patch('/course/' + course.id, course);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;
    }
  }

  @Action
  public async archiveCourse(courseId: string) {
    try {
      await axios.patch('/course/archive/' + courseId);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;
    }
  }

  @Action({ commit: 'setCoursesInfos' })
  public async fetchCoursesInfos() {
    await this.context.commit('setLoadingTemplates', true);
    try {
      const { data } = await axios.get('/course/courseInfo');
      await this.context.commit('setLoadingTemplates', false);
      return data.content;
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;
      return [];
    }
  }

  @Action({ commit: 'setCurrentCourseInfo' })
  public async fetchCoursesInfosById(idCourse: string) {
    await this.context.commit('setLoadingTemplates', true);
    try {
      const { data } = await axios.get('/course/courseInfo/' + idCourse);
      await this.context.commit('setLoadingTemplates', false);
      return data;
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;

      await this.context.commit('setLoadingTemplates', false);
      return [];
    }
  }

  @Action
  public async updateCourseInfos(courseInfo: { courseId: string, updateData: CourseInfoPatch }) {
    try {
      await axios.patch('/course/' + courseInfo.courseId, courseInfo.updateData);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;
    }
  }

  @Action
  public async fetchLessonsIdsByEmulatorsIds(emulatorsIds: string[]): Promise<string[]> {
    let lessonsIds: string[] = [];
    if (emulatorsIds && emulatorsIds.length > 0) {
      try {
        for (const emulatorId of emulatorsIds) {
          const { data } = await axios.get('/lesson?emulatorId=' + emulatorId);
          lessonsIds = lessonsIds.concat(data.content.map((lesson) => lesson.id));
        }
      } catch (e) {
        lessonsIds = [];
      }
    }
    return lessonsIds;
  }

  @Action({ commit: 'setCoursesClient' })
  public async fetchCoursesByClient(userID: string) {
    try {
      const { data } = await axios.get(`/course/courseInfo?clientIds=${userID}`);
      return data.content;
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);;
    }
  }

}

export default CoursesTemplateModule;
