






























































import Vue from 'vue';
import Component from 'vue-class-component';
import { ChangePasswordForm } from '@/models/AuthenticationModels';
import { namespace } from 'vuex-class';
import RuleUtility from '@/utils/rules';
import { UISnackBar } from '@/models/UIModels';

const auth = namespace('AuthModule');
const globalUI = namespace('GlobalUIModule');

@Component
export default class ChangePassword extends Vue {

  @auth.Action
  public changePassword!: (changePasswordData: ChangePasswordForm) => Promise<void>;

  @globalUI.Action
  public showSnackBar!: (snackBar: UISnackBar) => void;

  private ruleUtility = RuleUtility;

  private codeSent: boolean = false;
  private valid: boolean = false;
  private sendCodeValid: boolean = false;
  private confirmPassword = '';

  private form: ChangePasswordForm = {
    oldPassword: '',
    newPassword: '',
  };

  private validate(): void {
    const formToSend: ChangePasswordForm = {
      oldPassword: this.form.oldPassword,
      newPassword: this.form.newPassword,
    };

    this.changePassword(formToSend).then(() => {
      this.$router.push('/dashboard');
      this.showSnackBar(
        {
          color: 'success',
          text: 'changePassword.success',
        },
      );
    }).catch(() => {
      this.showSnackBar(
        {
          color: 'error',
          text: 'changePassword.error',
        },
      );
    });
  }
}
