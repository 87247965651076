










































import { Vue, Component, Prop } from 'vue-property-decorator';
import { EmulatorComponentsMap, EmulatorStyle } from '@/models/EmulatorModels';
import { EmulatorComponentType } from '@/enums/emulator';
import { itemHasStyles } from '@/utils/common';
import CMCIndicatorStatus from '@/components/cmc/controls/cmcIndicatorStatus/CMCIndicatorStatus.vue';

@Component({
  components: {
    CMCIndicatorStatus,
  },
})
export default class CMCTcasSystem extends Vue {
  @Prop({ required: true })
  private diagramData!: EmulatorComponentsMap;

  public hasKey(data: EmulatorComponentsMap | EmulatorStyle | null, key: string): boolean {
    if (typeof data === 'undefined' || data === null) {
      return false;
    }
    return Object.keys(data).includes(key);
  }

  public isValueEmpty(data: EmulatorComponentsMap): boolean {
    return typeof data.value !== 'undefined';
  }

  public isIndicator(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.INDICATOR ||
      type === EmulatorComponentType.ROUND_INDICATOR ||
      type === EmulatorComponentType.RECTANGLE_INDICATOR);
  }

  public getStyles(styles: EmulatorStyle): any {
    return itemHasStyles(styles);
  }

  public getClassesString(styles: EmulatorStyle): string {
    if (this.hasKey(styles, 'class')) {
      return ' ' + styles.class!;
    }
    return '';
  }
}
