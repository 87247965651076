export enum DialogContentType {
  NONE = 'none',
  FIRST_LOGIN = 'firstLogin',
  PROFILE = 'profile',
  SELECTED_PROFILE = 'selectedProfile',
  INACTIVITY = 'inactivity',
  LOGGED_OUT = 'loggedOut',
}

export enum SearchType {
  USER = 'user',
  EMULATOR = 'emulator',
  COURSETEMPLATE = 'course/courseTemplate',
  COURSESPLANNER = 'course/courseInfo',
}

export enum LessonState {
  IS_CURRENT = 'isCurrent',
  IS_DONE = 'isDone',
  IS_LOCK = 'isLock',
}

export enum ImageType {
  USER = 'user',
  EMULATOR = 'emulator'
}
