
























































































































import UserRoleType from '../../enums/user';
import { CreateUserParams, User, UserRoleLine } from '@/models/UserModels';
import { Component, Emit, Vue } from 'vue-property-decorator';
import PasswordUtility from '@/utils/password';
import { namespace } from 'vuex-class';
import { UISnackBar } from '@/models/UIModels';
import RoleSelectionList from '@/components/user/roleSelection/RoleSelectionList.vue';

const userModule = namespace('UserModule');
const globalUI = namespace('GlobalUIModule');

@Component({
  components: {
    RoleSelectionList,
  },
})
export default class UserCreateUserDialog extends Vue {
  @userModule.Action
  public createUsers!: (params: CreateUserParams[]) => Promise<void>;

  @userModule.Action
  public searchUserByEmail!: (params: string) => Promise<User | null>;

  @globalUI.Action
  public showSnackBar!: (snackBar: UISnackBar) => void;

  public userRoleLines: UserRoleLine[] = [];
  private createUsersText = '';
  private createUsersList: CreateUserParams[] = [];

  private selectedRoles: string[] = [];
  private selectedAircraft: string[] = [];

  private isCreateUsersFormValid = true;
  private confirmCircularProgressIndicator = false;

  private async validateCreateUsers() {
    if (this.createUsersText) {
      this.createUsersText.split('\n').forEach((line): void => {
        let newUserParams: CreateUserParams;
        const splitLine = line.split(',');
        let email;
        let studentId;
        if (splitLine.length > 0) {
          email = splitLine[0].trim();
          if (splitLine.length > 1) {
            studentId = splitLine[1].trim();
          }
          this.searchUserByEmail(email).then((user: User | null) => {
            if (user) {
              newUserParams = {
                username: email,
                studentId,
                temporaryPassword: '*********',
                roles: user.roles,
                isAlreadyCreated: true,
              };
            } else {
              newUserParams = {
                username: email,
                studentId,
                temporaryPassword: PasswordUtility.generateCreateUsersTextAreaString(),
                roles: UserRoleType.convertUserRoleLinesToRoles(this.userRoleLines),
                isAlreadyCreated: false,
              };
            }
            this.createUsersList.push(newUserParams);
          });
        }
      });
    }
  }

  private isRoleSelectionValid(): boolean {
    if (this.selectedRoles.length === 1 &&
      (this.selectedRoles[0] === UserRoleType.ADMIN || this.selectedRoles[0] === UserRoleType.GUEST)) {
      return true;
    } else {
      if (!(this.selectedRoles.length > 0 && this.selectedAircraft.length === 0)) {
        return true;
      }
    }

    return false;
  }

  private confirmCreateUsersDialog() {
    this.confirmCircularProgressIndicator = true;

    if (this.createUsersList.length > 0) {
      this.createUsers(
        this.createUsersList)
        .then(() => {
          this.onCloseHandler();
        })
        .catch(() => {
          this.showSnackBar(
            {
              color: 'error',
              text: 'user.manager.createUsersError',
            },
          );
          this.onCloseHandler();
        });
    }

    this.onCloseHandler();
  }

  @Emit()
  private onCloseHandler() {
    this.createUserDialogResetFields();
    return;
  }

  private createUserDialogResetFields() {
    this.createUsersText = '';
    this.createUsersList = [];
    this.selectedRoles = [];
    this.selectedAircraft = [];
  }

  // Validation
  private rulesCreateUsersTextArea() {
    return [
      (v: string) => !!v || 'At least one line is required',
      (v: string) => this.validateCreateUsersTextAreaLines(v) || 'Each line must be valid',
      (v: string) => this.validateCreateUsersTextAreaDuplicate(v) || 'Cannot use the same email twice',
    ];
  }

  private validateCreateUsersTextAreaLines(v: string): boolean {
    let result = true;

    v.split('\n').forEach((line) => {
      result = result && /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}(\s*,\s*\w+)?$/.test(line.trim());
    });

    return result;
  }

  private validateCreateUsersTextAreaDuplicate(v: string): boolean {
    const emails: string[] = [];
    const emailSet = new Set();

    v.split('\n').forEach((line) => {
      const splittedLine = line.split(',');
      if (splittedLine.length > 0) {
        const email = splittedLine[0].trim();
        emails.push(email);
        emailSet.add(email);
      }
    });

    return emails.length === emailSet.size;
  }
}
