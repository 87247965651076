






























































































































import _ from 'lodash';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EmulatorComponent, EmulatorComponentsMap, EmulatorTrigger, EmulatorStyle } from '@/models/EmulatorModels';
import { EmulatorComponentStatus, EmulatorTriggerType } from '@/enums/emulator';
import { getObjectPath, itemHasStatus } from '@/utils/common';
import ViewConditionsMixin from '@/components/cmc/mixins/ViewConditionsMixin';
import ClickNotSupportedMixin from '@/components/cmc/mixins/ClickNotSupportedMixin';
import { itemHasStyles, objHasKey } from '@/utils/common';
import { mixins } from 'vue-class-component';

const emulatorModule = namespace('EmulatorModule');

@Component({
  name: 'CMCExpandableMenuItem',
  components: {
    CMCExpandableMenu: () => import('./CMCExpandableMenu.vue'),
  },
  mixins: [
    ViewConditionsMixin,
    ClickNotSupportedMixin,
  ],
})
export default class CMCExpandableMenuItem extends mixins(
  ViewConditionsMixin,
  ClickNotSupportedMixin,
) {

  @emulatorModule.Action
  public dispatchActions!: (triggers: EmulatorTrigger[]) => void;

  @emulatorModule.Getter
  public currentEmulatorPane!: EmulatorComponent;

  @emulatorModule.Getter
  public expandedMenuItems!: any[];

  @Prop({ required: true })
  private component!: EmulatorComponent;

  @Prop({ default: 0 })
  private parentLevel!: number;

  @Prop({ default: false })
  private isOpened!: boolean;

  @Prop({
    default: () => [],
  },
  )
  private navigationPath!: string[];

  public get menuItems(): EmulatorComponentsMap {
    let items: EmulatorComponentsMap = {};

    if (this.component.components && this.component.components.menuItems
      && this.component.components.menuItems.components) {
      items = this.component.components.menuItems.components;
    }
    return items;
  }

  public get className(): string {
    let classes: string = this.parentLevelClass;
    if (this.isFaulted) {
      classes = classes + ' faulted';
    }
    return classes;
  }

  public get parentLevelClass(): string {
    if (this.parentLevel === 0) {
      return 'mx-0';
    }
    return `mx-${this.parentLevel + 3}`;
  }

  public get isExpandable(): boolean {
    return !this.hasClickNotSupported(this.component) && this.component.components!.menuItems !== undefined;
  }

  public isDisabled(): boolean {
    return itemHasStatus(this.component, EmulatorComponentStatus.DISABLED);
  }

  public get isFaulted(): boolean {
    return itemHasStatus(this.component, EmulatorComponentStatus.FAULTED);
  }

  public triggerHandler(input: EmulatorComponent): void {
    this.onClickDisplayReportStatus(input.components);
    if (this.isExpandable) {

      const expandedMenuItemsContext: any = { ...this.expandedMenuItems };
      const objectPath: string = getObjectPath(this.navigationPath);
      let updatedTree: [] = [];
      let mergedTree: [] = [];

      if (_.get(expandedMenuItemsContext, objectPath)) {
        mergedTree = expandedMenuItemsContext;
        _.unset(mergedTree, objectPath);
      } else {
        updatedTree = _.set(updatedTree, objectPath, {});
        mergedTree = _.merge(expandedMenuItemsContext, updatedTree);
      }

      const newTrigger: EmulatorTrigger = {
        type: EmulatorTriggerType.EXPAND_PANEL,
        payload: {
          data: mergedTree,
        },
      };

      if (!input.triggers) {
        input.triggers = [];
        input.triggers.push(newTrigger);
      } else {
        const index: number = input.triggers.findIndex((item: EmulatorTrigger) => {
          return item.type === EmulatorTriggerType.EXPAND_PANEL;
        });
        if (index !== -1) {
          input.triggers[index] = newTrigger;
        } else {
          input.triggers.push(newTrigger);
        }
      }
      this.emitExpandableMenu(this.getOverriddenTriggers(input));
    } else {
      if (input?.triggers && input.triggers[0]?.type === EmulatorTriggerType.NAVIGATE) {
        this.$nextTick(() => {
          const refs: any = document.getElementsByClassName('v-list');
          refs[1].scrollTop = 0;
        });
      }
    }

    this.dispatchActions(this.getOverriddenTriggers(input, true));
  }

  public onClickDisplayReportStatus(component): void {
    if (objHasKey(component, 'reportStatusBody') && objHasKey(component, 'reportStatusHeader')) {
      this.emitReportStatus(component);
    } else {
      component.resetReportStatus = true;
      this.emitReportStatus(component);
    }

  }

  public canDisplay(component: EmulatorComponent): boolean {
    if (component.viewConditions) {
      return this.evaluateViewConditions(component.viewConditions);
    }
    return true;
  }

  public emitExpandableMenu(e) {
    this.$nextTick(() => this.$emit('expandableMenu', e));
  }

  public getStyles(styles: EmulatorStyle): any {
    return itemHasStyles(styles);
  }

  public emitReportStatus(e) {
    this.$emit('reportStatus', e);
  }

  // private headerButtonClickHandler(input: EmulatorActionItem): void {
  //   // Does nothing for now
  // }
}
