








































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ConfirmationModal extends Vue {

  public isWaiting: boolean = false;

  @Prop({
    default: '',
  })
  private itemName!: string;

  @Prop({
    default: false,
  })
  private isArchive!: boolean;

  @Emit('onDeleteConfirmationHandler')
  public onDeleteConfirmation(isDeleted: boolean) {
    this.isWaiting = true;
    return isDeleted;
  }

  private get titleKey(): string {
    if (this.isArchive) {
      return 'common.confirmDeletePopup.archiveTitle';
    } else {
      return 'common.confirmDeletePopup.deleteTitle';
    }
  }

  private get descriptionKey(): string {
    if (this.isArchive) {
      return 'common.confirmDeletePopup.archiveDescription';
    } else {
      return 'common.confirmDeletePopup.deleteDescription';
    }
  }
}
