import { render, staticRenderFns } from "./CMCPaginator.vue?vue&type=template&id=c1ccb554&scoped=true&"
import script from "./CMCPaginator.vue?vue&type=script&lang=ts&"
export * from "./CMCPaginator.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1ccb554",
  null
  
)

export default component.exports