




























import { Vue, Component, Prop } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorStyle } from '@/models/EmulatorModels';
import { EmulatorComponentType } from '@/enums/emulator';
import CMCOrderedListMenuItem from "./CMCOrderedListMenuItem.vue"
import { itemHasStyles } from '@/utils/common';

@Component({
  components: {
    CMCOrderedListMenuItem,
  },
})
export default class CMCOrderedListMenu extends Vue {
  @Prop()
  private component!: EmulatorComponent;

  public isTitle(component: EmulatorComponent) {
    return component.type === EmulatorComponentType.LIST_TITLE;
  }

  public isListItem(component: EmulatorComponent) {
    return component.type === EmulatorComponentType.LIST_ITEM;
  }

  public getStyles(styles: EmulatorStyle): any {
    return itemHasStyles(styles);
  }
}
