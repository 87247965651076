























































import { Component } from 'vue-property-decorator';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import { UISnackBar } from '@/models/UIModels';
import { namespace } from 'vuex-class';
import { mixins } from 'vue-class-component';

const globalUI = namespace('GlobalUIModule');

@Component({})
export default class ContactUs extends mixins(ResponsiveMixin) {

  @globalUI.Action
  public showSnackBar!: (snackBar: UISnackBar) => void;

  private copyText(text: string) {
    navigator.clipboard.writeText(text);
    this.showSnackBar({
      color: 'primary',
      text: this.$t('contactUs.clipboardMessage', { copiedItem: text }) as string,
    });
  }
}
