















































































































import { Component, Prop } from 'vue-property-decorator';
import { EmulatorComponent } from '@/models/EmulatorModels';
import * as eventManager from '@/components/shared/SideMenuEventManager';
import { EmulatorTriggerType } from '@/enums/emulator';
import KeyboardNavigationMixin from '@/components/cmc/mixins/KeyboardNavigationMixin';
import { mixins } from 'vue-class-component';

@Component({
  name: 'CMCScrollableMenu',
})
export default class CMCScrollableList extends mixins(KeyboardNavigationMixin) {
  @Prop({
    required: true,
  })
  public heightClass!: string;
  @Prop({
    required: false,
    default: 0,
  })
  public selectedItem!: number;

  private get classNameBySelectItem() {
    if (this.selectedItem === 1) {
      return `${this.heightClass} wrapper-alone-selected`;
    }
    return `${this.heightClass}`;
  }

  public mounted() {
    this.subscribeClickHandler();
  }

  protected subscribeClickHandler() {
    eventManager.eventBus.$on(
      'sideMenuClickDispatch',
      (item: EmulatorComponent) => {
        if (Array.isArray(item.triggers) && item.triggers.length > 0) {
          if (item.triggers[0].type === EmulatorTriggerType.SCROLL_ITEM) {
            this.setClassOnItem(item.triggers[0].payload!.location);
          } else {
            if (item?.triggers[0]?.payload?.location) {
              this.setScrollBar(item.triggers[0].payload!.location);
            }
          }
        }
      },
    );
  }
}
