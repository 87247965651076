import { Vue, Component } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorTrigger } from '@/models/EmulatorModels';
import { EmulatorComponentStatus, EmulatorTriggerType } from '@/enums/emulator';
import { itemHasStatus } from '@/utils/common';

@Component
class ClickNotSupportedMixin extends Vue {
  public hasClickNotSupported(component: EmulatorComponent): boolean {
    return itemHasStatus(component, EmulatorComponentStatus.NOT_SUPPORTED);
  }

  public hasToBeForced(component: EmulatorComponent, forced: boolean): boolean {
    if (!forced) {
      return false;
    } else {
      return !component.triggers || component.triggers!.length === 0;
    }

  }

  public getOverriddenTriggers(
    component: EmulatorComponent,
    forceClickNotSupported: boolean = false,
  ): EmulatorTrigger[] {
    let triggers: EmulatorTrigger[] = [];
    if (this.hasClickNotSupported(component) || this.hasToBeForced(component, forceClickNotSupported)) {
      const trigger: EmulatorTrigger = {
        type: EmulatorTriggerType.CLICK_NOT_SUPPORTED,
      };
      triggers.push(trigger);
    } else {
      triggers = component.triggers ? component.triggers : [];
    }
    return triggers;
  }
}

export default ClickNotSupportedMixin;
