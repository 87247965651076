



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponent } from '@/models/EmulatorModels';
import CMCLayoutSideMenu from '@/components/cmc/sidemenu/CMCLayoutSideMenu.vue';
import CMCExpandableMenu from '@/components/cmc/controls/expandableMenu/CMCExpandableMenu.vue';
import CMCScrollableMenu from '@/components/cmc/controls/scrollableMenu/CMCScrollableMenu.vue';

@Component({
  components: {
    CMCLayoutSideMenu,
    CMCExpandableMenu,
    CMCScrollableMenu,
  },
})
export default class CMCFDEPane extends Vue {
  get heightClassName() {
    this.height = 'medium-list-scroll overflow-y-auto pa-0';
    return this.height;
  }

  public height;
  @Prop({ required: true })
  private currentEmulatorPane!: EmulatorComponent;
}
