









































































import { Component, Prop, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import draggable from 'vuedraggable';
import { Lesson } from '@/models/CoursesModels';
import ResizableAddItemCard from '@/components/shared/ResizableAddItemCard.vue';
import LessonForm from '@/components/coursesTemplates/lessons/LessonForm.vue';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';
import LessonsListItem from '@/components/coursesTemplates/lessons/LessonsListItem.vue';
import { Emulator } from '@/models/EmulatorModels';
import { Session, SessionLesson } from '@/models/EvaluationModels';
import { LessonState } from '@/enums/globalUI';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import UserMixin from '@/mixins/UserMixin';
import { mixins } from 'vue-class-component';

const coursesTemplateModule = namespace('CoursesTemplateModule');
const evaluationModule = namespace('EvaluationModule');

@Component({
  components: {
    ResizableAddItemCard,
    LessonsListItem,
    LessonForm,
    ConfirmationModal,
    draggable,
  },
})
export default class LessonsList extends mixins(ResponsiveMixin, UserMixin) {

  public isEditionPopUpOpen: boolean = false;
  public isDeleteConfirmationPopUpOpen: boolean = false;
  public editedLesson: Lesson | null = null;
  public isDragging: boolean = false;
  public isLoading: boolean = false;

  @Prop({
    required: false,
  })
  public emulator!: Emulator;

  @Prop({
    default: false,
  })
  public canEdit!: boolean;

  @Prop({
    default: false,
  })
  public isUserProfileView!: boolean;

  @Prop({
    default: '',
  })
  public courseId!: string;

  public lessonsList: Lesson[] = [];

  @evaluationModule.Getter
  public sessions!: Session[];

  @evaluationModule.Mutation
  public setLessonState!: (lessonState: string[]) => void;

  @evaluationModule.Mutation
  public setSessions!: (sessions: Session[]) => void;

  @coursesTemplateModule.Action
  public fetchLessonsByEmulator!: (emulator: Emulator) => Promise<Lesson[]>;

  @coursesTemplateModule.Action
  public addLesson!: (lesson: Lesson) => Promise<void>;

  @coursesTemplateModule.Action
  public updateLesson!: (lesson: Lesson) => Promise<void>;

  @coursesTemplateModule.Action
  public archiveLesson!: (lesson: Lesson) => Promise<void>;

  @evaluationModule.Action
  public getLastSessionForAUserAndLesson!: (sessionFor: SessionLesson) => Promise<void>;

  public created(): void {
    this.isLoading = true;
    this.fetchData();
  }

  public async fetchData(): Promise<void> {
    const lessons: Lesson[] = await this.fetchLessonsByEmulator(this.emulator);
    this.setListLesson(lessons);
  }

  public addLessonHandler(): void {
    this.isEditionPopUpOpen = true;
  }

  public async saveLesson(lesson: Lesson): Promise<void> {
    if (this.editedLesson) {
      this.lessonsList.forEach((item: Lesson, index: number) => {
        if (item.id === lesson.id) {
          this.lessonsList[index] = lesson;
        }
      });
      await this.updateLesson(lesson);
    } else {
      lesson.emulatorId = this.emulator.id;
      lesson.order = this.lessonsList.length;
      this.lessonsList.push(lesson);
      await this.addLesson(lesson);
      await this.fetchData();
    }

    this.closePopup();
  }

  public closePopup(): void {
    this.isEditionPopUpOpen = false;
    this.editedLesson = null;
  }

  public editLesson(lesson: Lesson): void {
    this.editedLesson = lesson;
    this.isEditionPopUpOpen = true;
  }

  public openDeletePopup(lesson: Lesson): void {
    this.editedLesson = lesson;
    this.isDeleteConfirmationPopUpOpen = true;
  }

  public deleteConfirmationPopUp(isDeleted: boolean) {
    if (isDeleted) {
      this.archiveLesson(this.editedLesson!);
      this.lessonsList.forEach((item: Lesson, index: number) => {
        if (this.editedLesson && item.id === this.editedLesson.id) {
          this.lessonsList.splice(index, 1);
        }
      });
      this.reorderItems();
      this.updateLessons();
    }
    this.editedLesson = null;
    this.isDeleteConfirmationPopUpOpen = false;
  }

  // Draggable Feature

  public startMove(): void {
    this.isDragging = true;
  }

  public endMove(): void {
    this.isDragging = false;
    this.reorderItems();
    this.updateLessons();
  }

  public updateLessons() {
    this.lessonsList.forEach((item: Lesson) => {
      this.updateLesson(item);
    });
  }

  public reorderItems() {
    this.lessonsList = this.lessonsList.map((item: Lesson, index: number) => {
      item.order = index;
      return item;
    });
  }

  @Emit('playEmulatorOnClick')
  public playEmulatorOnClick(lessonEmulator: any): void {
    return lessonEmulator;
  }

  private setListLesson(lessonList: Lesson[]): void {
    if (this.isUserProfileView) {
      this.setLessonItemsActions(lessonList).then((lessons) => {
        this.lessonsList = lessons;
        this.isLoading = false;
      });
    } else {
      this.lessonsList = lessonList;
      this.isLoading = false;
    }
  }

  private async setLessonItemsActions(lessonList: Lesson[]): Promise<Lesson[]> {
    const lessonState: string[] = [];
    let isCurrent: boolean = true;
    for (const lesson of lessonList) {
      const key = lessonList.indexOf(lesson);
      await this.getLastSessionForAUserAndLesson({
        userId: this.userId,
        courseId: this.courseId,
        lesson,
      });
      lessonState[key] = LessonState.IS_LOCK;
      if (this.sessions.length === 0 && isCurrent) {
        lessonState[key] = LessonState.IS_CURRENT;
        isCurrent = false;
      } else {
        if (this.sessions.length > 0) {
          if (this.sessions.filter((session) => session.lessonCompletedOn).length > 0) {
            lessonState[key] = LessonState.IS_DONE;
          } else if (isCurrent) {
            lessonState[key] = LessonState.IS_CURRENT;
            isCurrent = false;
          }
        }
      }
    }
    this.setLessonState(lessonState);
    return lessonList;
  }

  // Getting User ID from params
  private get userId(): string {
    if (this.isClient) {
      return this.currentUser!.id;
    }
    return this.$route.params.userId;
  }
}
