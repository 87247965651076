





























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Emulator, EmulatorComponent, EmulatorNode } from '@/models/EmulatorModels';
import EmulatorComponentList from '@/components/editor/componentsList/EmulatorComponentList.vue';
import { UIBreadcrumb } from '@/models/UIModels';
import BreadCrumbs from '@/components/shared/BreadCrumbs.vue';

const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    EmulatorComponentList,
    BreadCrumbs,
  },
})
export default class EmulatorComponentsLanding extends Vue {

  @emulatorModule.Getter
  public currentEmulator!: Emulator;

  @emulatorModule.Getter
  public currentEmulatorPane!: EmulatorComponent;

  @emulatorModule.Mutation
  public setPreviewOpen!: (isOpen: boolean) => void;

  @emulatorModule.Action
  public fetchEmulator!: (emulatorId: string) => Promise<void>;

  @emulatorModule.Action
  public chooseCurrentEmulatorPane!: (emulatorPane: EmulatorComponent) => void;

  @emulatorModule.Action
  public patchEmulator!: (emulator: Emulator) => Promise<void>;

  public async created() {
    if (!this.currentEmulator) {
      await this.fetchEmulator(this.idEmulator);
      await this.chooseCurrentEmulatorPane(this.currentEmulator!.panes![this.idPane]);
    }
  }

  get idEmulator(): string {
    return this.$route.params.id;
  }

  get idPane(): string {
    return this.$route.params.idPane;
  }

  public async onDataChanged(change: EmulatorNode) {
    const saveEmulator = this.currentEmulator;
    saveEmulator[this.idPane] = change.component;
    await this.patchEmulator(saveEmulator);
    await this.chooseCurrentEmulatorPane(this.currentEmulator!.panes![this.idPane]);
  }

  public get breadCrumbsItems(): UIBreadcrumb[] {
    return [
      {
        text: this.$t('emulator.emulators') as string,
        to: '/editor',
        exact: true,
      },
      {
        text: this.currentEmulator ? this.currentEmulator.title : '',
        to: '/editor/' + this.idEmulator,
        exact: true,
      },
    ];
  }
}
