import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';

import {
  Emulator,
  EmulatorComponent,
  EmulatorTrigger,
  EmulatorTriggerDispatch,
  EmulatorVar,
} from '@/models/EmulatorModels';
import { EmulatorTriggerType } from '@/enums/emulator';
import { fetchAllMasterEmulators, fetchMasterEmulatorByID } from '@/utils/common';
import AircraftType from '@/enums/user/aircraft';
import { NavigationData } from '@/models/NavigationModels';
import { User } from '@/models/UserModels';

@Module({
  namespaced: true,
  name: 'emulatorModule',
})
export default class EmulatorModule extends VuexModule {
  // Related to CRUD
  private $isLoadingEmulators: boolean = false;
  private $emulators: Emulator[] = [];
  private $selectedEmulators: Emulator[] = [];
  private $currentUsersEmulators: Emulator[] = [];

  // Related to Current Emulator Navigation
  private $currentEmulator: Emulator | null = null;
  private $currentEmulatorPane: EmulatorComponent | null = null;
  private $currentEmulatorState: EmulatorVar[] | null = null;
  private $previousEmulatorPane: any = {};

  // Related to Emulator Functionalities
  private $isEmulatorPreviewOpen: boolean = false;
  private $expandedMenuItems: any[] = [];
  private $currentIndexItemScroll: number = -1;
  private $maxIndexItemScroll: number = 0;
  private $dialogNotSupportedOpen: boolean = false;

  // Page Number For Search
  private $itemsPerPageEmulator: number = 20;

  // Related to NavigationReport
  private $isRecord: boolean = false;

  // Related to EndLesson
  private $onEndLesson: boolean = false;

  public get isLoadingEmulators(): boolean {
    return this.$isLoadingEmulators;
  }

  public get isEmulatorPreviewOpen(): boolean {
    return this.$isEmulatorPreviewOpen;
  }

  public get emulators(): Emulator[] {
    return this.$emulators;
  }

  public get selectedEmulators(): Emulator[] {
    return this.$selectedEmulators;
  }

  public get currentUsersEmulators(): Emulator[] {
    return this.$currentUsersEmulators;
  }

  public get currentEmulator(): Emulator | null {
    return this.$currentEmulator;
  }

  public get currentEmulatorPane(): EmulatorComponent | null {
    return this.$currentEmulatorPane;
  }

  public get previousEmulatorPane(): EmulatorComponent | null {
    return this.$previousEmulatorPane;
  }

  public get currentEmulatorState(): EmulatorVar[] | null {
    return this.$currentEmulatorState;
  }

  public get expandedMenuItems(): any[] {
    return this.$expandedMenuItems;
  }

  public get currentExpandedMenuItems(): any[] {
    if (this.currentEmulatorPane! && this.currentEmulatorPane!.id!) {
      return this.$expandedMenuItems[this.currentEmulatorPane!.id!];
    }
    return [];
  }

  public get currentIndexItemScroll(): number {
    return this.$currentIndexItemScroll;
  }

  public get maxIndexItemScroll(): number {
    return this.$maxIndexItemScroll;
  }

  public get dialogNotSupportedOpen(): boolean {
    return this.$dialogNotSupportedOpen;
  }

  public get isRecord(): boolean {
    return this.$isRecord;
  }

  public get onEndLesson(): boolean {
    return this.$onEndLesson;
  }

  public get itemsPerPageEmulator(): number {
    return this.$itemsPerPageEmulator;
  }

  @Mutation
  public setLoadingEmulators(isLoading: boolean) {
    this.$isLoadingEmulators = isLoading;
  }

  @Mutation
  public setPreviewOpen(isOpen: boolean) {
    this.$isEmulatorPreviewOpen = isOpen;
  }

  @Mutation
  public setEmulators(emulators: Emulator[]) {
    this.$emulators = emulators;
  }

  @Mutation
  public setSelectedEmulators(emulators: Emulator[]) {
    this.$selectedEmulators = emulators;
  }

  @Mutation
  public setCurrentUsersEmulators(emulators: Emulator[]) {
    this.$currentUsersEmulators = emulators;
  }

  @Mutation
  public setCurrentEmulator(emulator: Emulator | null) {
    this.$currentEmulator = emulator;
  }

  @Mutation
  public setCurrentEmulatorPane(emulatorPane: EmulatorComponent | null) {
    if (emulatorPane?.id && !this.$previousEmulatorPane[emulatorPane?.id] && emulatorPane?.type !== 'main_menu_pane') {
      this.$previousEmulatorPane[emulatorPane?.id] = this.$currentEmulatorPane;
    } else if (emulatorPane?.type === 'main_menu_pane') {
      this.$previousEmulatorPane = {};
    }
    this.$currentEmulatorPane = emulatorPane;
  }

  @Mutation
  public setCurrentEmulatorState(state: EmulatorVar[] | null) {
    this.$currentEmulatorState = state;
  }

  @Mutation
  public setExpandedMenuItems(tree: any[]) {
    this.$expandedMenuItems = tree;
  }

  @Mutation
  public setCurrentIndexItemScroll(previousIdx: number) {
    this.$currentIndexItemScroll = previousIdx;
  }

  @Mutation
  public setMaxIndexItemScroll(MaxIdx: number) {
    this.$maxIndexItemScroll = MaxIdx;
  }

  @Mutation
  public setDialogNotSupportedOpen(isOpen: boolean) {
    this.$dialogNotSupportedOpen = isOpen;
  }

  @Mutation
  public resetCurrentIndexItemScroll() {
    this.$currentIndexItemScroll = -1;
  }

  @Mutation
  public setIsRecord(isRecord: boolean) {
    this.$isRecord = isRecord;
  }

  @Mutation
  public setOnEndLesson(isOnEndLesson: boolean) {
    this.$onEndLesson = isOnEndLesson;
  }

  @Mutation
  public setItemsPerPageEmulator(itemsPerPage: number) {
    this.$itemsPerPageEmulator = itemsPerPage;
  }

  @Action({commit: 'setCurrentEmulator'})
  public chooseEmulator(emulator: Emulator | null) {
    if (emulator && emulator.state !== undefined && emulator.state !== null) {
      this.context.commit('setCurrentEmulatorState', emulator.state);
    }
    return emulator;
  }

  @Action({commit: 'setCurrentEmulatorPane'})
  public chooseCurrentEmulatorPane(emulatorPane: EmulatorComponent | null) {
    return emulatorPane;
  }

  @Action({commit: 'setCurrentEmulator'})
  public closeCurrentEmulator() {
    this.context.commit('setCurrentEmulatorPane', null);
    this.context.commit('setCurrentEmulatorState', []);
    this.context.commit('setExpandedMenuItems', []);
    return null;
  }

  @Action({commit: 'setCurrentEmulatorPane'})
  public chooseCurrentEmulatorPaneById(id: string) {
    let newPane: EmulatorComponent | null = null;

    if (this.context.getters.currentEmulator && this.context.getters.currentEmulator.panes) {

      newPane = this.context.getters.currentEmulator.panes[id];

      if (!newPane) {
        newPane = this.context.getters.currentEmulator.panes['1.0'];
      }
    }

    return newPane;
  }

  @Action
  public async changeCurrentState(state: EmulatorVar[]): Promise<void> {
    const pane: EmulatorComponent | null = this.context.getters.currentEmulatorPane;

    if (pane) {
      await this.context.dispatch('chooseCurrentEmulatorPane', null);
    }

    await this.context.commit('setCurrentEmulatorState', state);

    if (pane) {
      await this.context.dispatch('chooseCurrentEmulatorPane', pane);
    }
  }

  @Action
  public async dispatchAction(triggerPayload: EmulatorTriggerDispatch) {
    const navData: NavigationData = {};
    let previousIdx: number;
    let navigationTree: any[];
    let maxIdx: number;

    switch (triggerPayload.trigger.type) {
      case EmulatorTriggerType.CLICK_NOT_SUPPORTED:
        this.context.commit('setDialogNotSupportedOpen', true);
        navData.dialogNotSupportedOpen = true;
        break;
      case EmulatorTriggerType.NAVIGATE:
        await this.context.dispatch('chooseCurrentEmulatorPaneById',
          triggerPayload.trigger.payload!.location as string);
        if (!process.env.VUE_APP_STATIC_STAND_ALONE) {
          await this.context.commit('EvaluationModule/increaseClickCounter', null, {root: true});
        }
        break;
      case EmulatorTriggerType.PREV:
        navData.previousEmulatorPane
          = this.context.getters.previousEmulatorPane[this.context.getters.currentEmulatorPane.id].id;
        await this.context.dispatch('chooseCurrentEmulatorPane',
          this.context.getters.previousEmulatorPane[this.context.getters.currentEmulatorPane.id]);
        if (!process.env.VUE_APP_STATIC_STAND_ALONE) {
          await this.context.commit('EvaluationModule/increaseClickCounter', {}, {root: true});
        }
        break;
      case EmulatorTriggerType.EXPAND_PANEL:
        navigationTree = triggerPayload.trigger.payload!.data;
        await this.context.commit('setExpandedMenuItems', navigationTree);
        if (!process.env.VUE_APP_STATIC_STAND_ALONE) {
          await this.context.commit('EvaluationModule/increaseClickCounter', null, {root: true});
        }
        break;
      case EmulatorTriggerType.SCROLL_ITEM:
        maxIdx = this.context.getters.maxIndexItemScroll;
        previousIdx = this.context.getters.currentIndexItemScroll;
        switch (triggerPayload.trigger.payload!.location) {
          case 'page_up':
            if (previousIdx > 0) {
              previousIdx = previousIdx - 1;
            } else {
              previousIdx = 0;
            }
            break;
          case 'page_down':
            previousIdx = previousIdx + 1;
            break;
        }
        if (previousIdx >= 0 && previousIdx <= maxIdx) {
          await this.context.commit('setCurrentIndexItemScroll', previousIdx);
          navData.currentIndexItemScroll = previousIdx;
        }
        navData.maxIndexItemScroll = maxIdx;
        break;
    }

    if (this.context.getters.isRecord) {
      navData.state = this.context.getters.currentEmulatorState;
      navData.expandedMenuItems = this.context.getters.expandedMenuItems;
      navData.previousEmulatorPane = this.context.getters.previousEmulatorPane;
      navData.dialogNotSupportedOpen = this.context.getters.dialogNotSupportedOpen;

      triggerPayload.navigationPayload!.navData = navData;

      await this.context.dispatch('dispatchNavigationPayload', triggerPayload.navigationPayload);
    }
  }

  @Action
  public async dispatchActions(triggers: EmulatorTrigger[]) {
    if (triggers) {
      triggers.forEach((trigger: EmulatorTrigger) => {
        const pane: EmulatorComponent | null = this.context.getters.currentEmulatorPane;
        this.context.dispatch('dispatchAction', {
          trigger,
          navigationPayload: {
            paneId: pane!.id,
            triggeredAction: trigger,
          },
        } as EmulatorTriggerDispatch);
      });
    }
  }

  // Server Functions
  @Action({commit: 'setEmulators'})
  public async fetchEmulators(useLocalJSON?: boolean) {
    let emulators: Emulator[];
    const isMocked: string = process.env.VUE_APP_STATIC_DATA || 'false';
    await this.context.commit('setLoadingEmulators', true);

    if (isMocked === 'true' || useLocalJSON) {
      emulators = await fetchAllMasterEmulators();
      await this.context.commit('setLoadingEmulators', false);
    } else {
      const {data} = await axios.get('/emulator');
      emulators = data.content;
    }
    await this.context.commit('setLoadingEmulators', false);

    return emulators;
  }

  @Action({commit: 'setCurrentEmulator'})
  public async fetchEmulator(emulatorId: string, useLocalJSON: boolean = false) {
    let emulator: Emulator | null;
    const isMocked: string = process.env.VUE_APP_STATIC_DATA || 'false';

    if (isMocked === 'true' || useLocalJSON) {
      emulator = await fetchMasterEmulatorByID(emulatorId);
      await this.context.commit('setLoadingEmulators', false);
    } else {
      try {
        const {data} = await axios.get('/emulator/' + emulatorId);
        emulator = data;
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
        emulator = null;
      }
    }

    if (emulator && emulator.state !== undefined && emulator.state !== null) {
      this.context.commit('setCurrentEmulatorState', emulator.state);
    }
    return emulator;
  }

  @Action({commit: 'setSelectedEmulators'})
  public async fetchEmulatorsByIds(emulatorsIds: string[]) {
    let filteredEmulators: Emulator[] = [];

    if (emulatorsIds && emulatorsIds.length > 0) {
      try {
        const {data} = await axios.get('/emulator/emulatorId?id=' + emulatorsIds.toString());
        filteredEmulators = data.content;
      } catch (e) {
        filteredEmulators = [];
      }
    }
    return filteredEmulators;
  }

  @Action({commit: 'setCurrentUsersEmulators'})
  public async fetchEmulatorsForUser(user: User) {
    let filteredEmulators: Emulator[] = [];
    if (user.privateEmulatorIds && user.privateEmulatorIds.length > 0) {
      try {
        const {data} = await axios.get('/emulator/emulatorId?id=' + user.privateEmulatorIds.toString());
        filteredEmulators = data.content;
      } catch (e) {
        filteredEmulators = [];
      }
    }
    return filteredEmulators;
  }

  @Action({commit: 'setEmulators'})
  public async fetchEmulatorsByModel(aircraft: AircraftType) {
    let filteredEmulators: Emulator[];
    try {
      const {data} = await axios.get(`/emulator?aircraftModel=${aircraft}&size=50&page=0`);
      filteredEmulators = data.content;
    } catch (e) {
      filteredEmulators = [];
    }
    return filteredEmulators;
  }

  @Action
  public async createEmulator(emulator: Emulator) {
    await axios.post('/emulator/upload', emulator);
  }

  @Action
  public async patchEmulator(emulator: Emulator) {
    // const { data } = await axios.patch('/emulator/' + emulator.id, emulator);
    await axios.post('/emulator/upload', emulator);
    await this.context.dispatch('fetchEmulator', emulator.id);
  }

  @Action
  public async deleteEmulator(emulator: Emulator) {
    try {
      await axios.patch('/emulator/archive/' + emulator.id);
    } catch (e) {
      // eslint-disable-next-line
      console.error('Cannot Patch', e);
    }
  }

  @Action
  public dispatchNavigationPayload(navigationPayload: NavigationData): void {
    this.context.dispatch('EvaluationModule/sendNavigationPayload', navigationPayload, {root: true})
      .then();
  }

  @Action
  public async downloadSCORMPackage(emulatorId: string): Promise<void> {
    const url: string = `/emulator/download/${emulatorId}`;
    const fileName: string = `at_emulator_${emulatorId}.zip`;
    try {
      const zipFileResponse = await axios.get(url, {
        responseType: 'arraybuffer',
      });

      // console.log(zipFileResponse);

      const url_file = window.URL.createObjectURL(new Blob([zipFileResponse.data]));
      const link_file = document.createElement('a');
      link_file.href = url_file;
      link_file.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link_file);
      link_file.click();
      link_file.remove();

    } catch (e) {
      // eslint-disable-next-line
      console.error('Download failed');
    }
  }
}
