






















































































import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Emulator, EmulatorComponent } from '@/models/EmulatorModels';
import { namespace } from 'vuex-class';
import { EmulatorPaneType } from '@/enums/emulator';
import { getEmulatorPaneTypeKey } from '@/utils/labels_utils';
import ListItemCardTitle from '@/components/shared/ListItemCardTitle.vue';
import ElementsCountCard from '@/components/shared/ElementsCountCard.vue';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';
import EmulatorComponentForm from '@/components/editor/forms/EmulatorComponentForm.vue';

const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    ListItemCardTitle,
    ElementsCountCard,
    EmulatorComponentForm,
    ConfirmationModal,
  },
})
export default class EmulatorPanesListItem extends Vue {

  public isEditPaneOpen = false;
  public isConfirmationOpen = false;

  @Prop({
    required: true,
  })
  public pane!: EmulatorComponent;

  @emulatorModule.Getter
  public currentEmulator!: Emulator;

  @emulatorModule.Mutation
  public setPreviewOpen!: (isOpen: boolean) => void;

  @emulatorModule.Action
  public chooseCurrentEmulatorPane!: (emulatorPane: EmulatorComponent) => void;

  @emulatorModule.Action
  public patchEmulator!: (emulator: Emulator) => Promise<void>;

  @emulatorModule.Action
  public fetchEmulator!: (emulatorId: string) => Promise<void>;

  public chips: string[] = [
    this.getPaneTypeLabel(this.pane.type as EmulatorPaneType),
  ];

  public goToPane(): void {
    this.chooseCurrentEmulatorPane(this.pane);
    this.$router.push('/editor/' + this.currentEmulator.id + '/' + this.pane.id);
  }

  public previewButtonClick() {
    this.chooseCurrentEmulatorPane(this.pane);
    this.setPreviewOpen(true);
  }

  public editButtonClick() {
    this.isEditPaneOpen = true;
  }

  public async onNewComponent(component: EmulatorComponent): Promise<void> {
    const saveEmulator = this.currentEmulator;
    saveEmulator.panes![component.id!] = component;
    if (component.id !== this.pane.id!) {
      delete saveEmulator.panes![this.pane.id!];
    }
    await this.patchEmulator(saveEmulator);
    await this.fetchEmulator(this.currentEmulator.id);
    this.isEditPaneOpen = false;
  }

  public onCloseEditHandler(): void {
    this.isEditPaneOpen = false;
  }

  public async duplicateButtonClick(): Promise<void> {
    const saveEmulator: Emulator = _.cloneDeep(this.currentEmulator);
    if (saveEmulator.panes) {
      const newPane: EmulatorComponent = _.cloneDeep(this.pane);
      newPane.id = newPane.id + '.copy';
      newPane.name = newPane.name + ' Copy';
      saveEmulator.panes![newPane.id!] = newPane;
      await this.patchEmulator(saveEmulator);
      await this.fetchEmulator(this.currentEmulator.id);
    }
  }

  public deleteEmulatorClick() {
    this.isConfirmationOpen = true;
  }

  public async onDeleteConfirmation(isConfirmed: boolean): Promise<void> {
    if (isConfirmed) {
      const saveEmulator = this.currentEmulator;
      delete saveEmulator.panes![this.pane.id!];
      await this.patchEmulator(saveEmulator);
    }
    this.isConfirmationOpen = false;
  }

  private getPaneTypeLabel(paneType: EmulatorPaneType): string {
    return this.$t(getEmulatorPaneTypeKey(paneType)) as string;
  }
}
