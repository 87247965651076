













































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import ResizableAddItemCard from '@/components/shared/ResizableAddItemCard.vue';
import CoursePlannerForm from '@/components/coursesPlanner/forms/CoursePlannerForm.vue';
import { Course, CourseInfo } from '@/models/CoursesModels';
import { namespace } from 'vuex-class';
import CoursesListItem from '@/components/coursesPlanner/lists/CoursesListItem.vue';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import moment from 'moment';
import Loader from '@/components/shared/Loader.vue';

const coursesTemplateModule = namespace('CoursesTemplateModule');

@Component({
  components: {
    ResizableAddItemCard,
    CoursePlannerForm,
    CoursesListItem,
    Loader,
  },
  mixins: [
    ResponsiveMixin,
  ],
})
export default class CoursesList extends Vue {

  @coursesTemplateModule.Action
  public addCourse!: (course: Course) => Promise<void>;

  @coursesTemplateModule.Getter
  public coursesInfos!: CourseInfo[];

  @coursesTemplateModule.Getter
  public isLoadingTemplates!: boolean;

  @Prop({
    default: () => [],
  })
  public courses!: CourseInfo[];

  @Prop({
    default: false,
  })
  public canEdit!: boolean;

  @Prop({
    default: false,
  })
  public isShowingCurrentOnly!: boolean;

  private isCourseFormOpen: boolean = false;

  @Emit('courseAdded')
  public async addCourseHandler(course: Course): Promise<void> {
    await this.addCourse(course);
    this.isCourseFormOpen = false;
  }

  public closeCourseForm(): void {
    this.isCourseFormOpen = false;
  }

  private onCourseClickHandler(courseInfo: CourseInfo): void {
    this.$router.push('/courses-planner/' + courseInfo.id!);
  }

  private openCourseForm(): void {
    this.isCourseFormOpen = true;
  }

  private get filteredCourses(): CourseInfo[] {
    let filtered: CourseInfo[] = this.courses;
    if (this.isShowingCurrentOnly) {
      filtered = filtered.filter((course: CourseInfo) => {
        return moment(moment.now()).isSameOrAfter(course.startDate);
      });
    }
    return filtered;
  }
}
