



































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorStyle } from '@/models/EmulatorModels';
import { EmulatorComponentType } from '@/enums/emulator';
import CMCLayoutSideMenu from '@/components/cmc/sidemenu/CMCLayoutSideMenu.vue';
import CMCOrderedListMenu from '@/components/cmc/common/orderedListMenu/CMCOrderedListMenu.vue';
import CMCScrollableMenu from '@/components/cmc/controls/scrollableMenu/CMCScrollableMenu.vue';
import { itemHasStyles } from '@/utils/common';

@Component({
  components: {
    CMCLayoutSideMenu,
    CMCOrderedListMenu,
    CMCScrollableMenu,
  },
})
export default class CMCDataPreconditionPane extends Vue {
  @Prop({ required: true })
  private currentEmulatorPane!: EmulatorComponent;

  public isList(type: EmulatorComponentType) {
    return type === EmulatorComponentType.LIST;
  }

  public getStyles(styles: EmulatorStyle): any {
    return itemHasStyles(styles);
  }
}
