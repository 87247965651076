














import { Component, Vue } from 'vue-property-decorator';
// import { EmulatorPaneItemActionType, EmulatorPaneItemType } from '@/enums/emulator';
// import { EmulatorActionItem } from '@/models/EmulatorModels';
// import store from '@/store/store';

@Component({})
export default class CMCInfoPane extends Vue {
  // private title: string = 'CMCInfoPane';
  //
  // private goHome(): void {
  //   const input: EmulatorActionItem = {
  //     type: EmulatorPaneItemType.SIDE_MENU_ITEM,
  //     action: EmulatorPaneItemActionType.NAVIGATE,
  //     data: {
  //       location: '1.0',
  //     },
  //   };
  //   store.dispatch('dispatchAction',
  //     {
  //       input,
  //       emulator: store.getters.currentEmulator,
  //       coursePlan: store.getters.currentCoursePlan,
  //       pane: store.getters.currentEmulatorPane,
  //     },
  //   );
  // }
  //
  // // private created(): void {
  // // }
}
