







































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Lesson } from '@/models/CoursesModels';
import { LessonType } from '@/enums/courses';
import { getLessonTypeKey } from '@/utils/labels_utils';
import { namespace } from 'vuex-class';
import { LessonState } from '@/enums/globalUI';

const evaluationModule = namespace('EvaluationModule');

@Component({})
export default class LessonsListItem extends Vue {
  public userProfileDoneLesson: boolean = false;
  public userProfileCurrentLesson: boolean = false;
  public userProfileLockLesson: boolean = false;

  @Prop({
    required: true,
  })
  public lesson!: Lesson;

  @Prop({
    default: false,
  })
  public canEdit!: boolean;

  @Prop({
    default: false,
  })
  public isUserProfileView!: boolean;

  @Prop()
  public index!: number;

  @evaluationModule.Getter
  public lessonState!: string[];

  public mounted(): void {
    if (this.lessonState.length > 0) {
      switch (this.lessonState[this.index]) {
        case LessonState.IS_CURRENT :
          this.userProfileCurrentLesson = true;
          break;
        case LessonState.IS_DONE :
          this.userProfileDoneLesson = true;
          break;
        case LessonState.IS_LOCK :
          this.userProfileLockLesson = true;
          break;
      }
    }
  }

  @Emit('onEdit')
  public editButtonClick(): Lesson {
    return this.lesson;
  }

  @Emit('onDelete')
  public deleteButtonClick(): Lesson {
    return this.lesson;
  }

  public getLessonTypeString(type: LessonType): string {
    return this.$t(getLessonTypeKey(type)) as string;
  }

  public handlerUserActionOnClick(e) {
    if (this.isUserProfileView) {
      e.preventDefault();
      if (this.userProfileCurrentLesson || (this.isExploratory && this.isDashboard)) {
        this.playEmulator();
      }
      return false;
    }
  }

  @Emit('onPlayEmulator')
  public playEmulator(): any {
    return {
      lessonKey: this.lesson.id,
      emulatorKey: this.lesson.emulatorId,
    };
  }

  private get isLessonDisable(): boolean {
    return this.isUserProfileView &&
      (this.userProfileLockLesson || (this.userProfileDoneLesson && (!this.isExploratory && this.isDashboard)));
  }

  private get isExploratory(): boolean {
    return this.lesson.type === LessonType.EXPLORATORY;
  }

  private get isDashboard(): boolean {
    return this.$route.name === 'dashboard';
  }
}
