import { Vue } from 'vue-property-decorator';
import { EmulatorComponentType } from '@/enums/emulator';
import { EmulatorComponent, EmulatorComponentsMap } from '@/models/EmulatorModels';

class SideMenuEventManager extends Vue {

  public sideMenuClickDispatch(buttonClicked: EmulatorComponent) {
    this.$emit('sideMenuClickDispatch', buttonClicked);
  }
}

const eventBus = new SideMenuEventManager();

const defaultData: EmulatorComponentsMap = {
  0: {
    type: EmulatorComponentType.SIDE_MENU_ITEM,
  },
  1: {
    type: EmulatorComponentType.SIDE_MENU_ITEM,
  },
  2: {
    type: EmulatorComponentType.SIDE_MENU_ITEM,
  },
  3: {
    type: EmulatorComponentType.SIDE_MENU_ITEM,
  },
  4: {
    type: EmulatorComponentType.SIDE_MENU_ITEM,
  },
  5: {
    type: EmulatorComponentType.SIDE_MENU_ITEM,
  },
};

export {
  eventBus,
  defaultData,
};
