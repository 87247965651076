import { render, staticRenderFns } from "./CMCDataMotor.vue?vue&type=template&id=5b8d0c8a&scoped=true&"
import script from "./CMCDataMotor.vue?vue&type=script&lang=ts&"
export * from "./CMCDataMotor.vue?vue&type=script&lang=ts&"
import style0 from "./CMCDataMotor.vue?vue&type=style&index=0&id=5b8d0c8a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b8d0c8a",
  null
  
)

export default component.exports