


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ElementsCountCard extends Vue {
  @Prop({
    default: 'emulator.components',
  })
  public title!: string;

  @Prop({
    required: true,
  })
  public count!: number;

  @Prop({
    default: false,
  })
  public displayTime!: boolean;

  public getCount(): number | string {
    if (this.count > 0) {
      if (this.displayTime) {
        const time: Date = new Date(this.count);
        return time.getMinutes() + ':' + time.getSeconds();
      }
      return this.count;
    }
    return (this.displayTime) ? '0:00' : 0;
  }
}
