
































































import _ from 'lodash';
import { Component, Prop } from 'vue-property-decorator';
import * as eventBus from '../../shared/SideMenuEventManager';
import { EmulatorComponent, EmulatorComponentsMap, EmulatorTrigger } from '@/models/EmulatorModels';
import * as eventManager from '@/components/shared/SideMenuEventManager';
import { EmulatorComponentStatus } from '@/enums/emulator';
import { itemHasStatus } from '@/utils/common';
import { namespace } from 'vuex-class';
import ClickNotSupportedMixin from '@/components/cmc/mixins/ClickNotSupportedMixin';
import { mixins } from 'vue-class-component';

const emulatorModule = namespace('EmulatorModule');

@Component({})
export default class CMCSideMenu extends mixins(ClickNotSupportedMixin) {

  @emulatorModule.Action
  public dispatchActions!: (triggers: EmulatorTrigger[]) => void;

  @Prop()
  public sideMenuData!: EmulatorComponentsMap;

  public created() {
    this.subscribeClickHandler();
  }

  public beforeDestroy() {
    eventManager.eventBus.$off('sideMenuClickDispatch');
  }

  public isDisabled(item: EmulatorComponent): boolean {
    if (item.value) {
      return itemHasStatus(item, EmulatorComponentStatus.DISABLED);
    } else {
      return true;
    }
  }

  public clickHandler(item: EmulatorComponent): void {
    eventBus.eventBus.sideMenuClickDispatch(item);
  }

  public get renderedSideMenuData(): EmulatorComponentsMap {
    if (this.sideMenuData) {
      const cloneDefault: EmulatorComponentsMap = _.cloneDeep(
        eventManager.defaultData,
      ) as EmulatorComponentsMap;
      return _.merge(cloneDefault, this.sideMenuData);
    } else {
      return {};
    }
  }

  public get isEmpty(): boolean {
    return !this.renderedSideMenuData || Object.keys(this.renderedSideMenuData).length === 0;
  }

  protected subscribeClickHandler() {
    eventManager.eventBus.$on(
      'sideMenuClickDispatch',
      (item: EmulatorComponent) => {
        if (item.triggers && !this.hasClickNotSupported(item)) {
          this.dispatchActions(item.triggers);
        } else {
          this.dispatchActions(this.getOverriddenTriggers(item));
        }
      },
    );
  }
}
