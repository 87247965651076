import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { CAEImage, ImagePayload } from '@/models/GlobalModels';
import axios from 'axios';

@Module({ namespaced: true, name: 'mediaImageModule' })
class ImageModule extends VuexModule {
  private $currentFile: CAEImage | null = null;

  public get currentFile(): CAEImage | null {
    return this.$currentFile;
  }

  @Mutation
  public setCurrentFile(data: any) {
    this.$currentFile = data;
  }
  
  @Mutation
  public removeImage(data: any) {
    this.$currentFile = data;
  }
  @Action({ commit: 'setCurrentFile' })
  public async uploadImage(imagePayload: ImagePayload): Promise<CAEImage | null> {
    const axiosRequest = axios.create({ baseURL: process.env.VUE_APP_API_URL, timeout: 1000 });
    try {
      const { data } = await axiosRequest.post('/image/upload', imagePayload.data);
      return {
        url: data.url,
        public_id: data.public_id,
      };
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return null;
    }
  }
  
  @Action({ commit: 'removeImage' })
  public async deleteImage(imagePayload: ImagePayload): Promise<any | null> {
    const cldAxios = axios.create({ baseURL: process.env.VUE_APP_API_URL, timeout: 1000 });
    try {
      const { data } = await cldAxios.delete(`/image/delete/${imagePayload.id}?type=${imagePayload.type}`);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return null;
    }
  }
}

export default ImageModule;
