
















































import { Component } from 'vue-property-decorator';
import { Emulator, EmulatorComponent } from '@/models/EmulatorModels';
import EmulatorsList from '@/components/editor/emulatorsList/EmulatorsList.vue';
import EmulatorCreationForm from '@/components/editor/forms/EmulatorCreationForm.vue';
import { namespace } from 'vuex-class';
import BreadCrumbs from '@/components/shared/BreadCrumbs.vue';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import UserMixin from '@/mixins/UserMixin';
import { mixins } from 'vue-class-component';

const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    EmulatorsList,
    EmulatorCreationForm,
    BreadCrumbs,
  },
})
export default class EmulatorEditorLanding extends mixins(ResponsiveMixin, UserMixin) {
  public tempPopup: boolean = false;
  public uploadSnackbar: boolean = false;

  @emulatorModule.Getter
  public currentEmulator!: Emulator;

  @emulatorModule.Getter
  public currentEmulatorPane!: EmulatorComponent;

  @emulatorModule.Mutation
  public setPreviewOpen!: (isOpen: boolean) => void;

  @emulatorModule.Action
  public fetchEmulator!: (id: string) => Promise<void>;

  @emulatorModule.Action
  public chooseCurrentEmulatorPane!: (pane: EmulatorComponent) => void;

  @emulatorModule.Action
  public downloadSCORMPackage!: (emulatorId: string) => Promise<void>;

  public onAddItemHandler() {
    this.tempPopup = true;
  }

  public onPreviewHandler(emulator: Emulator) {
    this.fetchEmulator(emulator.id).then(() => {
      this.chooseCurrentEmulatorPane(this.currentEmulator!.panes!['1.0']);
      this.setPreviewOpen(true);
    });
  }

  public async onDownloadHandler(emulator: Emulator) {
    this.uploadSnackbar = true;
    await this.downloadSCORMPackage(emulator.id);
  }

  public onCloseAddFormHandler() {
    this.tempPopup = false;
  }
}
