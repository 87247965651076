















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Emulator, EmulatorComponent } from '@/models/EmulatorModels';
import EmulatorView from '@/components/viewers/EmulatorView.vue';
import { namespace } from 'vuex-class';
import { UISnackBar } from '@/models/UIModels';
import { wait } from '@/utils/common';
import { User } from '@/models/UserModels';
import { Session } from '@/models/EvaluationModels';

const globalUI = namespace('GlobalUIModule');
const emulatorModule = namespace('EmulatorModule');
const evaluationModule = namespace('EvaluationModule');
const userModule = namespace('UserModule');

@Component({
  components: {
    EmulatorView,
  },
})
export default class EmulatorViewer extends Vue {
  public isLoading: boolean = true;
  public isDialogOpen: boolean = true;

  @globalUI.Action
  public showSnackBar!: (snackBar: UISnackBar) => void;

  @userModule.Getter
  public currentUser!: User;

  @emulatorModule.Getter
  public isRecord!: boolean;

  @emulatorModule.Getter
  public currentEmulator!: Emulator;

  @emulatorModule.Getter
  public currentEmulatorPane!: EmulatorComponent;

  @emulatorModule.Mutation
  public setIsRecord!: (isRecord: boolean) => void;

  @emulatorModule.Mutation
  public setCurrentEmulator!: (emulator: Emulator | null) => void;

  @emulatorModule.Mutation
  public setCurrentEmulatorPane!: (emulatorPane: EmulatorComponent | null) => void;

  @emulatorModule.Action
  public fetchEmulator!: (emulatorId: string) => Promise<void>;

  @emulatorModule.Action
  public chooseCurrentEmulatorPane!: (emulatorPane: EmulatorComponent) => void;

  @evaluationModule.Getter
  public session!: Session;

  @evaluationModule.Getter
  public sessionId!: string;

  @evaluationModule.Mutation
  public setSessionId!: (sessionId: string) => void;

  @evaluationModule.Action
  public createSession!: (sessionObj: Session) => Promise<Session>;

  @evaluationModule.Action
  public destroySession!: (sessionId: string) => void;

  @emulatorModule.Action
  public closeCurrentEmulator!: () => void;

  @Prop({
    default: false,
  })
  public initialDialogOpen!: boolean;

  @Prop({
    required: true,
  })
  public emulatorId!: string;

  @Prop({
    default: '',
  })
  public courseId!: string;

  @Prop({
    default: '1.0',
  })
  public emulatorPaneId!: string;

  @Prop({
    default: '',
  })
  public lessonId!: string;

  @Emit('handleCloseViewer')
  public async closeViewer(): Promise<void> {
    await this.emptyCurrentEmulator();
  }

  protected created() {
    this.isDialogOpen = this.initialDialogOpen;
    wait(200).then(() => {
      this.setEmulator();
      if (this.courseId !== '') {
        const sessionObj: Session = {
          id: null,
          userId: this.getUserID(),
          emulatorId: this.emulatorId,
          lessonId: (this.lessonId !== '') ? this.lessonId : null,
          lessonCompletedOn: null,
          creationDate: null,
          archived: false,
          courseId: this.courseId,
        };
        this.createSession(sessionObj).then((session) => {
          if (session.lessonId) {
            this.setIsRecord(true);
            this.setSessionId(session.id!);
          }
        });
      }
    });
  }

  private beforeDestroy() {
    this.resetIsRecordAndSessionId();
  }

  private setEmulator(): void {
    this.isLoading = true;
    this.fetchEmulator(this.emulatorId).then(() => {
      document.title = this.currentEmulator.title;
      this.chooseCurrentEmulatorPane(this.currentEmulator!.panes![this.emulatorPaneId]);
      this.isLoading = false;
    }).catch(() => {
      this.showSnackBar({
        color: 'error',
        text: 'common.error.httpProblem',
      });
    });
  }

  private async emptyCurrentEmulator(): Promise<void> {
    this.isDialogOpen = false;
    this.resetIsRecordAndSessionId();
    document.title = process.env.VUE_APP_TITLE || '';
  }

  private getUserID(): string {
    if (Object.keys(this.$route.params).includes('userId')) {
      return this.$route.params.userId;
    }
    return this.currentUser.id;
  }

  private resetIsRecordAndSessionId(): void {
    this.setIsRecord(false);
    this.setSessionId('');
  }
}
