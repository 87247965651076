

























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CMCCharacterProgressBar extends Vue {

  @Prop({ default: 0 })
  private percent!: number;

  private sampleWidth: number = 0;

  private mounted(): void {
    this.sampleWidth = this.sampleElementWidth;
  }

  get sampleElementWidth(): number {
    let sampleElement: Element;
    let calculatedWidth: number = 0;
    if (this.$el) {
      sampleElement = this.$el.getElementsByClassName('character-progress__sample')[0];
      calculatedWidth = Math.round(sampleElement.clientWidth);
    }
    return calculatedWidth;
  }

  get fullWidth(): number {
    const fullEl = this.$el.getElementsByClassName('character-progress__bar')[0];
    return fullEl.clientWidth;
  }

  get numberCharacters(): number {
    let numberChar = 0;
    if (this.sampleWidth !== 0) {
      numberChar = Math.ceil(
        Math.ceil(this.percent / 100 * this.fullWidth) / this.sampleWidth) - 1;
    }

    return numberChar;
  }

  get percentString(): string {
    return this.percent + '%';
  }
}
