import i18n from '@/plugins/i18n';
import AircraftType from '@/enums/user/aircraft';
import { UserRoleLine } from '@/models/UserModels';

export default class UserRoleType {

  public static FLEET_CHIEF: string = 'fleet-chief';
  public static ADMIN: string = 'admin';
  public static INSTRUCTOR: string = 'instructor';
  public static CLIENT: string = 'client';
  public static GUEST: string = 'guest';

  public static getRoles(): string[] {
    return [
      UserRoleType.FLEET_CHIEF,
      UserRoleType.ADMIN,
      UserRoleType.INSTRUCTOR,
      UserRoleType.CLIENT,
    ];
  }

  // Display & UI
  public static getRoleLabelByType(roleType: string): string {
    switch (roleType) {
      case UserRoleType.GUEST:
        return i18n.t('common.roles.guest').toString();

      case UserRoleType.CLIENT:
        return i18n.t('common.roles.user').toString();

      case UserRoleType.INSTRUCTOR:
        return i18n.t('common.roles.instructor').toString();

      case UserRoleType.ADMIN:
        return i18n.t('common.roles.admin').toString();

      case UserRoleType.FLEET_CHIEF:
        return i18n.t('common.roles.fleetChief').toString();

      default:
        return 'Unknown Role';
    }
  }

  public static getDisplayRolesList(roles: { [index: string]: string[] }): string[] {
    const outputList: string[] = [];
    const roleMap = new Map(Object.entries(roles));

    roleMap.forEach((aircraftList, roleString) => {
      const prefix = UserRoleType.getRoleLabelByType(roleString);

      if (aircraftList.length === 0 || roleString === UserRoleType.GUEST || roleString === UserRoleType.ADMIN) {
        outputList.push(prefix);

      } else {
        aircraftList.forEach((aircraft) => {
          outputList.push(prefix + ' (' + AircraftType.getAircraftLabelByType(aircraft) + ')');
        });
      }
    });

    return outputList;
  }

  public static getDisplayRolesListAbridged(roles: { [index: string]: string[] }): string[] {
    const outputList: string[] = [];
    const roleMap = new Map(Object.entries(roles));

    roleMap.forEach((aircraftList, roleString) => {
      const prefix = UserRoleType.getRoleLabelByType(roleString);

      if (aircraftList.length > 0) {
        if (aircraftList.length === 1) {
          outputList.push(prefix + ' ' + '(' + AircraftType.getAircraftLabelByType(aircraftList[0]) + ')');

        } else {
          outputList.push(prefix + ' ' + '(...)');
        }
      }
    });

    return outputList;
  }

  // Permissions
  public static getAssignableRolesForUser(userRoles: { [index: string]: string[] }): string[] {
    if (this.isAdmin(userRoles)) {
      return UserRoleType.getRoles();

    } else if (this.isFleetChief(userRoles)) {
      return [UserRoleType.CLIENT, UserRoleType.INSTRUCTOR];

    } else if (this.isInstructor(userRoles)) {
      return [UserRoleType.CLIENT];

    } else {
      return [];
    }
  }

  public static getAssignableAircraftForUser(userRoles: { [index: string]: string[] }) {
    const noAssignableAircraft: string[] = [];
    let fleetChiefAssignableAircraft: string[] = noAssignableAircraft;
    let instructorAssignableAircraft: string[] = noAssignableAircraft;

    if (this.isAdmin(userRoles)) {
      return AircraftType.getAircraft();

    } else if (this.isFleetChief(userRoles) || this.isInstructor(userRoles)) {
      const userRolesMap = new Map(Object.entries(userRoles));

      userRolesMap.forEach((value, key) => {
        if (key === UserRoleType.FLEET_CHIEF) {
          if (value) {
            fleetChiefAssignableAircraft = value;
          }
        }

        if (key === UserRoleType.INSTRUCTOR) {
          if (value) {
            instructorAssignableAircraft = value;
          }
        }
      });

      if (!this.isInstructor(userRoles)) {
        return fleetChiefAssignableAircraft;

      } else if (!this.isFleetChief(userRoles)) {
        return instructorAssignableAircraft;

        // Both FleetChief & Instructor
      } else {
        // If both list contain the same elements (could probably be removed and be faster).
        if (fleetChiefAssignableAircraft.sort().join(',') === instructorAssignableAircraft.sort().join(',')) {
          return fleetChiefAssignableAircraft;

        } else {
          // List of only common elements
          return fleetChiefAssignableAircraft.filter((value) => { // iterate over the array
            return instructorAssignableAircraft.indexOf(value) > -1;
          });
        }
      }

    } else {
      return noAssignableAircraft;
    }
  }

  public static canToggleUser(
    currentUserRoles: { [index: string]: string[] }, targetUserRoles: { [index: string]: string[] },
  ): boolean {
    if (UserRoleType.isAdmin(currentUserRoles)) {
      return true;
    } else if (UserRoleType.isFleetChief(currentUserRoles)) {
      if (!UserRoleType.isAdmin(targetUserRoles)
        && !UserRoleType.isFleetChief(targetUserRoles)) {
        return true;
      }
    }

    return false;
  }

  public static canEditRoles(
    currentUserRoles: { [index: string]: string[] }, targetUserRoles: { [index: string]: string[] },
  ): boolean {
    if ((UserRoleType.isFleetChief(targetUserRoles)
      || UserRoleType.isAdmin(targetUserRoles)
      && !UserRoleType.isAdmin(currentUserRoles))) {
      return false;
    }

    return true;
  }

  // Conversion
  public static convertRolesToUserRoleLines(roles: { [index: string]: string[] }): UserRoleLine[] {
    const userRoleLines: UserRoleLine[] = [];
    const roleMap = new Map(Object.entries(roles));

    roleMap.forEach((aircraftList, roleString) => {
      const userRoleLine: UserRoleLine = {
        role: roleString,
        aircraft: aircraftList,
      };

      userRoleLines.push(userRoleLine);
    });

    return userRoleLines;
  }

  public static convertUserRoleLinesToRoles(userRoleLines: UserRoleLine[]): { [index: string]: string[] } {
    const roles: { [index: string]: string[] } = {};

    userRoleLines.forEach((value) => {
      roles[value.role] = value.aircraft;
    });

    return roles;
  }

  // Roles
  public static isFleetChief(roles: { [index: string]: string[] }): boolean {
    if (roles) {
      const arrayRoles = Object.keys(roles);
      return this.checkRoles([UserRoleType.FLEET_CHIEF], arrayRoles);

    } else {
      return false;
    }
  }

  public static isAdmin(roles: { [index: string]: string[] }): boolean {
    if (roles) {
      const arrayRoles = Object.keys(roles);
      return this.checkRoles([UserRoleType.ADMIN], arrayRoles);

    } else {
      return false;
    }
  }

  public static isInstructor(roles: { [index: string]: string[] }): boolean {
    if (roles) {
      const arrayRoles = Object.keys(roles);
      return this.checkRoles([UserRoleType.INSTRUCTOR], arrayRoles);

    } else {
      return false;
    }
  }

  public static isClient(roles: { [index: string]: string[] }): boolean {
    if (roles) {
      const arrayRoles = Object.keys(roles);
      return this.checkRoles([UserRoleType.CLIENT], arrayRoles);

    } else {
      return false;
    }
  }

  public static isGuest(roles: { [index: string]: string[] }): boolean {
    if (roles) {
      const arrayRoles = Array.from(Object.keys(roles));

      if (arrayRoles !== [] && !arrayRoles.includes(this.GUEST)) {
        return false;
      }
    }

    return true;
  }

  public static hasAccessByRoles(requiredRoles: string[], roles: { [index: string]: string[] }): boolean {
    const arrayRoles = Array.from(Object.keys(roles));

    return this.checkRoles(requiredRoles, arrayRoles);
  }

  public static hasAccessForAircraft(
    requiredAircraft: string,
    requiredRoles: string[],
    roles: { [index: string]: string[] },
  ): boolean {
    let hasAccess: boolean = false;
    requiredRoles.forEach((role: string) => {
      if (!hasAccess && roles[role] && this.checkAircraftRoles(roles[role], requiredAircraft)) {
        hasAccess = true;
      }
    });
    return hasAccess;
  }

  private static checkRoles(checkedRoles: UserRoleType[], userRoles: UserRoleType[]): boolean {
    if (userRoles) {
      return checkedRoles.filter((checkedRole: UserRoleType) => {
        return userRoles.find((userRole: UserRoleType) => {
          return checkedRole === userRole;
        }) !== undefined;
      }).length > 0;
    } else {
      return false;
    }
  }

  private static checkAircraftRoles(aircraftList: string[], requiredAircraft: string): boolean {
    return aircraftList.find((aircraftType: string) => {
      return aircraftType === requiredAircraft;
    }) !== undefined;
  }
}

// NOT UPDATED (MIGHT BE OUTDATED)

// FLEET_CHIEF
// Add/Remove Instructors
// Add/Remove Clients
// Create Client Group
// Access/Modify CoursePlans
// Access/Modify Nav Emulator
// Modify User permissions

// ADMIN
// Add/Remove Clients
// Add/Remove Instructors
// Create Client/Instructor Groups

// INSTRUCTOR
// Add/Remove Clients
// Create Client Group
// Access CoursePlans
// View Evaluator Parameters for created groups

// CLIENT
// Access CoursePlan

