


















import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';

const globalUI = namespace('GlobalUIModule');

@Component
export default class DialogContentInactivity extends Vue {

  @globalUI.Mutation
  public setDialogVisible!: (isVisible: boolean) => void;

  @globalUI.Getter
  public inactivityThreshold!: number;

  private inactivityRemainingSeconds: number = 0;
  private inactivityInterval: any = null;

  public created() {
    this.inactivityRemainingSeconds = this.inactivityThreshold;

    this.inactivityInterval = setInterval(() => {
      this.inactivityRemainingSeconds--;
    }, 1000);
  }

  public destroyed() {
    clearInterval(this.inactivityInterval);
  }

}
