













































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorStyle } from '@/models/EmulatorModels';
import CMCDataTable from '@/components/cmc/controls/CMCDataTable.vue';
import { EmulatorComponentType } from '@/enums/emulator';
import { itemHasStyles } from '@/utils/common';
import CMCOrderedListMenu from '@/components/cmc/common/orderedListMenu/CMCOrderedListMenu.vue';

@Component({
  components: {
    CMCDataTable,
    CMCOrderedListMenu,
  },
})
export default class CMCTablePane extends Vue {
  private limitColumns: number = 6;
  @Prop({ required: true })
  private currentEmulatorPane!: EmulatorComponent;

  public getStyles(styles: EmulatorStyle): any {
    return itemHasStyles(styles);
  }

  public isTable(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE);
  }

  public isTableHeader(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE_HEADER);
  }

  public isTableBody(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE_BODY);
  }

  public isRowItem(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE_ROW_ITEM);
  }

  public isBoolean(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.BOOLEAN);
  }

  public isText(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TEXT);
  }

  public isList(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.LIST);
  }

  public getColSpan(style: any): number {
    return style?.css?.columnSpan || 0;
  }

}
