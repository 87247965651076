




























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { EmulatorTriggerType } from '@/enums/emulator';
import { getTriggersKey } from '@/utils/labels_utils';
import { EmulatorTrigger } from '@/models/EmulatorModels';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import { mixins } from 'vue-class-component';

@Component({})
export default class EmulatorTriggerForm extends mixins(ResponsiveMixin) {

  public isFormValid: boolean = false;
  public formTriggerType: string = '';
  public location: string = '';
  public direction: number = 0;

  public requiredRules: any[] = [
    (v) => !!v || this.getErrorMessage('common.error.fieldRequired'),
  ];

  public get triggerTypesList(): string[] {
    const tableEnum: any[] = [];
    for (const enumMember in EmulatorTriggerType) {
      if (enumMember) {
        tableEnum.push({
          id: EmulatorTriggerType[enumMember],
          label: this.$t(getTriggersKey(EmulatorTriggerType[enumMember])),
        });
      }
    }
    return tableEnum;
  }

  public get isNavigate(): boolean {
    return this.formTriggerType === EmulatorTriggerType.NAVIGATE;
  }

  public get isExpand(): boolean {
    return this.formTriggerType === EmulatorTriggerType.EXPAND_PANEL;
  }

  public get isPaginatorChangePage(): boolean {
    return this.formTriggerType === EmulatorTriggerType.CHANGE_PAGE;
  }

  @Prop()
  public editedTrigger!: EmulatorTrigger | null;

  public created() {
    if (this.editedTrigger) {
      this.formTriggerType = this.editedTrigger.type as string;
      this.location = this.editedTrigger.payload!.location ? this.editedTrigger.payload!.location : '';
      this.direction = this.editedTrigger.payload!.direction ? this.editedTrigger.payload!.direction : 0;
    }
  }

  public async onSaveClick() {
    this.isFormValid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.isFormValid) {
      this.onAddTrigger();
    }
  }

  @Emit('onAddTriggerHandler')
  public onAddTrigger() {
    const newEmulatorTrigger: EmulatorTrigger = {
      type: this.formTriggerType as EmulatorTriggerType,
      payload: {
        location: this.location,
        direction: this.direction,
      },
    };
    this.resetForm();
    return newEmulatorTrigger;
  }

  @Emit('onCloseHandler')
  public onCloseHandler() {
    this.resetForm();
    return;
  }

  public getErrorMessage(key: string, args?: any): string {
    return this.$t(key, args) as string;
  }

  private resetForm() {
    this.isFormValid = (this.$refs.form as Vue & { reset: () => boolean }).reset();
  }
}
