
























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import RuleUtility from '@/utils/rules';
import { Lesson } from '@/models/CoursesModels';
import { LessonType } from '@/enums/courses';
import { getLessonTypeKey } from '@/utils/labels_utils';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import { mixins } from 'vue-class-component';

@Component({})
export default class  LessonForm extends mixins(ResponsiveMixin) {

  public ruleUtility = RuleUtility;

  public isFormValid: boolean = false;

  public isFormSaving: boolean = false;

  public title: string = '';
  public description: string = '';
  public lessonType!: LessonType;

  public get lessonTypeList(): string[] {
    const tableEnum: any[] = [];
    for (const enumItem in LessonType) {
      if (enumItem) {
        tableEnum.push({
          id: LessonType[enumItem],
          label: this.$t(getLessonTypeKey(LessonType[enumItem])),
        });
      }
    }
    return tableEnum;
  }

  @Prop({
    default: null,
  })
  public lesson!: Lesson | null;

  @Prop({
    default: '',
  })
  public formTitle!: string;

  @Emit('onSave')
  public save(): Lesson {
    if (this.lesson) {
      return {
        id: this.lesson.id,
        title: this.title,
        description: this.description,
        type: this.lessonType,
        order: this.lesson.order,
      };
    } else {
      return {
        title: this.title,
        description: this.description,
        type: this.lessonType,
        order: 0,
      };
    }
  }

  @Emit('onCancel')
  public onCancelHandler(): void {
    this.reset();
  }

  public created(): void {
    if (this.lesson) {
      this.title = this.lesson.title;
      this.description = this.lesson.description;
      this.lessonType = this.lesson.type;
    } else {
      this.lessonType = LessonType.EXPLORATORY;
    }
  }

  public beforeDestroyed(): void {
    this.reset();
    this.isFormSaving = false;
  }

  public onSaveClick(): void {
    this.isFormValid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.isFormValid) {
      this.isFormSaving = true;
      this.save();
    }
  }

  private reset(): void {
    (this.$refs.form as HTMLFormElement).reset();
  }
}
