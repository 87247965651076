var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"mt-3"},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.searchItems,"page":_vm.actualPage,"items-per-page":_vm.selectItemPerPage,"loading":_vm.loading,"sort-by":"aircraft","item-key":"id","calculate-width":"true","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.aircraftModel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.aircraftType.getAircraftLabelByType(item.aircraftModel))+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.title))])]}},{key:"item.cloneId",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.cloneTitle))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","id":item.id,"color":"success"},on:{"click":function($event){return _vm.onPreviewHandler(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-play ")])],1),_c('v-btn',{attrs:{"icon":"","small":"","id":("download_" + (item.id)),"color":"emulator lighten-2"},on:{"click":function($event){return _vm.onDownloadHandler(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-download ")])],1),(_vm.canEdit)?_c('v-btn',{attrs:{"icon":"","small":"","color":"primary","disabled":!item.canBeModified,"to":'editor/' + item.id}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-pen ")])],1):_vm._e(),(_vm.canEdit)?_c('v-btn',{attrs:{"icon":"","small":"","color":"error","disabled":!item.canBeModified},on:{"click":function($event){return _vm.onDeleteEmulatorClick(item.title)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-trash-alt ")])],1):_vm._e()]}},{key:"footer",fn:function(){return [_c('PaginationFooter',{attrs:{"actual-page":_vm.actualPage,"select-item-per-page":_vm.selectItemPerPage !== _vm.totalElements ? _vm.selectItemPerPage : 'ALL',"total-pages":_vm.totalPages,"items-per-page-options":_vm.itemsPerPageOptions},on:{"updateItemsPerPage":_vm.updateItemsPerPage,"nextPage":_vm.nextPage,"previousPage":_vm.previousPage}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }