


















import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorComponentsMap } from '@/models/EmulatorModels';
import CMCExpandableMenuItem from '@/components/cmc/controls/expandableMenu/CMCExpandableMenuItem.vue';
import CMCFlightDeckEffectsPane from '@/components/cmc/panes/CMCFlightDeckEffectsPane.vue';
import { namespace } from 'vuex-class';
import { getObjectPath } from '@/utils/common';

const emulatorModule = namespace('EmulatorModule');

@Component({
  name: 'CMCExpandableMenu',
  components: {
    CMCExpandableMenuItem,
    CMCFlightDeckEffectsPane,
  },
})
export default class CMCExpandableMenu extends Vue {

  public openedItems: number[] = [];

  @emulatorModule.Getter
  public expandedMenuItems!: any[];

  @Prop({
    default: 0,
  },
  )
  private level!: number;

  @Prop({
    default: () => [],
  },
  )
  private navigationPath!: string[];

  @Prop()
  private components!: EmulatorComponentsMap;

  public getNavigationPath(component: EmulatorComponent, name?: string) {
    const arrPath = [...this.navigationPath];
    if (name) {
      arrPath.push(name);
    } else if (component.id) {
      arrPath.push(component.id);
    }
    return arrPath;
  }

  public created() {
    this.setExpandedItems();
  }

  public beforeUpdate() {
    this.setExpandedItems();
  }

  public setExpandedItems(): void {
    this.openedItems = [];
    // Find if panels from this page have been previously opened
    Object.entries(this.components).map((item: [string, EmulatorComponent], index: number) => {
      const objectPath: string = getObjectPath(this.getNavigationPath(item[1], item[0]));
      if (_.get(this.expandedMenuItems, objectPath)) {
        this.openedItems.push(index);
      }
    });
  }

  public emitExpandableMenu(e) {
    this.$nextTick(() => this.$emit('expandableMenu', e));
  }

  public emitReportStatus(e) {
    this.$emit('reportStatus', e);
  }
}
