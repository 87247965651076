import { Component, Vue } from 'vue-property-decorator';
import UserRoleType from '@/enums/user';
import { namespace } from 'vuex-class';
import { User, UserProfile } from '@/models/UserModels';


const auth = namespace('AuthModule');
const userModule = namespace('UserModule');

@Component({})
export default class UserMixin extends Vue {

  @auth.Getter
  public isAuthenticated!: boolean;

  @userModule.Getter
  public userRoles!: { [index: string]: string[] };

  @userModule.Getter
  public currentUser!: User | null;

  @userModule.Getter
  public currentUserProfile!: UserProfile | null;

  public get isGuest(): boolean {
    return UserRoleType.isGuest(this.userRoles);
  }

  public get isClient(): boolean {
    return UserRoleType.isClient(this.userRoles);
  }

  public get isInstructor(): boolean {
    return UserRoleType.isInstructor(this.userRoles);
  }

  public get isFleetChief(): boolean {
    return UserRoleType.isFleetChief(this.userRoles);
  }

  public get isAdmin(): boolean {
    return UserRoleType.isAdmin(this.userRoles);
  }

  public get isOnlyAdmin(): boolean {
    return UserRoleType.isAdmin(this.userRoles)
      && !this.isClient
      && !this.isInstructor
      && !this.isFleetChief;
  }
}
