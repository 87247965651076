






























































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import draggable from 'vuedraggable';
import { Course, CourseInfo, CourseTemplate } from '@/models/CoursesModels';
import { Emulator } from '@/models/EmulatorModels';
import AddEmulatorsForm from '@/components/coursesTemplates/emulator/AddEmulatorsForm.vue';
import CourseTemplatesEmulatorsListItem from '@/components/courses/CourseEmulatorsListItem.vue';
import ToolbarTitle from '@/components/shared/ToolbarTitle.vue';

const emulatorModule = namespace('EmulatorModule');
const coursesTemplateModule = namespace('CoursesTemplateModule');

@Component({
  components: {
    AddEmulatorsForm,
    CourseTemplatesEmulatorsListItem,
    draggable,
    ToolbarTitle,
  },
})
export default class CourseEmulatorsList extends Vue {
  public isLoadingEmulators: boolean = false;
  public isEmulatorsPopupOpen: boolean = false;

  @Prop({
    default: true,
  })
  public canEdit!: boolean;

  @Prop({
    default: true,
  })
  public canActivateEmulators!: boolean;

  @Prop({
    default: false,
  })
  public isUserProfileView!: boolean;

  @Prop({
    required: true,
  })
  public courseObject!: CourseTemplate | CourseInfo;

  @Prop({
    required: false,
    default: true,
  })
  public widthHeader!: boolean;

  @emulatorModule.Action
  public fetchEmulatorsByIds!: (emulatorsIds: string[]) => Promise<void>;

  @emulatorModule.Getter
  public selectedEmulators!: Emulator[];

  @emulatorModule.Mutation
  public setSelectedEmulators!: (emulators: Emulator[]) => void;

  @coursesTemplateModule.Action
  public editTemplate!: (template: CourseTemplate) => Promise<void>;

  @coursesTemplateModule.Action
  public updateCourse!: (course: Course) => Promise<void>;

  public created() {
    this.isLoadingEmulators = true;
    if (this.courseObject && this.courseObject.emulatorIds) {
      this.fetchEmulatorsByIds(this.courseObject.emulatorIds).then(() => {
        this.isLoadingEmulators = false;
      });
    }
  }

  public destroyed(): void {
    this.setSelectedEmulators([]);
  }

  public openEmulatorsPopup(): void {
    this.isEmulatorsPopupOpen = true;
  }

  public closeEmulatorsPopup(): void {
    this.isEmulatorsPopupOpen = false;
  }

  public async saveEmulatorsToTemplate(emulatorIds: string[]): Promise<void> {
    const newCourseTemplate: CourseTemplate | CourseInfo = this.courseObject;
    newCourseTemplate.emulatorIds = emulatorIds;
    await this.editTemplate(newCourseTemplate as CourseTemplate);
    await this.fetchEmulatorsByIds(newCourseTemplate.emulatorIds);
    this.isEmulatorsPopupOpen = false;
  }

  public async endDrag(): Promise<void> {
    const newListOrder = this.selectedEmulators.map((item: Emulator) => {
      return item.id;
    });
    this.courseObject.emulatorIds = newListOrder;
    await this.editTemplate(this.courseObject as CourseTemplate);
  }

  @Emit('playEmulatorOnClick')
  public playEmulatorOnClick(lessonEmulator: any): void {
    return lessonEmulator;
  }

  public get courseId(): string {
    if (this.courseObject) {
      return this.courseObject.id!;
    }
    return '';
  }

  private isActivated(idEmulator): boolean {
    if (this.courseObject && (this.courseObject as CourseInfo).enabledEmulatorIds) {
      return (this.courseObject as CourseInfo).enabledEmulatorIds.find((id: string) => {
        return id === idEmulator;
      }) !== undefined;
    } else {
      return false;
    }
  }

  private activateEmulatorHandler(emulatorId: string): void {
    const newCourse = this.courseObject as Course;
    newCourse.enabledEmulatorIds.push(emulatorId);
    this.updateCourse(newCourse);
  }

  private deactivateEmulatorHandler(emulatorId: string): void {
    const newCourse: Course = this.courseObject as Course;
    newCourse.enabledEmulatorIds.forEach((item: string, index: number) => {
      if (item === emulatorId) {
        newCourse.enabledEmulatorIds.splice(index, 1);
      }
    });
    this.updateCourse(newCourse);
  }
}
