import BaseService from '@/store/services/BaseService';
import { Emulator } from '@/models/EmulatorModels';
import { TokenValidationInfo } from '@/models/ExternalViewModels';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export default class ExternalViewService extends BaseService {
  public static EXTERNAL_VIEW_URL = '/view';
  public static EXTERNAL_TOKEN_URL = `${ExternalViewService.EXTERNAL_VIEW_URL}/token`;
  public static EXTERNAL_TOKEN_VALIDATE_URL = `${ExternalViewService.EXTERNAL_TOKEN_URL}/validate`;
  public static EXTERNAL_EMULATOR_URL = `${ExternalViewService.EXTERNAL_VIEW_URL}/emulator`;

  public static async validateExternalToken(tokenValidationInfo: TokenValidationInfo): Promise<string | null> {
    return await super.postJSON(`${ExternalViewService.EXTERNAL_TOKEN_VALIDATE_URL}`, tokenValidationInfo, ExternalViewService.externalAxiosInstance());
  }

  public static async getExternalEmulator(id: string, jwtToken: string): Promise<Emulator | null> {
    return await super.getJSON(`${ExternalViewService.EXTERNAL_EMULATOR_URL}/${id}`, {}, ExternalViewService.externalAxiosInstance(jwtToken));
  }

  public static externalAxiosInstance(jwtToken?: string): AxiosInstance {
    const axiosInstance:AxiosInstance = axios.create();
    axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
      if (jwtToken) {
        config.headers.Authorization = `Bearer ${jwtToken}`;
      }
      return Promise.resolve(config);
    });
    return axiosInstance;
  }

}
