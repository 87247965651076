import axios, { AxiosInstance } from 'axios';
import { isProd } from '@/utils/common';

export default class BaseService {
  public static async getJSON(url: string, params?: any, customAxiosInstance?: AxiosInstance): Promise<any> {
    try {
      const { data } = await BaseService.axiosInstance(customAxiosInstance).get(url, {
        params,
      });
      return data;
    } catch (e) {
      if (!isProd()) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }

  public static async postJSON(url: string, payload: any, customAxiosInstance?: AxiosInstance): Promise<any> {
    try {
      const { data } = await BaseService.axiosInstance(customAxiosInstance).post(url, payload);
      return data;
    } catch (e) {
      if (!isProd()) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }

  public static axiosInstance(instance: AxiosInstance | undefined): AxiosInstance {
    return instance || axios;
  }
}

