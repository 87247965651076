import {
  EmulatorComponentStatus,
  EmulatorComponentType,
  EmulatorPaneType,
  EmulatorTriggerType,
} from '@/enums/emulator';

import { Emulator } from '@/models/EmulatorModels';
import AircraftType from '@/enums/user/aircraft';
import { LessonType } from '@/enums/courses';
import moment from 'moment';
import i18n from '@/plugins/i18n';

export const getEmulatorPaneTypeKey = (type: EmulatorPaneType): string => {
  let key = '';
  switch (type) {
    case EmulatorPaneType.MAIN_MENU:
      key = 'enum.emulatorPaneType.mainMenu';
      break;
    case EmulatorPaneType.SECONDARY_MENU:
      key = 'enum.emulatorPaneType.secondaryMenu';
      break;
    case EmulatorPaneType.END_OF_FLIGHT:
      key = 'enum.emulatorPaneType.endOfFlight';
      break;
    case EmulatorPaneType.PAGINATOR:
      key = 'enum.emulatorPaneType.paginator';
      break;
    case EmulatorPaneType.PAGINATOR_LIST:
      key = 'enum.emulatorPaneType.paginatorList';
      break;
    case EmulatorPaneType.FAN_STATUS_DU:
      key = 'enum.emulatorPaneType.fanStatusDu';
      break;
    case EmulatorPaneType.FAN_STATUS_MAU:
      key = 'enum.emulatorPaneType.fanStatusMau';
      break;
    case EmulatorPaneType.FAULT:
      key = 'enum.emulatorPaneType.fault';
      break;
    case EmulatorPaneType.FDE:
      key = 'enum.emulatorPaneType.fde';
      break;
    case EmulatorPaneType.INFO:
      key = 'enum.emulatorPaneType.info';
      break;
    case EmulatorPaneType.FLIGHTDECKEFFECTS_NEW_PANE:
      key = 'enum.emulatorPaneType.flightDeckEffects';
      break;
    case EmulatorPaneType.TABLE:
      key = 'enum.emulatorPaneType.table';
      break;
    case EmulatorPaneType.TABLE_STATUS:
      key = 'enum.emulatorPaneType.tableStatusPane';
      break;
    case EmulatorPaneType.DATA_AC_SYSTEM:
      key = 'enum.emulatorPaneType.dataAcSystemPane';
      break;
    case EmulatorPaneType.INDICATOR_STATUS:
      key = 'enum.emulatorPaneType.indicatorStatusPane';
      break;
    case EmulatorPaneType.TEST_INITIALIZING:
      key = 'enum.emulatorPaneType.testInitializing';
      break;
    case EmulatorPaneType.TCAS_PANE:
      key = 'enum.emulatorPaneType.tcasPane';
      break;
    default:
      key = 'enum.unknown';
  }
  return key;
};

export const getEmulatorComponentTypeKey = (type: EmulatorComponentType): string => {
  let key = '';
  switch (type) {
    case EmulatorComponentType.TEXT:
      key = 'enum.emulatorComponentType.text';
      break;
    case EmulatorComponentType.LIST:
      key = 'enum.emulatorComponentType.list';
      break;
    case EmulatorComponentType.LIST_ITEM:
      key = 'enum.emulatorComponentType.listItem';
      break;
    case EmulatorComponentType.BUTTON:
      key = 'enum.emulatorComponentType.button';
      break;
    case EmulatorComponentType.SIDE_MENU:
      key = 'enum.emulatorComponentType.sideMenu';
      break;
    case EmulatorComponentType.SIDE_MENU_ITEM:
      key = 'enum.emulatorComponentType.sideMenuItem';
      break;
    case EmulatorComponentType.TABLE:
      key = 'enum.emulatorComponentType.table';
      break;
    case EmulatorComponentType.TABLE_HEADER:
      key = 'enum.emulatorComponentType.tableHeader';
      break;
    case EmulatorComponentType.TABLE_BODY:
      key = 'enum.emulatorComponentType.tableBody';
      break;
    case EmulatorComponentType.TABLE_ROW:
      key = 'enum.emulatorComponentType.tableRow';
      break;
    case EmulatorComponentType.TABLE_ROW_ITEM:
      key = 'enum.emulatorComponentType.tableRowItem';
      break;
    case EmulatorComponentType.IMAGE:
      key = 'enum.emulatorComponentType.image';
      break;
    case EmulatorComponentType.BOOLEAN:
      key = 'enum.emulatorComponentType.boolean';
      break;
    case EmulatorComponentType.INDICATOR:
      key = 'enum.emulatorComponentType.indicator';
      break;
    case EmulatorComponentType.ROUND_INDICATOR:
      key = 'enum.emulatorComponentType.round_indicator';
      break;
    case EmulatorComponentType.RECTANGLE_INDICATOR:
      key = 'enum.emulatorComponentType.rectangle_indicator';
      break;
    case EmulatorComponentType.CROSS_INDICATOR:
      key = 'enum.emulatorComponentType.cross_indicator';
      break;
    case EmulatorComponentType.STYLE:
      key = 'enum.emulatorComponentType.style';
      break;
    case EmulatorComponentType.LIST_GAUGE:
      key = 'enum.emulatorComponentType.listGauge';
      break;
    case EmulatorComponentType.GAUGE:
      key = 'enum.emulatorComponentType.gauge';
      break;
    case EmulatorComponentType.TCAS:
      key = 'enum.emulatorComponentType.tcas';
      break;
    case EmulatorComponentType.TCAS_ROW:
      key = 'enum.emulatorComponentType.tcas_row';
      break;
    case EmulatorComponentType.TCAS_ITEM:
      key = 'enum.emulatorComponentType.tcas_item';
      break;
    default:
      key = 'enum.unknown';
  }
  return key;
};

export const getStatusKey = (status: EmulatorComponentStatus): string => {
  let statusKey = '';
  switch (status) {
    case EmulatorComponentStatus.FAULTED:
      statusKey = 'enum.emulatorComponentStatus.faulted';
      break;
    case EmulatorComponentStatus.DISABLED:
      statusKey = 'enum.emulatorComponentStatus.disabled';
      break;
    case EmulatorComponentStatus.EXTERNAL:
      statusKey = 'enum.emulatorComponentStatus.external';
      break;
    case EmulatorComponentStatus.EMPTY:
      statusKey = 'enum.emulatorComponentStatus.empty';
      break;
    case EmulatorComponentStatus.IDLE:
      statusKey = 'enum.emulatorComponentStatus.idle';
      break;
    case EmulatorComponentStatus.STANDBY:
      statusKey = 'enum.emulatorComponentStatus.standby';
      break;
    case EmulatorComponentStatus.MATCH:
      statusKey = 'enum.emulatorComponentStatus.match';
      break;
    case EmulatorComponentStatus.NOMATCH:
      statusKey = 'enum.emulatorComponentStatus.nomatch';
      break;
    case EmulatorComponentStatus.NOT_SUPPORTED:
      statusKey = 'enum.emulatorComponentStatus.not_supported';
      break;
    default:
      statusKey = 'enum.unknown';
  }
  return statusKey;
};

export const getAircraftLabel = (emulator: Emulator): string => {
  return getAircraftImageLabel(emulator.aircraftModel.toString());
};

export const getTriggersKey = (trigger: EmulatorTriggerType): string => {
  let key = '';
  switch (trigger) {
    case EmulatorTriggerType.NAVIGATE:
      key = 'enum.emulatorTriggerType.navigate';
      break;
    case EmulatorTriggerType.CHANGE_PAGE:
      key = 'enum.emulatorTriggerType.changePage';
      break;
    case EmulatorTriggerType.EXPAND_PANEL:
      key = 'enum.emulatorTriggerType.expandPanel';
      break;
    case EmulatorTriggerType.SCROLL_BAR:
      key = 'enum.emulatorTriggerType.scrollBar';
      break;
    case EmulatorTriggerType.SCROLL_ITEM:
      key = 'enum.emulatorTriggerType.scrollItem';
      break;
    case EmulatorTriggerType.CLICK_NOT_SUPPORTED:
      key = 'enum.emulatorTriggerType.clickNotSupported';
      break;
    default:
      key = 'enum.unknown';
  }
  return key;
};

export const getLessonTypeKey = (type: LessonType): string => {
  let key = '';
  switch (type) {
    case LessonType.EXPLORATORY:
    case LessonType.MONITORED:
    case LessonType.EVALUATION:
      key = 'enum.lessonType.' + type.toLowerCase();
      break;
    default:
      key = 'enum.unknown';
  }
  return key;
};

export const getImagePath = (emulator: Emulator): string => {
  return getAircraftImagePath(emulator.aircraftModel.toString());
};

export const getAircraftImagePath = (aircraft: string): string => {
  let filename = '';
  switch (aircraft) {
    case AircraftType.G_650:
      filename = 'g650.jpg';
      break;
    case AircraftType.G_550:
      filename = 'g550.jpg';
      break;
    case AircraftType.G_450:
      filename = 'g450.jpg';
      break;
    case AircraftType.UFO:
      filename = 'ufo.jpg';
      break;
  }
  return `/img/aircraft/${filename}`;
};

export const getAircraftImageLabel = (aircraft: string): string => {
  return AircraftType.getAircraftLabelByType(aircraft);
};

export const getDurationLabel = (date1: any, date2?: any): string => {
  let theDate: string = `${capitalize(i18n.t('common.from') as string)} ${moment(date1).format('L')}`;
  if (date2) {
    theDate += ` ${i18n.t('common.to')} ${moment(date2).format('L')}`;
  }
  return theDate;
};

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const stripSpecialCharacters = (s: string) => {
  return s.replace(/[^a-zA-Z ]/g, '');
};
