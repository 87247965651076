

















































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponentStatus, EmulatorComponentType } from '@/enums/emulator';

@Component({})
export default class CMCIndicatorStatus extends Vue {
  @Prop({ default: '' })
  private status!: string;

  @Prop({ default: '' })
  private type!: string;

  @Prop({ default: '' })
  private value!: any;

  @Prop({ default: '' })
  private setStyle: any;

  public isStatusExternal(): boolean {
    return this.status[0] === EmulatorComponentStatus.EXTERNAL;
  }

  public isStatusNoMatch(): boolean {
    return this.status[0] === EmulatorComponentStatus.NOMATCH;
  }

  public isCrossIndicator(): boolean {
    return this.type === EmulatorComponentType.CROSS_INDICATOR;
  }

  public addIndicatorsClass(): string {
    let indicatorClass!: string;
    switch (this.type) {
      case EmulatorComponentType.ROUND_INDICATOR:
        indicatorClass = ' cmc-round-indicator';
        break;
      case EmulatorComponentType.RECTANGLE_INDICATOR:
        indicatorClass = ' cmc-rectangle-indicator';
        break;
      case EmulatorComponentType.CROSS_INDICATOR:
        indicatorClass = ' cmc-cross-indicator';
        break;
      default:
        indicatorClass = '';
        break;
    }
    return this.getStatusesClass() + ' cmc-indicator-status' + indicatorClass + this.addCustomClass();
  }

  public addCustomClass(): string {
    if (this.setStyle.hasOwnProperty('class')) {
      return ' ' + this.setStyle.class;
    }
    return '';
  }

  public getStatusesClass(): string {
    if (this.isStatusExternal()) {
      return 'cmc-indicator-status-external';
    } else if (this.status[0] === EmulatorComponentStatus.EMPTY) {
      return 'cmc-indicator-status-empty';
    } else if (this.status[0] === EmulatorComponentStatus.IDLE) {
      return 'cmc-indicator-status-idle';
    } else if (this.status[0] === EmulatorComponentStatus.STANDBY) {
      return 'cmc-indicator-status-standby';
    } else if (this.status[0] === EmulatorComponentStatus.MATCH) {
      return 'cmc-indicator-status-match';
    } else if (this.isStatusNoMatch()) {
      return 'cmc-indicator-status-no-match';
    } else {
      const spanDisplay = this.value.toString() === 'true' ? 'cmc-status-valid' : 'cmc-status-invalid';
      return 'cmc-indicator-status-internal ' + spanDisplay;
    }
  }
}
