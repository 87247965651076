

































































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Emulator } from '@/models/EmulatorModels';
import { CourseTemplate } from '@/models/CoursesModels';
import AircraftType from '@/enums/user/aircraft';

const emulatorModule = namespace('EmulatorModule');

@Component({})
export default class AddEmulatorsForm extends Vue {
  public isLoading: boolean = false;
  public isSaving: boolean = false;
  public allEmulators: Emulator[] = [];
  public newSelectedEmulators: Emulator[] = [];

  public get tableHeaders() {
    return [
      {
        text: this.$t('emulator.emulators'),
        align: 'start',
        filterable: true,
        value: 'title',
      },
    ];
  }

  public searchText: string = '';

  @Prop({
    required: true,
  })
  public courseTemplate!: CourseTemplate;

  @emulatorModule.Action
  public fetchEmulatorsByIds!: (emulatorsIds: string[]) => Promise<void>;

  @emulatorModule.Action
  public fetchEmulatorsByModel!: (aircraft: AircraftType) => Promise<void>;

  @emulatorModule.Action
  public fetchEmulator!: (emulatorId: string) => Promise<void>;

  @emulatorModule.Getter
  public emulators!: Emulator[];

  @emulatorModule.Getter
  public selectedEmulators!: Emulator[];

  @emulatorModule.Getter
  public currentEmulator!: Emulator;

  public created(): void {
    this.isLoading = true;
    this.fetchEmulatorsByModel(this.courseTemplate.aircraftModel).then(() => {
      this.fetchEmulatorsByIds(this.courseTemplate.emulatorIds!).then(() => {
        this.newSelectedEmulators = this.selectedEmulators;
        this.isLoading = false;
        this.allEmulators = this.emulators;
      });
    });
  }

  @Emit('onSave')
  public onSaveClick(): string[] {
    this.isSaving = true;
    const emulatorIds = this.newSelectedEmulators.map((emulator) => {
      return emulator.id;
    });
    return emulatorIds;
  }

  @Emit('onCancel')
  public onCancelHandler(): void {
    return;
  }
}
