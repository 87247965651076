






























































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorComponentsMap } from '@/models/EmulatorModels';
import CMCLayoutSideMenu from '@/components/cmc/sidemenu/CMCLayoutSideMenu.vue';
import CMCExpandableMenu from '@/components/cmc/controls/expandableMenu/CMCExpandableMenu.vue';
import CMCScrollableMenu from '@/components/cmc/controls/scrollableMenu/CMCScrollableMenu.vue';
import { EmulatorComponentStatus } from '@/enums/emulator';
import { itemHasStatus } from '@/utils/common';

@Component({
  name: 'CMCFlightDeckEffectsPane',
  components: {
    CMCLayoutSideMenu,
    CMCExpandableMenu,
    CMCScrollableMenu,
  },
})
export default class CMCFlightDeckEffectsPane extends Vue {
  public columsNumber = 0;
  public height;
  @Prop({
    required: true,
  })
  private currentEmulatorPane!: EmulatorComponent;

  get heightClassName() {
    this.height = 'list-scroll overflow-y-auto pa-0';
    return this.height;
  }

  get listItems(): EmulatorComponentsMap {
    return this.currentEmulatorPane!.components!.list!.components!;
  }

  get listItemsLength(): number {
    return Object.entries(this.currentEmulatorPane!.components!.list!.components!).length;
  }

  get columnNumber(): number {
    Object.entries(this.currentEmulatorPane!.components!.list!.components!)
      .splice(0, 1)
      .map((item: [string, EmulatorComponent]) => {
        this.columsNumber = Object.entries(item[1].components!).length;
      });
    return this.columsNumber;
  }

  private itemIsFaulted(item: EmulatorComponent): boolean {
    return itemHasStatus(item, EmulatorComponentStatus.FAULTED);
  }

  private checkValues(obj, colName): boolean {
    return obj[colName];
  }

}
