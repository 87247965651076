import { DialogContentType } from '@/enums/globalUI';
import { UIDialog } from '@/models/UIModels';
import {ComponentFactory} from "@/components/componentFactory/ComponentFactory";

export class DialogComponentFactory implements ComponentFactory<DialogContentType> {
  public static getComponentByType(dialogContent: DialogContentType): string {
    return this.localInstance.getComponentByType(dialogContent);
  }

  public static getDialogByType(dialogContent: DialogContentType): UIDialog {
    return this.localInstance.getDialogByType(dialogContent);
  }

  private static localInstance = new DialogComponentFactory();

  public getComponentByType(dialogContent: DialogContentType): string {
    switch (dialogContent) {
      case DialogContentType.FIRST_LOGIN:
        return 'DialogContentFirstLogin';

      case DialogContentType.PROFILE:
        return 'ProfileForm';

      case DialogContentType.SELECTED_PROFILE:
        return 'ProfileForm';

      case DialogContentType.INACTIVITY:
        return 'DialogContentInactivity';

      case DialogContentType.LOGGED_OUT:
        return 'DialogContentLoggedOut';

      default:
        return 'DialogContentNone';

    }
  }

  public getDialogByType(dialogContent: DialogContentType): UIDialog {
    const dialogContentTypeString = this.getComponentByType(dialogContent);

    switch (dialogContent) {
      case DialogContentType.FIRST_LOGIN:
        return {
          component: dialogContentTypeString,
          width: 377,
          isPersistent: true,
        };

      case DialogContentType.PROFILE:
        return {
          component: dialogContentTypeString,
          width: 987,
          isPersistent: true,
        };

      case DialogContentType.SELECTED_PROFILE:
        return {
          component: dialogContentTypeString,
          width: 987,
          isPersistent: true,
        };

      case DialogContentType.INACTIVITY:
        return {
          component: dialogContentTypeString,
          width: 290,
          isPersistent: false,
        };

      case DialogContentType.LOGGED_OUT:
        return {
          component: dialogContentTypeString,
          width: 290,
          isPersistent: false,
        };

      default:
        return {
          component: dialogContentTypeString,
          width: 1,
          isPersistent: false,
        };
    }
  }
}
