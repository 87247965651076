



































import { UserRoleLine } from '@/models/UserModels';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import UserRoleType from '@/enums/user';
import RoleSelectionList from '@/components/user/roleSelection/RoleSelectionList.vue';

@Component({
  components: {
    RoleSelectionList,
  },
})
export default class UserEditRoleDialog extends Vue {
  public userRoleLines: UserRoleLine[] = [];

  @Prop()
  public pickedRoles!: { [index: string]: string[] } | null;

  @Prop({
    default: 'primary',
  })
  public color!: string;

  @Prop({
    default: 'fa-edit',
  })
  public icon!: string;

  @Prop({
    default: 'user.manager.setRoleDialogTitle',
  })
  public titleLabel!: string;

  @Prop({
    default: false,
  })
  public canBeEmpty!: boolean;

  @Prop({
    default: 'common.save',
  })
  public saveButtonLabel!: string;

  // @Emit('onRolePickedHandler')
  // public onRolePickedHandler() {
  //   return UserRoleType.convertUserRoleLinesToRoles(this.userRoleLines);
  // }

  @Emit()
  public onConfirmHandler() {
    return UserRoleType.convertUserRoleLinesToRoles(this.userRoleLines);
  }

  @Emit()
  public onCloseHandler() {
    return;
  }

  protected created() {
    if (this.pickedRoles) {
      this.userRoleLines = UserRoleType.convertRolesToUserRoleLines(this.pickedRoles);
    }
  }

  private get isAbleToSave(): boolean {
    if (!this.canBeEmpty) {
      return this.userRoleLines.length > 0;
    }
    return true;
  }
}
