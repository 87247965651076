


















































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'CMCStatus',
})
export default class CMCStatus extends Vue {
}
