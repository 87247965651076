




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponentsMap, EmulatorStyle } from '@/models/EmulatorModels';
import { itemHasStyles } from '@/utils/common';

@Component({
  name: 'CMCDataTable',
})

export default class CMCDataTable extends Vue {
  @Prop({ default: {} })
  private tableData!: EmulatorComponentsMap;

  public getStyles(styles: EmulatorStyle): any {
    return itemHasStyles(styles);
  }
}
