























import { Component, Vue } from 'vue-property-decorator';
import { Emulator, EmulatorComponent } from '@/models/EmulatorModels';
import { namespace } from 'vuex-class';
import EmulatorsPanesList from '@/components/editor/panesList/EmulatorPanesList.vue';
import { UIBreadcrumb } from '@/models/UIModels';
import BreadCrumbs from '@/components/shared/BreadCrumbs.vue';

const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    EmulatorsPanesList,
    BreadCrumbs,
  },
})
export default class EmulatorPanesLanding extends Vue {
  public isPaneCreationOpen: boolean = false;

  @emulatorModule.Getter
  public currentEmulator!: Emulator;

  @emulatorModule.Getter
  public isEmulatorPreviewOpen!: boolean;

  @emulatorModule.Getter
  public isLoadingEmulators!: boolean;

  @emulatorModule.Mutation
  public setPreviewOpen!: (isOpen: boolean) => void;

  @emulatorModule.Action
  public fetchEmulator!: (emulatorId: string) => Promise<void>;

  @emulatorModule.Action
  public chooseCurrentEmulatorPane!: (emulatorPane: EmulatorComponent) => void;

  protected async created() {
    await this.fetchEmulator(this.idEmulator);
    this.chooseCurrentEmulatorPane(this.currentEmulator!.panes!['1.0']);
  }

  get idEmulator(): string {
    return this.$route.params.id;
  }

  public get breadCrumbsItems(): UIBreadcrumb[] {
    return [
      {
        text: this.$t('emulator.emulators') as string,
        to: '/editor',
        exact: true,
      },
    ];
  }
}
