












































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorStyle } from '@/models/EmulatorModels';
import CMCStatus from '@/components/cmc/controls/status/CMCStatus.vue';
import CMCIndicatorStatus from '@/components/cmc/controls/cmcIndicatorStatus/CMCIndicatorStatus.vue';
import { itemHasStyles } from '@/utils/common';
import { EmulatorComponentType } from '@/enums/emulator';

@Component({
  components: {
    CMCIndicatorStatus,
    CMCStatus,
  },
})

export default class CMCIndicatorStatusPane extends Vue {
  @Prop({ required: true })
  private currentEmulatorPane!: EmulatorComponent;

  public getStyles(styles: EmulatorStyle): any {
    return itemHasStyles(styles);
  }

  public isIndicator(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.INDICATOR ||
      type === EmulatorComponentType.ROUND_INDICATOR ||
      type === EmulatorComponentType.CROSS_INDICATOR ||
      type === EmulatorComponentType.RECTANGLE_INDICATOR);
  }
}
