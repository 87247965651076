







































































































import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { DialogContentType, ImageType } from '@/enums/globalUI';
import RuleUtility from '@/utils/rules';
import { UpdateUserParams, UpdateUserProfile, UserProfile, User } from '@/models/UserModels';
import { UISnackBar } from '@/models/UIModels';
import ImageFileMixin from '@/mixins/ImageFileMixin.ts';
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';
import { mixins } from 'vue-class-component';

const auth = namespace('AuthModule');
const globalUI = namespace('GlobalUIModule');
const userModule = namespace('UserModule');

@Component({
  components: {
    ProfileAvatar,
  },
})
export default class ProfileForm extends mixins(ImageFileMixin) {
  @globalUI.Action
  public showSnackBar!: (snackBar: UISnackBar) => void;

  @globalUI.Mutation
  private setDialogVisible!: (isVisible: boolean) => void;

  @globalUI.Action
  private showDialogByContent!: (content: DialogContentType) => boolean;

  @userModule.Getter
  private selectedUserProfile!: UserProfile | null;

  @userModule.Getter
  public currentUser!: User;

  @userModule.Action
  private updateCurrentUser!: (updateUserParams: UpdateUserParams) => Promise<void>;

  @globalUI.Action
  private hideDialog!: () => void;

  @auth.Action
  private logout!: () => Promise<void>;

  private ruleUtility = RuleUtility;

  private valid: boolean = false;
  private isSaving: boolean = false;
  private isRemovingAvatar: boolean = false;

  // Data
  private firstName: string = '';
  private lastName: string = '';
  private company: string = '';

  protected created() {
    if (this.selectedUserProfile) {
      this.firstName = this.selectedUserProfile.firstName ? this.selectedUserProfile.firstName : '';
      this.lastName = this.selectedUserProfile.lastName ? this.selectedUserProfile.lastName : '';
      this.company = this.selectedUserProfile.company ? this.selectedUserProfile.company : '';
    }
  }

  private beforeDestroy(): void {
    this.setCurrentFile(null);
  }

  private async validateForm(): Promise<void> {
    this.valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();

    if (this.valid) {

      if (this.imageFile !== null) {
        const image = await this.uploadFile(ImageType.USER, this.currentUser.id);
      }

      const updateUserProfile: UpdateUserProfile = {
        firstName: this.firstName,
        lastName: this.lastName,
        company: this.company,
        isOnboardingDone: true,
        avatar: this.newAvatarUrl,
      };

      const updateUserParams: UpdateUserParams = {
        profile: updateUserProfile,
      };

      this.updateCurrentUser(updateUserParams).then(() => {
        this.setDialogVisible(false);
        this.isSaving = false;
      }).catch(() => {
        this.isSaving = false;
        this.showSnackBar(
          {
            color: 'error',
            text: 'globalUI.dialog.profile.error',
          },
        );
      });
    }
  }

  private get newAvatarUrl(): string | undefined {
    let url: string | undefined = '';
    if(this.isRemovingAvatar && this.selectedUserProfile?.avatar !== ''){
      this.deleteCurrentAvatar();
    }
    if (!this.isRemovingAvatar) {
      url = this.currentFile?.url ? this.currentFile?.url : undefined;
    }
    return url;
  }

  private saveProfile() {
    this.isSaving = true;
    this.validateForm();
  }

  private changePasswordButton() {
    this.setDialogVisible(false);
    this.$router.replace('/account/changePassword');
  }

  private getIsOnboardingDone() {
    return this.selectedUserProfile && this.selectedUserProfile.isOnboardingDone;
  }

  private cancelHandler() {
    // TODO: Consider extracting the logout method.
    this.logout().then(() => {
      this.$router.replace('/account/signin');
    });
    this.hideDialog();
  }

  private get hasAvatar(): boolean {
    return this.selectedUserProfile !== null
      && this.selectedUserProfile!.avatar !== undefined
      && this.selectedUserProfile!.avatar !== '';
  }

  private cancelCurrentFile(): void {
    this.setCurrentFile(null);
    this.isRemovingAvatar = true;
  }

  private deleteCurrentAvatar(): void {
    this.deleteFile(ImageType.USER, this.currentUser.id);
  }
}
