









































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponent } from '@/models/EmulatorModels';
import CMCLayoutSideMenu from '@/components/cmc/sidemenu/CMCLayoutSideMenu.vue';
import CMCExpandableMenu from '@/components/cmc/controls/expandableMenu/CMCExpandableMenu.vue';
import CMCScrollableMenu from '@/components/cmc/controls/scrollableMenu/CMCScrollableMenu.vue';
import { objHasKey } from '@/utils/common';

@Component({
  components: {
    CMCLayoutSideMenu,
    CMCExpandableMenu,
    CMCScrollableMenu,
  },
})
export default class CMCSecondaryMenuPane extends Vue {
  public height;
  private reportStatusHeader: string = '';
  private reportStatusBody: string = '';
  private hasReportStatus: boolean = false;

  get heightClassName() {
    this.height = 'list-scroll overflow-y-auto pa-0';
    return this.height;
  }

  @Prop({ required: true })
  private currentEmulatorPane!: EmulatorComponent;

  public emitExpandableMenu(e) {
    this.$nextTick(() => this.$emit('expandableMenu', e));
  }

  public emitReportStatus(component) {
    if (objHasKey(component, 'resetReportStatus')) {
      this.clearReportStatus();
      return false;
    }
    this.reportStatusBody = component.reportStatusBody.value;
    this.reportStatusHeader = component.reportStatusHeader.value;
    this.hasReportStatus = true;
  }

  private beforeDestroy(): void {
    this.clearReportStatus();
  }

  private clearReportStatus(): void {
    this.reportStatusBody = '';
    this.reportStatusHeader = '';
    this.hasReportStatus = false;
  }
}
