export enum EmulatorPaneType {
  MAIN_MENU = 'main_menu_pane',
  SECONDARY_MENU = 'secondary_menu_pane',
  END_OF_FLIGHT = 'end_of_flight_pane',
  PAGINATOR = 'paginator',
  PAGINATOR_LIST = 'paginator_list',
  FAN_STATUS_DU = 'du_fan_status_pane',
  FAN_STATUS_MAU = 'mau_fan_status_pane',
  FAULT = 'fault_pane',
  FDE = 'fde_pane',
  INFO = 'info_pane',
  FLIGHTDECKEFFECTS_NEW_PANE = 'FlightDeckEffects_New_Pane ',
  TABLE = 'table_pane',
  TABLE_STATUS = 'table_status_pane',
  DATA_AC_SYSTEM = 'data_ac_system_pane',
  INDICATOR_STATUS = 'indicator_status_pane',
  TCAS_PANE = 'tcas_pane',
  TEST_INITIALIZING = 'test_initializing',
  DATA_PRECONDITION_PANE = 'data_precondition_pane',
}

export enum EmulatorComponentType {
  TEXT = 'text',
  LIST = 'list',
  LIST_ITEM = 'list_item',
  LIST_TITLE = 'list_title',
  BUTTON = 'button',
  SIDE_MENU = 'side_menu',
  SIDE_MENU_ITEM = 'side_menu_item',
  TABLE = 'table',
  TABLE_HEADER = 'table_header',
  TABLE_BODY = 'table_body',
  TABLE_ROW = 'table_row',
  TABLE_ROW_ITEM = 'table_row_item',
  IMAGE = 'img',
  BOOLEAN = 'boolean',
  INDICATOR = 'indicator',
  ROUND_INDICATOR = 'round_indicator',
  RECTANGLE_INDICATOR = 'rectangle_indicator',
  CROSS_INDICATOR = 'cross_indicator',
  STYLE = 'style',
  LIST_GAUGE = 'list_gauge',
  GAUGE = 'gauge',
  TCAS = 'tcas',
  TCAS_ROW = 'tcas_row',
  TCAS_ITEM = 'tcas_item',
}

export enum EmulatorComponentStatus {
  DISABLED = 'disabled',
  FAULTED = 'faulted',
  EXTERNAL = 'external',
  EMPTY = 'empty',
  IDLE = 'idle',
  STANDBY = 'standby',
  MATCH = 'match',
  NOMATCH = 'nomatch',
  NOT_SUPPORTED = 'not_supported',
}

export enum EmulatorTriggerType {
  NAVIGATE = 'navigate',
  EXPAND_PANEL = 'expand_panel',
  CHANGE_PAGE = 'change_page',
  SCROLL_BAR = 'scroll_bar',
  SCROLL_ITEM = 'scroll_item',
  CLICK_NOT_SUPPORTED = 'click_not_supported',
  PREV = 'previous',
}

export enum EmulatorView {
  GRID = 'grid',
  LIST = 'list',
}

