





















































































































































































































































































import { namespace } from 'vuex-class';
import { UIDialog, UISnackBar } from '@/models/UIModels';
import {
  AddUserRolesParams,
  RemoveUserRolesParams,
  UpdateUserAircraftParams,
  UpdateUserRolesParams,
  User,
  UserProfile,
} from '@/models/UserModels';
import Loader from '@/components/shared/Loader.vue';
import { Component, Watch } from 'vue-property-decorator';
import UserDeleteDialog from '@/components/user/UserDeleteDialog.vue';
import { DialogComponentFactory } from '@/components/componentFactory/DialogComponentFactory';
import { DialogContentType, SearchType } from '@/enums/globalUI';
import UserRoleType from '@/enums/user';
import AircraftType from '@/enums/user/aircraft';
import UserCreateUserDialog from '@/components/user/UserCreateUserDialog.vue';
import PaginationFooter from '@/components/paginationFooter/PaginationFooter.vue';
import { SearchObject } from '@/models/GlobalModels';
import UserMixin from '@/mixins/UserMixin';
import UserEditRoleDialog from '@/components/user/UserEditRoleDialog.vue';
import { mixins } from 'vue-class-component';

const globalUI = namespace('GlobalUIModule');
const userModule = namespace('UserModule');
const searchModule = namespace('SearchModule');

@Component({
  components: {
    UserDeleteDialog,
    UserEditRoleDialog,
    UserCreateUserDialog,
    PaginationFooter,
    Loader,
  },
})
export default class UserManager extends mixins(UserMixin) {
  @userModule.Getter
  public userRoles!: { [index: string]: string[] };

  @userModule.Getter
  public isLoadingUserPage!: boolean;

  @userModule.Mutation
  public setSelectedUserId!: (userId: string) => void;

  @userModule.Action
  public fetchUserProfile!: (userId: string) => Promise<UserProfile>;

  @userModule.Action
  public enableUser!: (userId: string) => Promise<void>;

  @userModule.Action
  public disableUser!: (userId: string) => Promise<void>;

  @userModule.Action
  public updateUserRoles!: (params: UpdateUserRolesParams) => Promise<void>;

  @userModule.Action
  public updateUserAircraft!: (params: UpdateUserAircraftParams) => Promise<void>;

  @userModule.Action
  public deleteUser!: (userId: string) => Promise<void>;

  @userModule.Action
  public addUserRoles!: (params: AddUserRolesParams) => Promise<void>;

  @userModule.Action
  public removeUserRoles!: (params: RemoveUserRolesParams) => Promise<void>;

  @userModule.Mutation
  public setLoadingUserPage!: (isLoading: boolean) => void;

  @searchModule.Getter
  public searchItems!: User[];

  @searchModule.Getter
  public totalElements!: number;

  @searchModule.Getter
  public totalPages!: number;

  @searchModule.Getter
  public actualPage!: number;

  @searchModule.Getter
  public itemsPerPageOptions!: number;

  @userModule.Getter
  public itemsPerPageUsers!: number;

  @searchModule.Mutation
  public setActualPage!: (page: number) => void;

  @searchModule.Mutation
  public setItemsPerPage!: (itemsPerPage: number) => void;

  @userModule.Mutation
  public setItemsPerPageUsers!: (itemsPerPage: number) => void;

  @searchModule.Action
  public fetchItemsPerPage!: (searchType: SearchType) => Promise<void>;

  @searchModule.Action
  public searchItemsReq!: (search: SearchObject) => Promise<void>;

  @globalUI.Action
  public showSnackBar!: (snackBar: UISnackBar) => void;

  @globalUI.Action
  private showDialog!: (dialogUI: UIDialog) => void;

  private isLoadingUserManageForTheFirstTime = true;
  private isLoading = false;
  private selectedUsers: User[] = [];
  private searchTerm = '';
  private refreshIntervalId;
  private isUserDeleteDialogVisible = false;
  private isCreateUsersDialogVisible = false;
  private isSetUsersRoleDialogVisible = false;

  private userToBeDeleted: User | null = null;

  // Roles
  private roles = UserRoleType.getRoles();

  // Add Roles
  private rolesToBeAdded: { [index: string]: string[] } = {};
  private isAddUsersRoleDialogVisible = false;

  // Remove Roles
  private rolesToBeRemoved: { [index: string]: string[] } = {};
  private isRemoveUsersRoleDialogVisible = false;

  public created() {
    this.setItemsPerPage(this.itemsPerPageUsers);
    this.setLoadingUserPage(true);
    this.localFetchUserPage(true);
  }

  public beforeDestroy() {
    clearInterval(this.refreshIntervalId);
  }

  public get userCanBeSelected(): boolean {
    return this.isAdmin || this.isFleetChief;
  }

  public get selectItemPerPage() {
    return this.itemsPerPageUsers;
  }

  private updateItemsPerPage(newValue: number | string) {
    let itemsPerPage: number;
    if (newValue !== 'ALL') {
      itemsPerPage = typeof newValue === 'string' ? parseInt(newValue, 10) : newValue;
    } else {
      itemsPerPage = this.totalElements;
    }
    this.setItemsPerPageUsers(itemsPerPage);
    this.setItemsPerPage(this.itemsPerPageUsers);
    this.setActualPage(1);
    this.fetchData();
  }

  private nextPage() {
    if (this.actualPage + 1 <= this.totalPages) {
      this.setActualPage(this.actualPage + 1);
      this.fetchData();
    }
  }

  private previousPage() {
    if (this.actualPage - 1 >= 1) {
      this.setActualPage(this.actualPage - 1);
      this.fetchData();
    }
  }

  private async fetchData() {
    this.isLoadingUserManageForTheFirstTime = true;
    await this.fetchItemsPerPage(SearchType.USER).then(() => {
      this.isLoadingUserManageForTheFirstTime = false;
    });
  }

  private async localFetchUserPage(displaySnackbar: boolean) {
    this.setLoadingUserPage(true);
    this.fetchData().then(() => {
      this.setLoadingUserPage(false);
      this.isLoadingUserManageForTheFirstTime = false;
      if (displaySnackbar) {
        this.showSnackBar(
          {
            color: 'success',
            text: 'user.manager.fetchUserPageSuccess',
          },
        );
      }
    }).catch(() => {
      this.showSnackBar(
        {
          color: 'error',
          text: 'user.manager.fetchUserPageError',
        },
      );
    });
  }

  private canToggleUser(targetUserRoles: { [index: string]: string[] }): boolean {
    return UserRoleType.canToggleUser(this.userRoles, targetUserRoles);
  }

  private getAircraftLabel(type: string): string {
    return AircraftType.getAircraftLabelByType(type);
  }

  // Delete User Dialog
  private showDeleteUserDialog(user: User) {
    this.userToBeDeleted = user;
    this.isUserDeleteDialogVisible = true;
  }

  private proceedDeleteUserDialog() {
    if (this.userToBeDeleted) {
      this.deleteUser(this.userToBeDeleted.id).then(() => this.localFetchUserPage(true));
    }
    this.isUserDeleteDialogVisible = false;
  }

  private cancelDeleteUserDialog() {
    this.userToBeDeleted = null;
    this.isUserDeleteDialogVisible = false;
  }

  private getHeaders() {
    return [
      { text: this.$t('user.manager.studentIdLabel'), value: 'studentId' },
      { text: this.$t('user.manager.givenNameLabel'), value: 'givenName' },
      { text: this.$t('user.manager.emailLabel'), value: 'email' },
      { text: this.$t('user.manager.rolesLabel'), value: 'roles' },
      { text: this.$t('user.manager.enabledLabel'), value: 'enabled' },
      { value: 'profileExists' },
    ];
  }

  // TODO: Remove code duplication.
  private getActions() {
    const allOptions: any[] = [
      {
        title: this.$t('user.manager.actionSetRoles'),
        action: this.showSetRoleForSelectedUsersDialog,
        disable: this.selectedUsers.length !== 1,
      },
      {
        title: this.$t('user.manager.actionAddRoles'),
        action: this.showAddRoleForSelectedUsersDialog,
        disable: this.selectedUsers.length <= 1,
      },
      {
        title: this.$t('user.manager.actionRemoveRoles'),
        action: this.showRemoveRoleForSelectedUsersDialog,
        disable: this.selectedUsers.length <= 1,
      },
      {
        title: this.$t('user.manager.actionActivateUser'),
        action: this.enableSelectedUsers,
        disable: this.selectedUsers.length < 1,
      },
      {
        title: this.$t('user.manager.actionDeactivateUser'),
        action: this.disableSelectedUsers,
        disable: this.selectedUsers.length < 1,
      },
    ];

    if (UserRoleType.isAdmin(this.userRoles)) {
      return allOptions;
    } else if (UserRoleType.isFleetChief(this.userRoles)) {
      if (UserRoleType.getAssignableAircraftForUser(this.userRoles).length !== 0) {
        return allOptions;
      }
      return [
        allOptions[3], allOptions[4],
      ];
    }
    return [];
  }

  private openUserProfile(userId: string) {
    this.setSelectedUserId(userId);
    this.fetchUserProfile(userId).then(() => {
      this.showDialog({
        component: DialogComponentFactory.getComponentByType(DialogContentType.SELECTED_PROFILE),
        width: 987,
        isPersistent: false,
      });
    }).catch(() => {
      this.showSnackBar(
        {
          color: 'error',
          text: 'globalUI.dialog.firstLogin.error',
        },
      );
    });
  }

  // Roles
  private getDisplayRolesList(item, roles: { [index: string]: string[] }): string[] {
    return UserRoleType.getDisplayRolesList(roles);
  }

  private canDeleteUser() {
    return UserRoleType.isAdmin(this.userRoles);
  }

  private showSetRoleForSelectedUsersDialog() {
    this.isSetUsersRoleDialogVisible = true;
  }

  private proceedSetRoleForSelectedUsersDialog(roles: { [index: string]: string[] }) {
    this.setRoleForSelectedUsers(roles);
    this.isSetUsersRoleDialogVisible = false;
  }

  private cancelSetRoleForSelectedUsersDialog() {
    this.isSetUsersRoleDialogVisible = false;
  }

  private async setRoleForSelectedUsers(roles: { [index: string]: string[] }) {
    for (const selectedUser of this.selectedUsers) {

      await this.changeUsersAircraft(selectedUser, roles);

      this.updateUserRoles({
        userId: selectedUser.id,
        roles,
      }).then(() => {
        this.localFetchUserPage(false);
        this.showSnackBar(
          {
            color: 'success',
            text: 'user.manager.updateRoleSuccess',
          },
        );
      }).catch(() => {
        this.localFetchUserPage(false);
        this.showSnackBar(
          {
            color: 'error',
            text: 'user.manager.updateRoleError',
          },
        );
      });
    }

    await this.localFetchUserPage(true);
  }

  private changeUserState(userId: string, enable: boolean) {
    if (enable) {
      this.enableUser(userId).then(() => this.localFetchUserPage(true));
    } else {
      this.disableUser(userId).then(() => this.localFetchUserPage(true));
    }
  }

  // Enable & Disable
  private async disableSelectedUsers() {
    for (const selectedUser of this.selectedUsers) {
      await this.disableUser(selectedUser.id);
    }

    await this.localFetchUserPage(true);
  }

  private async enableSelectedUsers() {
    for (const selectedUser of this.selectedUsers) {
      await this.enableUser(selectedUser.id);
    }

    await this.localFetchUserPage(true);
  }

  // Add Roles Dialog
  private showAddRoleForSelectedUsersDialog() {
    this.isAddUsersRoleDialogVisible = true;
  }

  private hideAddRoleForSelectedUsersDialog() {
    this.isAddUsersRoleDialogVisible = false;
  }

  private addRoleConfirmHandler(roles: { [index: string]: string[] }) {
    this.addRolesForSelectedUsers(roles);
    this.hideAddRoleForSelectedUsersDialog();
  }

  private addRoleCancelHandler() {
    this.hideAddRoleForSelectedUsersDialog();
  }

  @Watch('searchTerm')
  private async searchUser() {
    if (this.searchTerm.length !== 0) {
      this.isLoading = true;
      const searchItem: SearchObject = {
        type: SearchType.USER,
        param: this.searchTerm,
      };
      await this.searchItemsReq(searchItem)
        .then(() => this.isLoading = false);
    } else {
      this.isLoading = false;
    }
  }

  private async addRolesForSelectedUsers(roles: { [index: string]: string[] }) {
    const userIds: string[] = [];
    this.selectedUsers.forEach((user) => {
      userIds.push(user.id);

      this.changeUsersAircraft(user, roles);
    });

    this.addUserRoles({
      userIds,
      roles,
    }).then(() => {
      this.localFetchUserPage(false);
      this.showSnackBar(
        {
          color: 'success',
          text: 'user.manager.updateRoleSuccess',
        },
      );
      this.rolesToBeAdded = {};
    }).catch(() => {
      this.localFetchUserPage(false);
      this.showSnackBar(
        {
          color: 'error',
          text: 'user.manager.updateRoleError',
        },
      );
      this.rolesToBeAdded = {};
    });

    await this.localFetchUserPage(true);
  }

  // Remove Roles Dialog
  private showRemoveRoleForSelectedUsersDialog() {
    this.isRemoveUsersRoleDialogVisible = true;
  }

  private hideRemoveRoleForSelectedUsersDialog() {
    this.isRemoveUsersRoleDialogVisible = false;
  }

  private removeRoleConfirmHandler(roles: { [index: string]: string[] }) {
    this.removeRolesForSelectedUsers(roles);
    this.hideRemoveRoleForSelectedUsersDialog();
  }

  private removeRoleCancelHandler() {
    this.hideRemoveRoleForSelectedUsersDialog();
  }

  private async removeRolesForSelectedUsers(roles: { [index: string]: string[] }) {
    const userIds: string[] = [];
    this.selectedUsers.forEach((user) => {
      userIds.push(user.id);
    });

    this.removeUserRoles({
      userIds,
      roles,
    }).then(() => {
      this.localFetchUserPage(false);
      this.showSnackBar(
        {
          color: 'success',
          text: 'user.manager.updateRoleSuccess',
        },
      );
      this.rolesToBeRemoved = {};
    }).catch(() => {
      this.localFetchUserPage(false);
      this.showSnackBar(
        {
          color: 'error',
          text: 'user.manager.updateRoleError',
        },
      );
      this.rolesToBeRemoved = {};
    });

    await this.localFetchUserPage(true);

  }

  private createUserCancelHandler() {
    this.isCreateUsersDialogVisible = false;
    this.localFetchUserPage(true);
  }

  private async clearAll(): Promise<void> {
    this.searchTerm = '';
    this.selectedUsers = [];
    this.isLoadingUserManageForTheFirstTime = true;
    await this.localFetchUserPage(false);
    this.isLoadingUserManageForTheFirstTime = false;
  }

  private async changeUsersAircraft(selectedUser: User, roles: { [index: string]: string[] }) {
    let aircraftIds: string[] = [];

    if(roles[UserRoleType.ADMIN] && roles[UserRoleType.ADMIN].length > 0) {
      aircraftIds = [...roles[UserRoleType.ADMIN].map((aircraft: string) => aircraft + '-base')];
    }

    if(roles[UserRoleType.FLEET_CHIEF] && roles[UserRoleType.FLEET_CHIEF].length > 0) {
      aircraftIds = [...roles[UserRoleType.FLEET_CHIEF].map((aircraft: string) => aircraft + '-base')];
    }

    if(roles[UserRoleType.INSTRUCTOR] && roles[UserRoleType.INSTRUCTOR].length > 0) {
      aircraftIds = aircraftIds.concat([...roles[UserRoleType.INSTRUCTOR].map((aircraft: string) => aircraft + '-base')]);
    }

    if(roles[UserRoleType.CLIENT] && roles[UserRoleType.CLIENT].length > 0) {
      aircraftIds = aircraftIds.concat([...roles[UserRoleType.CLIENT].map((aircraft: string) => aircraft + '-base')]);
    }

    aircraftIds = aircraftIds.filter((item, pos) => aircraftIds.indexOf(item) == pos);

    await this.updateUserAircraft({
      userId: selectedUser.id,
      privateEmulatorIds: aircraftIds,
    });
  }
}
