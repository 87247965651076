






























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { EmulatorTrigger } from '@/models/EmulatorModels';
import ResizableAddItemCard from '@/components/shared/ResizableAddItemCard.vue';
import EmulatorTriggerForm from '@/components/editor/forms/complexListControl/EmulatorTriggerForm.vue';
import ListItemCardTitle from '@/components/shared/ListItemCardTitle.vue';
import { getTriggersKey } from '@/utils/labels_utils';
import { EmulatorTriggerType } from '@/enums/emulator';

@Component({
  components: {
    ResizableAddItemCard,
    EmulatorTriggerForm,
    ListItemCardTitle,
  },
})
export default class EmulatorTriggersList extends Vue {
  public triggers: EmulatorTrigger[] = [];

  public isTriggerFormOpen: boolean = false;
  public editedTrigger: EmulatorTrigger | null = null;
  public index: number | null = null;

  @Prop()
  public readonly value!: EmulatorTrigger[];

  @Emit('input')
  public emitValue(): EmulatorTrigger[] {
    return this.triggers;
  }

  public onAddItemClick(): void {
    this.isTriggerFormOpen = true;
  }

  public onAddItemHandler(trigger: EmulatorTrigger): void {
    if (this.index !== null) {
      this.triggers[this.index] = trigger;
      this.index = null;
      this.editedTrigger = null;
    } else {
      this.triggers.push(trigger);
    }

    this.isTriggerFormOpen = false;
    this.emitValue();
  }

  public onCloseHandler() {
    this.isTriggerFormOpen = false;
  }

  public onDeleteTriggerClick(index: number) {
    this.triggers.splice(index, 1);
  }

  public onCardClick(trigger: EmulatorTrigger, index: number): void {
    this.editedTrigger = trigger;
    this.index = index;
    this.isTriggerFormOpen = true;
  }

  public getTitleTrigger(type: EmulatorTriggerType): string {
    return this.$t(getTriggersKey(type)) as string;
  }

  protected created(): void {
    this.triggers = this.value;
  }
}
