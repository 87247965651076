












import { Vue, Component, Prop } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorStyle } from '@/models/EmulatorModels';
import { itemHasStyles } from '@/utils/common';

@Component({})
export default class CMCOrderedListMenuItem extends Vue {
  @Prop()
  private component!: EmulatorComponent;

  public getStyles(styles: EmulatorStyle): any {
    return itemHasStyles(styles);
  }
}
