





































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { EmulatorComponent } from '@/models/EmulatorModels';
import { namespace } from 'vuex-class';

const emulatorModule = namespace('EmulatorModule');

@Component({})
export default class CMCTestInitializing extends Vue {
  @emulatorModule.Action
  public chooseCurrentEmulatorPaneById!: (id: string) => void;

  @Prop({
    required: true,
  })
  private currentEmulatorPane!: EmulatorComponent;

  private counter: number = 10;
  private stopCounting: boolean = false;

  private counting(): void {
    if (this.counter > 0 && !this.stopCounting) {
      setTimeout(() => {
        this.counter -= 1;
        this.counting();
      }, 1000);
    }
  }

  @Watch('counter')
  private onCounterChange(): void {
    if (this.counter === 0) {
      this.chooseCurrentEmulatorPaneById(this.currentEmulatorPane.components!.targetPane.value);
    }
  }

  private created(): void {
    this.counting();
  }

  private beforeDestroy(): void {
    this.stopCounting = true;
  }
}
