
















































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorStyle } from '@/models/EmulatorModels';
import CMCDataTable from '@/components/cmc/controls/CMCDataTable.vue';
import { itemHasStyles } from '@/utils/common';
import { EmulatorComponentType } from '@/enums/emulator';
import CMCIndicatorStatus from '@/components/cmc/controls/cmcIndicatorStatus/CMCIndicatorStatus.vue';

@Component({
  components: {
    CMCDataTable,
    CMCIndicatorStatus,
  },
})
export default class CMCTableStatusPane extends Vue {
  private limitColumns: number = 9;
  @Prop({ required: true })
  private currentEmulatorPane!: EmulatorComponent;

  public getStyles(styles: EmulatorStyle): any {
    return itemHasStyles(styles);
  }

  public isTable(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE);
  }

  public isTableHeader(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE_HEADER);
  }

  public isTableBody(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE_BODY);
  }

  public isRowItem(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE_ROW_ITEM);
  }

  public isIndicator(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.INDICATOR ||
      type === EmulatorComponentType.ROUND_INDICATOR ||
      type === EmulatorComponentType.CROSS_INDICATOR ||
      type === EmulatorComponentType.RECTANGLE_INDICATOR);
  }

  public isText(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TEXT);
  }

  public getColSpan(style: EmulatorStyle): number | null {
    return this.getSpanValue('columnSpan', style)
  }

  public getRowSpan(style: EmulatorStyle): number | null {
    return this.getSpanValue('rowSpan', style)
  }

  private getSpanValue(property: string, style: EmulatorStyle): number | null {
    const css = style?.css;
    return css != undefined ? css[property] : null;
  }
}
