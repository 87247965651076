

































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ResizableAddItemCard extends Vue {
  @Prop({
    default: false,
  })
  public dense!: boolean;

  @Prop()
  public label!: string;

  @Prop()
  public disabled!: boolean;

  @Prop({
    default: false,
  })
  public isHorizontal!: boolean;

  @Emit('clickHandler')
  public clickHandler(): void {
    return;
  }
}
