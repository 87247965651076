






import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';

const globalUI = namespace('GlobalUIModule');

@Component
export default class DialogContentNone extends Vue {

  @globalUI.Mutation
  public setDialogVisible!: (isVisible: boolean) => void;

}
