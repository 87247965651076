



































































import { Component, Prop, Emit } from 'vue-property-decorator';
import { Emulator } from '@/models/EmulatorModels';
import { getImagePath } from '@/utils/labels_utils';
import LessonsList from '@/components/coursesTemplates/lessons/LessonsList.vue';
import UserMixin from '@/mixins/UserMixin';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    LessonsList,
  },
})
export default class CourseEmulatorsListItem extends mixins(UserMixin) {
  public isActive: boolean = false;

  @Prop({ required: true })
  public emulator!: Emulator;

  @Prop({ default: false })
  public canEdit!: boolean;

  @Prop({ default: false })
  public canBeActivated!: boolean;

  @Prop({ default: false })
  public isActivated!: boolean;

  @Prop({ default: false })
  public isUserProfileView!: boolean;

  @Prop({ default: '' })
  public courseId!: string;

  public getImagePath(emulator: Emulator): string {
    return getImagePath(emulator);
  }

  @Emit('onActivateEmulator')
  public activateEmulator(): string {
    return this.emulator.id;
  }

  @Emit('onDeactivateEmulator')
  public deactivateEmulator(): string {
    return this.emulator.id;
  }

  @Emit('playEmulatorOnClick')
  public playEmulatorOnClick(lessonEmulator: any): void {
    return lessonEmulator;
  }

  private created(): void {
    this.isActive = this.isActivated;
  }

  private toggleActivation(): void {
    this.isActive = !this.isActive;
    this.isActive ? this.activateEmulator() : this.deactivateEmulator();
  }

  private get isClientActionDisabled(): boolean {
    return this.isUserProfileView && this.isClient && !this.isActivated;
  }

}
