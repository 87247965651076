export default class PasswordUtility {
  public static generateCreateUsersTextAreaString(): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZc0123456789';
    const caps = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const small = 'abcdefghijklmnopqrstuvwxyz';
    const digit = '0123456789';
    const charactersLength = characters.length;
    result += caps.charAt(Math.floor(Math.random() * caps.length));
    result += small.charAt(Math.floor(Math.random() * small.length));
    result += digit.charAt(Math.floor(Math.random() * digit.length));

    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    const a = result.split('');
    const n = a.length;

    for (let i = n - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const tmp = a[i];
      a[i] = a[j];
      a[j] = tmp;
    }

    return a.join('');
  }
}
