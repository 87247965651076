

























































import { Component, Prop, Vue } from 'vue-property-decorator';
import CMCLayoutSideMenu from '@/components/cmc/sidemenu/CMCLayoutSideMenu.vue';
import CMCFanStatus from '@/components/cmc/controls/CMCFanStatus.vue';
import CMCCharacterProgressBar from '@/components/cmc/controls/CMCCharacterProgressBar.vue';

interface Nic {
  no: number;
  designation: string;
  status: boolean;
  active: boolean;
}

@Component({
  components: {
    CMCLayoutSideMenu,
    CMCCharacterProgressBar,
    CMCFanStatus,
  },
})
export default class CMCFanDataBlock extends Vue {
  @Prop({})
  private nic1!: Nic;

  @Prop({})
  private nic2!: Nic;
}
