
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CMCFanStatus extends Vue {

  @Prop({ default: false })
  private active!: boolean;

  @Prop({ default: '' })
  private label!: string;

  @Prop({ default: '' })
  private designationFan!: string;
}
