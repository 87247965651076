import { Component, Vue } from 'vue-property-decorator';
import { EmulatorVar } from '@/models/EmulatorModels';
import { namespace } from 'vuex-class';

const emulatorModule = namespace('EmulatorModule');

@Component
class ViewConditionsMixin extends Vue {

  @emulatorModule.Getter
  public currentEmulatorState!: EmulatorVar[];

  @emulatorModule.Action
  public changeCurrentState!: (state: EmulatorVar[]) => Promise<void>;

  public evaluateViewConditions(viewConditions: EmulatorVar[]): boolean {

    let stateLoop: EmulatorVar | undefined;
    let canDisplay: boolean = true;
    viewConditions.map((item: EmulatorVar) => {

      stateLoop = this.currentEmulatorState.find((obj: EmulatorVar) => {
        return obj.name === item.name;
      });
      if (stateLoop?.value !== item.value) {
        canDisplay = false;
      }
    });

    return canDisplay;
  }

  public returnViewCondition(name: string): EmulatorVar | undefined {
    return this.currentEmulatorState.find((obj: EmulatorVar) => {
      return obj.name === name;
    });
  }

  public changeViewConditions(name: string, value: any): void {
    this.currentEmulatorState.forEach(async (obj: EmulatorVar, index) => {
      if (name === obj.name) {
        const newState: EmulatorVar[] = this.currentEmulatorState;
        newState[index] = { name, value };
        await this.changeCurrentState(newState);
      }
    });
  }

}

export default ViewConditionsMixin;
