import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { NavigationPayload } from '@/models/NavigationModels';
import { fetchAllNavigationReport } from '@/utils/common';

@Module({ namespaced: true, name: 'navigationReportModule' })
export default class NavigationReportModule extends VuexModule {
  private $isLoadingNavigationReport: boolean = false;
  private $navigationReport: NavigationPayload[] = [];

  public get isLoadingNavigationReport(): boolean {
    return this.$isLoadingNavigationReport;
  }

  public get navigationReport(): NavigationPayload[] {
    return this.$navigationReport;
  }

  @Mutation
  public setLoadingNavigationReport(isLoading: boolean) {
    this.$isLoadingNavigationReport = isLoading;
  }

  @Mutation
  public addNavigationItem(navItem: NavigationPayload) {
    this.$navigationReport.push(navItem);
  }

  @Mutation
  public emptyNavigationReport() {
    this.$navigationReport = [];
  }

  @Mutation
  public setNavigationReport(navigationReport: NavigationPayload[]) {
    this.$navigationReport = navigationReport;
  }

  @Action({ commit: 'addNavigationItem' })
  public async sendNavigationReportPayload(stateData: NavigationPayload) {
    if (!process.env.VUE_APP_STATIC_DATA) {
      await axios.post('/sessionAction', stateData);
    } else {
      await new Promise((resolve) => {
        resolve(stateData as any);
      });
    }
    return stateData;
  }

  @Action({ commit: 'setNavigationReport' })
  public async fetchNavigationReport(sessionId: string, useLocalJSON?: boolean) {
    let navigationReport: NavigationPayload[];
    const isMocked: string = process.env.VUE_APP_STATIC_DATA || 'false';
    await this.context.commit('setLoadingNavigationReport', true);
    if (isMocked === 'true' || useLocalJSON) {
      navigationReport = await fetchAllNavigationReport();
      await this.context.commit('setLoadingNavigationReport', false);
    } else {
      const { data } = await axios.get(`/sessionAction?sessionId=${sessionId}&sort=creationDate`);
      navigationReport = data.content;
    }
    await this.context.commit('setLoadingNavigationReport', false);
    return navigationReport;
  }
}
