
































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorStyle } from '@/models/EmulatorModels';
import { EmulatorComponentType } from '@/enums/emulator';
import { itemHasStyles } from '@/utils/common';
import CMCDataGauge from '@/components/cmc/controls/CMCDataGauge.vue';
import CMCDataTable from '@/components/cmc/controls/CMCDataTable.vue';
import CMCIndicatorStatus from '@/components/cmc/controls/cmcIndicatorStatus/CMCIndicatorStatus.vue';

@Component({
  components: {
    CMCIndicatorStatus,
    CMCDataGauge,
    CMCDataTable,
  },
})

export default class CMCDataAcSystemPane extends Vue {
  private limitColumns: number = 6;
  @Prop({ required: false })
  private currentEmulatorPane!: EmulatorComponent;

  public getStyles(styles: EmulatorStyle): any {
    return itemHasStyles(styles);
  }

  public isListItem(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.LIST_ITEM);
  }

  public isTable(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE);
  }

  public isTableHeader(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE_HEADER);
  }

  public isTableBody(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE_BODY);
  }

  public isText(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TEXT);
  }

  public isRowItem(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE_ROW_ITEM);
  }

  public isIndicator(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.INDICATOR ||
      type === EmulatorComponentType.ROUND_INDICATOR ||
      type === EmulatorComponentType.CROSS_INDICATOR ||
      type === EmulatorComponentType.RECTANGLE_INDICATOR);
  }

}
