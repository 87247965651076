















































import { Component, Vue, Watch } from 'vue-property-decorator';

import Header from '@/components/shared/Header.vue';
import { UIDialog, UISnackBar } from '@/models/UIModels';
import { namespace } from 'vuex-class';
import GlobalDialog from '@/components/globalUI/GlobalDialog.vue';
import { DialogContentType } from '@/enums/globalUI';
import { DialogComponentFactory } from '@/components/componentFactory/DialogComponentFactory';
import { User } from '@/models/UserModels';

const auth = namespace('AuthModule');
const globalUI = namespace('GlobalUIModule');
const userModule = namespace('UserModule');

@Component({
  components: {
    GlobalDialog,
    Header,
  },
  name: 'App',
})

export default class App extends Vue {
  @auth.Getter
  public isAuthenticated!: boolean;

  @userModule.Getter
  public currentUser!: User;

  @globalUI.Getter
  public dialog!: UIDialog;

  @globalUI.Getter
  public isDialogVisible!: boolean;

  @globalUI.Getter
  public snackBar!: UISnackBar;

  @globalUI.Getter
  public isSnackBarVisible!: boolean;

  @globalUI.Getter
  public inactivityBaseAmount!: number;

  @globalUI.Getter
  public inactivityThreshold!: number;

  @globalUI.Mutation
  public setSnackBarVisible!: (isVisible: boolean) => void;

  @globalUI.Mutation
  public setInactivityInterval!: (value: any) => void;

  @globalUI.Action
  public hideDialog!: () => void;

  @globalUI.Action
  public hideSnackBar!: () => void;

  @globalUI.Action
  public showDialogByContent!: (content: DialogContentType) => Promise<boolean>;

  @globalUI.Action
  private logoutHandler!: () => Promise<void>;

  private inactivityRemainingSeconds: number = 1800;
  private inactivityInterval: any = null;

  public get isDialogVisibleInApp(): boolean {
    return this.isDialogVisible;
  }

  public get isSnackBarVisibleInApp(): boolean {
    return this.isSnackBarVisible;
  }

  public set isSnackBarVisibleInApp(isVisible: boolean) {
    this.setSnackBarVisible(isVisible);
  }

  public get hasBackground(): boolean {
    return !this.isAuthenticated || this.$route.name === 'contactus';
  }

  public created() {
    this.inactivityInterval = setInterval(() => {
      if (this.isAuthenticated) {
        this.inactivityRemainingSeconds--;
        if (!this.isDialogVisible) {
          if (this.inactivityRemainingSeconds < this.inactivityThreshold) {
            this.showDialogByContent(DialogContentType.INACTIVITY);
          }
        } else if (this.isDialogVisible && this.dialog.component ===
          DialogComponentFactory.getComponentByType(DialogContentType.INACTIVITY)) {
          if (this.inactivityRemainingSeconds <= 0) {
            this.showDialogByContent(DialogContentType.LOGGED_OUT);
            this.logoutHandler();
          }
        }
      }
    }, 1000);

    document.addEventListener('click', () => {
      this.inactivityRemainingSeconds = this.inactivityBaseAmount;
    });
  }

  public destroyed() {
    clearInterval(this.inactivityInterval);
  }

  @Watch('isSnackBarVisible')
  public isSnackBarVisibleHandler(isVisible: boolean) {
    this.isSnackBarVisibleInApp = isVisible;
  }
}
