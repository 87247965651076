import { Component, Vue } from 'vue-property-decorator';
import { CAEImage,ImagePayload } from '@/models/GlobalModels';
import { namespace } from 'vuex-class';
import { ImageType } from '@/enums/globalUI';

const mediaImageModule = namespace('ImageModule');

@Component({})
export default class ImageFileMixin extends Vue {

  @mediaImageModule.Getter
  public currentFile!: CAEImage | null;

  @mediaImageModule.Mutation
  public setCurrentFile!: (data: CAEImage | null) => Promise<void>;

  @mediaImageModule.Action
  public uploadImage!: (imagePayload: ImagePayload) => Promise<void>;
  
  @mediaImageModule.Action
  public deleteImage!: (imagePayload: ImagePayload) => Promise<void>;

  public imageFile: any = null;

  public fileRules = [
    (value) => !value || value.size < 4000000 || '#Avatar size should be less than 2 MB!',
  ];

  public async uploadFile(type: ImageType, id: string): Promise<void> {
    const data = new FormData();
    data.append('file', this.imageFile);
    data.append('type', type);
    data.append('id', id);
    const payload: ImagePayload = { data, type, id };
    await this.uploadImage(payload);
    this.imageFile = null;
  }
  
  public async deleteFile(type: ImageType, id: string): Promise<void> {
    const data = new FormData();
    const payload: ImagePayload = { data, type, id };
    await this.deleteImage(payload)
  }
}
