















































































































































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { UIDialog, UISnackBar } from '@/models/UIModels';
import { DialogComponentFactory } from '@/components/componentFactory/DialogComponentFactory';
import { DialogContentType } from '@/enums/globalUI';
import { User, UserProfile } from '@/models/UserModels';
import LanguageSwitch from '@/components/shared/LanguageSwitch.vue';
import UserMixin from '@/mixins/UserMixin.ts';
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';
import { mixins } from 'vue-class-component';

const auth = namespace('AuthModule');
const globalUI = namespace('GlobalUIModule');
const userModule = namespace('UserModule');

@Component({
  components: {
    LanguageSwitch,
    ProfileAvatar,
  },
})
export default class Header extends mixins(UserMixin) {
  get publicPath(): string {
    return process.env.BASE_URL as string;
  }

  get title() {
    return process.env.VUE_APP_TITLE;
  }

  get isProd(): boolean {
    return process.env.NODE_ENV === 'production';
  }

  public get isMiniState() {
    return this.isMini || this.$vuetify.breakpoint.smAndDown;
  }

  @auth.Getter
  public isAuthenticated!: boolean;

  @userModule.Getter
  public userRoles!: { [index: string]: string[] };

  @userModule.Mutation
  public setSelectedUserId!: (userId: string) => void;

  @userModule.Getter
  public currentUserProfile!: UserProfile;

  @userModule.Getter
  public currentUser!: User;

  @globalUI.Action
  public showDialogByContent!: (content: DialogContentType) => void;

  @globalUI.Action
  public showSnackBar!: (snackBar: UISnackBar) => void;

  @userModule.Action
  public fetchUserProfile!: (userId: string) => Promise<UserProfile>;

  @userModule.Action
  public fetchCurrentUserProfile!: () => Promise<UserProfile>;

  @globalUI.Action
  private showDialog!: (dialogUI: UIDialog) => void;

  @globalUI.Action
  private logoutHandler!: () => Promise<void>;

  private isMini: boolean = false;
  private drawer: boolean = true;

  private created() {

    if (!this.isGuest) {
      this.fetchCurrentUserProfile().then((userProfile: UserProfile) => {
        if (!userProfile || !userProfile.isOnboardingDone) {
          this.showDialogByContent(DialogContentType.FIRST_LOGIN);
        }
      }).catch(() => {
        this.showSnackBar(
          {
            color: 'error',
            text: 'globalUI.dialog.firstLogin.error',
          },
        );
      });
    }
  }

  private openProfile() {
    if (!this.isGuest) {
      this.setSelectedUserId(this.currentUser.id);
      this.fetchUserProfile(this.currentUser.id).then(() => {
        this.showDialog({
          component: DialogComponentFactory.getComponentByType(DialogContentType.PROFILE),
          width: 987,
          isPersistent: false,
        });
      }).catch(() => {
        this.showSnackBar(
          {
            color: 'error',
            text: 'globalUI.dialog.firstLogin.error',
          },
        );
      });
    }
  }

  private displayFirstAndLastName(): string {
    if (this.currentUserProfile && this.currentUserProfile.firstName) {
      return this.currentUserProfile.firstName + ' ' + this.currentUserProfile.lastName;
    }
    return '';
  }
}
