var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mau-fan-status fill-height py-0 no-gutters align-content-space-between justify-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.currentEmulatorPane.components.title)?_c('v-row',{attrs:{"justify":"space-around","fluid":"","no-gutters":"","dense":""}},[_c('h1',{staticClass:"cmc-color-title"},[_vm._v(" "+_vm._s(_vm.currentEmulatorPane.components.title.value)+" ")]),_c('h1',{staticClass:"cmc-color-title text-right"},[_vm._v(" "+_vm._s(_vm.currentEmulatorPane.components.numberPage.value)+" ")])]):_vm._e(),_c('v-row',{staticClass:"mau-fan-status__legend no-gutters"},[_c('v-col',{staticClass:"al",attrs:{"cols":"6"}},[_c('p',{staticClass:"text-center d-flex grow align-center justify-center mt-2 mb-1"},[_c('span',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.legend.notFaulted.value))]),_c('span',[_c('img',{attrs:{"src":require('@/assets/fan/cmc_fan_faulted_green.png'),"alt":""}})])])]),_c('v-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"text-center d-flex grow align-center justify-center mt-2 mb-1"},[_c('span',[_vm._v(_vm._s(_vm.legend.faulted.value))]),_c('span',[_c('img',{attrs:{"src":require('@/assets/fan/cmc_fan_faulted_red.png'),"alt":""}})])])])],1),_c('v-row',{staticClass:"no-gutters px-9"},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"mau-fan-status__column-title cmc-color-title mb-3"},[_vm._v(" "+_vm._s(_vm.labels.designation.value)+_vm._s(_vm.labels.label1.value)+" ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"mau-fan-status__column-title cmc-color-title"},[_vm._v(" "+_vm._s(_vm.labels.designation.value)+_vm._s(_vm.labels.label2.value)+" ")])]),_vm._l((_vm.currentFans),function(fan,index){return _c('CMCFanDataBlock',{key:index,attrs:{"nic1":{
            noNic: _vm.labels.label1.value,
            designation: _vm.labels.designation.value,
            statusOk: _vm.labels.statusOk.value,
            statusNotOk: _vm.labels.statusNotOk.value,
            designationFan: _vm.labels.designationFan.value,
            noFan: fan.components.blockNumber.value,
            status: fan.components.nic1OK.value,
            active: fan.components.leftFanOK.value,
          },"nic2":{
            noNic: _vm.labels.label2.value,
            designation: _vm.labels.designation.value,
            statusOk: _vm.labels.statusOk.value,
            statusNotOk: _vm.labels.statusNotOk.value,
            designationFan: _vm.labels.designationFan.value,
            noFan: fan.components.blockNumber.value,
            status: fan.components.nic2OK.value,
            active: fan.components.rightFanOK.value,
          }}})})],2)],1)],1),(_vm.currentEmulatorPane.components.bottomInfos)?_c('v-row',{staticClass:"cmc-color-title px-5"},[_c('v-col',{attrs:{"cols":"6","align-self":"end"}},[(_vm.currentEmulatorPane.components.bottomInfos.components.page)?_c('p',{staticClass:"no-gutters text-no-wrap align-self-end"},[_vm._v(" "+_vm._s(_vm.currentEmulatorPane.components.bottomInfos.components.page.value)+" ")]):_vm._e()]),_c('v-col',{attrs:{"cols":"6"}},[(_vm.currentEmulatorPane.components.bottomInfos.components.selectBack)?_c('p',{staticClass:"pb-0 mb-0 no-gutters text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currentEmulatorPane.components.bottomInfos.components.selectBack.value)+" ")]):_vm._e(),(_vm.currentEmulatorPane.components.bottomInfos.components.selectForward)?_c('p',{staticClass:"no-gutters text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currentEmulatorPane.components.bottomInfos.components.selectForward.value)+" ")]):_vm._e()])],1):_vm._e(),(!_vm.currentEmulatorPane.components.bottomInfos)?_c('v-row'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }