





























































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponent, EmulatorNode } from '@/models/EmulatorModels';
import ListItemCardTitle from '@/components/shared/ListItemCardTitle.vue';
import { EmulatorComponentType } from '@/enums/emulator';
import { getEmulatorComponentTypeKey } from '@/utils/labels_utils';
import ElementsCountCard from '@/components/shared/ElementsCountCard.vue';
import EmulatorComponentForm from '@/components/editor/forms/EmulatorComponentForm.vue';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';

@Component({
  components: {
    ListItemCardTitle,
    ElementsCountCard,
    EmulatorComponentForm,
    ConfirmationModal,
  },
})
export default class EmulatorComponentListItem extends Vue {
  public isConfirmationOpen = false;
  public isEditingFormOpen: boolean = false;

  public get isList(): boolean {
    let isList: boolean = false;
    if (this.component.components) {
      isList = this.component.type === EmulatorComponentType.LIST
        || Object.keys(this.component.components!).length >= 1;
    } else if ((this.component.type !== EmulatorComponentType.TEXT &&
      this.component.type !== EmulatorComponentType.SIDE_MENU_ITEM &&
      this.component.type !== EmulatorComponentType.BUTTON &&
      this.component.type !== EmulatorComponentType.BOOLEAN &&
      this.component.type !== EmulatorComponentType.LIST_ITEM &&
      this.component.type !== EmulatorComponentType.TABLE &&
      this.component.type !== EmulatorComponentType.TABLE_HEADER &&
      this.component.type !== EmulatorComponentType.TABLE_BODY &&
      this.component.type !== EmulatorComponentType.TABLE_ROW &&
      this.component.type !== EmulatorComponentType.TABLE_ROW_ITEM &&
      this.component.type !== EmulatorComponentType.LIST_GAUGE &&
      this.component.type !== EmulatorComponentType.GAUGE &&
      this.component.type !== EmulatorComponentType.IMAGE)
    ) {
      isList = true;
    }
    return isList;
  }

  @Prop({
    required: true,
  })
  public component!: EmulatorComponent;

  @Prop({
    required: true,
  })
  public rendererName!: string;

  public get chips(): string[] {
    return [
      this.getTypeLabel(this.component.type as EmulatorComponentType),
    ];
  }

  public onCloseHandler() {
    this.isEditingFormOpen = false;
  }

  public onNewComponent(component: EmulatorComponent) {
    this.onDataChanged(this.rendererName, component);
    this.isEditingFormOpen = false;
  }

  @Emit('openComponents')
  public openComponents(): EmulatorNode {
    return {
      component: this.component,
      key: this.rendererName,
    };
  }

  @Emit('onDataChanged')
  public onDataChanged(key: string, component?: EmulatorComponent): EmulatorNode {
    return {
      key,
      component,
    };
  }

  public deleteEmulatorClick() {
    this.isConfirmationOpen = true;
  }

  public onDeleteConfirmation(isConfirmed: boolean): void {
    if (isConfirmed) {
      this.onDataChanged(this.rendererName);
    }
    this.isConfirmationOpen = false;
  }

  private getTypeLabel(type: EmulatorComponentType): string {
    return this.$t(getEmulatorComponentTypeKey(type)) as string;
  }
}
