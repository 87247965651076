




























































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponentStatus, EmulatorComponentType, EmulatorPaneType } from '@/enums/emulator';
import { namespace } from 'vuex-class';
import { Emulator, EmulatorComponent, EmulatorTrigger, EmulatorVar, EmulatorStyle } from '@/models/EmulatorModels';
import { getEmulatorComponentTypeKey, getEmulatorPaneTypeKey, getStatusKey } from '@/utils/labels_utils';
import EmulatorTriggersList from '@/components/editor/forms/complexListControl/EmulatorTriggersList.vue';
import EmulatorViewConditionList from '@/components/editor/forms/complexListControl/EmulatorViewConditionList.vue';

const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    EmulatorTriggersList,
    EmulatorViewConditionList,
  },
})
export default class EmulatorComponentForm extends Vue {

  public isFormSaving: boolean = false;
  public isFullEditMode: boolean = false;

  public isFormValid: boolean = false;
  public id: string = '';
  public name: string = '';
  public typeComponent: string = '';
  public value: any = '';
  public statuses: any[] = [];
  public triggers: EmulatorTrigger[] = [];
  public viewConditions: EmulatorVar[] = [];
  public style: EmulatorStyle = {};

  public requiredRules: any[] = [
    (v) => !!v || this.getErrorMessage('common.error.fieldRequired'),
  ];

  @Prop({})
  public component!: EmulatorComponent | null;

  @Prop({})
  public rendererName!: string;

  @Prop({
    default: false,
  })
  public isPane!: boolean;

  @Prop({
    default: false,
  })
  public isEditMode!: boolean;

  public get paneTypesList(): string[] {
    const tableEnum: any[] = [];
    for (const enumMember in EmulatorPaneType) {
      if (enumMember) {
        tableEnum.push({
          id: EmulatorPaneType[enumMember],
          label: this.$t(getEmulatorPaneTypeKey(EmulatorPaneType[enumMember])),
        });
      }
    }
    return tableEnum;
  }

  public get componentTypesList(): string[] {
    const tableEnum: any[] = [];
    for (const enumMember in EmulatorComponentType) {
      if (enumMember) {
        tableEnum.push({
          id: EmulatorComponentType[enumMember],
          label: this.$t(getEmulatorComponentTypeKey(EmulatorComponentType[enumMember])),
        });
      }
    }
    return tableEnum;
  }

  public get statusesList(): string[] {
    const tableEnum: any[] = [];
    for (const enumMember in EmulatorComponentStatus) {
      if (enumMember) {
        tableEnum.push({
          id: EmulatorComponentStatus[enumMember],
          label: this.$t(getStatusKey(EmulatorComponentStatus[enumMember])),
        });
      }
    }
    return tableEnum;
  }

  public get currentStatuses(): any[] {
    let statusesItems: any[] = [];
    if (this.component && this.component.statuses) {
      statusesItems = this.component.statuses.map((status: EmulatorComponentStatus) => {
        return {
          id: status,
          label: this.$t(getStatusKey(status)),
        };
      });
    }
    return statusesItems;
  }

  private get parsedValue(): any {
    if (this.typeComponent === EmulatorComponentType.BOOLEAN as string ||
      this.typeComponent === EmulatorComponentType.INDICATOR as string ||
      this.typeComponent === EmulatorComponentType.ROUND_INDICATOR as string ||
      this.typeComponent === EmulatorComponentType.RECTANGLE_INDICATOR as string) {
      return this.value.toString() === 'true';
    }
    return this.value;
  }

  @emulatorModule.Getter
  public emulators!: Emulator[];

  @emulatorModule.Getter
  public currentEmulator!: Emulator | null;

  @emulatorModule.Mutation
  public setCurrentEmulator!: (Emulator) => void;

  @emulatorModule.Action
  public createEmulator!: (emulator: Emulator) => Promise<void>;

  @emulatorModule.Action
  public fetchEmulators!: () => Promise<void>;

  @emulatorModule.Action
  public fetchEmulator!: (emulatorId: string) => Promise<void>;

  public getErrorMessage(key: string, args?: any): string {
    return this.$t(key, args) as string;
  }

  public async onSaveClick() {
    this.isFormValid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.isFormValid) {
      this.isFormSaving = true;
      const newComponent: EmulatorComponent = {
        id: this.id,
        name: this.name,
        type: this.typeComponent,
        value: this.parsedValue,
        statuses: this.statuses.map((type: any) => {
          return type!.id;
        }),
        components: this.component && this.component.components ? this.component.components : {},
        triggers: this.triggers as EmulatorTrigger[],
        viewConditions: this.viewConditions as EmulatorVar[],
        style: this.style as EmulatorStyle,
      };
      this.onNewComponent(newComponent);
      this.resetForm();
    }
  }

  @Emit('onCloseHandler')
  public onCloseHandler() {
    this.isFormSaving = false;
    this.resetForm();
    return;
  }

  @Emit('onNewComponent')
  public onNewComponent(newComponent: EmulatorComponent): EmulatorComponent {
    return newComponent;
  }

  protected created() {
    if (this.component) {
      this.id = this.rendererName ? this.rendererName : '';
      this.name = this.component.name ? this.component.name : '';
      this.typeComponent = this.component.type as string;
      this.value = this.component.value !== undefined ? this.component.value : '';
      this.statuses = this.currentStatuses;
      this.triggers = this.component.triggers ? this.component.triggers : [];
      this.viewConditions = this.component.viewConditions ? this.component.viewConditions : [];
      this.style = this.component.style ? this.component.style : {};
    }
  }

  private resetForm() {
    this.isFormValid = (this.$refs.form as Vue & { reset: () => boolean }).reset();
  }
}
