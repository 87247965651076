












































import { Component, Prop, Vue } from 'vue-property-decorator';
import ResizableAddItemCard from '@/components/shared/ResizableAddItemCard.vue';
import { namespace } from 'vuex-class';
import EmulatorPanesListItem from '@/components/editor/panesList/EmulatorPanesListItem.vue';
import { Emulator, EmulatorComponent, EmulatorComponentsMap } from '@/models/EmulatorModels';
import EmulatorComponentForm from '@/components/editor/forms/EmulatorComponentForm.vue';

const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    ResizableAddItemCard,
    EmulatorPanesListItem,
    EmulatorComponentForm,
  },
})
export default class EmulatorsPanesList extends Vue {

  @Prop()
  public title!: string;

  @emulatorModule.Getter
  public currentEmulator!: Emulator;

  @emulatorModule.Action
  public createEmulator!: (emulator: Emulator) => Promise<void>;

  @emulatorModule.Action
  public fetchEmulator!: (emulatorId: string) => Promise<void>;

  public isNewPaneFormOpen: boolean = false;

  @Prop()
  public panes!: EmulatorComponentsMap;

  public onAddItemHandler(): void {
    this.isNewPaneFormOpen = true;
  }

  public async onNewComponent(component: EmulatorComponent): Promise<void> {
    const saveEmulator = this.currentEmulator;
    saveEmulator.panes![component.id!] = component;
    await this.createEmulator(saveEmulator);
    await this.fetchEmulator(this.currentEmulator.id);
    this.isNewPaneFormOpen = false;
  }

  public onCloseEditHandler(): void {
    this.isNewPaneFormOpen = false;
  }
}
