import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class ResponsiveMixin extends Vue {

  private get isTablet(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  private get popUpWidth(): string {
    return this.isTablet ? '80vw' : '30vw';
  }
}
