















































































































import { Component, Emit, Prop } from 'vue-property-decorator';
import { Emulator } from '@/models/EmulatorModels';
import { namespace } from 'vuex-class';
import Loader from '@/components/shared/Loader.vue';
import { EmulatorView } from '@/enums/emulator';
import { SearchType } from '@/enums/globalUI';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';
import EmulatorGrid from './EmulatorGrid.vue';
import EmulatorTable from './EmulatorTable.vue';
import { SearchObject } from '@/models/GlobalModels';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import UserMixin from '@/mixins/UserMixin';
import { User } from '@/models/UserModels';
import { mixins } from 'vue-class-component';

const emulatorModule = namespace('EmulatorModule');
const userModule = namespace('UserModule');
const searchModule = namespace('SearchModule');

@Component({
  components: {
    EmulatorGrid,
    EmulatorTable,
    ConfirmationModal,
    Loader,
  },
})
export default class EmulatorsList extends mixins(ResponsiveMixin, UserMixin) {

  @Prop({
    default: false,
  })
  public canEdit!: boolean;

  @Prop({
    default: false,
  })
  public isBase!: boolean;

  @Prop({
    default: true,
  })
  public isPaginated!: boolean;

  @userModule.Getter
  public userRoles!: { [index: string]: string[] };

  @emulatorModule.Getter
  public selectedEmulators!: Emulator[];

  @emulatorModule.Getter
  public currentUsersEmulators!: Emulator[];

  @emulatorModule.Action
  public fetchEmulatorsForUser!: (user: User) => Promise<void>;

  @emulatorModule.Action
  public deleteEmulator!: (emulator: Emulator) => Promise<void>;

  @searchModule.Getter
  public searchItems!: Emulator[];

  @searchModule.Getter
  public totalElements!: number;

  @searchModule.Getter
  public totalPages!: number;

  @searchModule.Getter
  public actualPage!: number;

  @searchModule.Getter
  public itemsPerPageOptions!: number;

  @emulatorModule.Getter
  public itemsPerPageEmulator!: number;

  @searchModule.Mutation
  public setActualPage!: (page: number) => void;

  @searchModule.Mutation
  public setItemsPerPage!: (itemsPerPage: number) => void;

  @emulatorModule.Mutation
  public setItemsPerPageEmulator!: (itemsPerPage: number) => void;

  @searchModule.Action
  public fetchItemsPerPage!: (searchType: SearchType) => Promise<void>;

  @searchModule.Action
  public searchItemsReq!: (search: SearchObject) => Promise<void>;

  private emulatorView: EmulatorView = EmulatorView.GRID;
  private isLoadingEmulators: boolean = false;

  // List
  private deleteTargetEmulator!: Emulator;
  private isListViewDeleteDialogVisible = false;
  private search = '';
  private delayTimer: number = -1;

  public get selectItemPerPage() {
    return this.itemsPerPageEmulator;
  }

  @Emit('onAddItemHandler')
  public onAddItemHandler() {
    return;
  }

  @Emit('onPreviewHandler')
  public onPreviewHandler(emulator: Emulator) {
    return emulator;
  }

  @Emit('onDownloadHandler')
  public onDownloadHandler(emulator: Emulator) {
    return emulator;
  }

  public created() {
    this.setItemsPerPage(this.itemsPerPageEmulator);
    this.fetchData();
  }

  public onDeleteConfirmationHandler(isDeleted: boolean) {
    if (isDeleted) {
      this.deleteEmulator(this.deleteTargetEmulator).then(() => {
        this.fetchData();
      });
    }

    this.isListViewDeleteDialogVisible = false;
  }

  private onDeleteEmulatorClick(targetEmulator: Emulator) {
    this.deleteTargetEmulator = targetEmulator;
    this.isListViewDeleteDialogVisible = true;
  }

  private changeView() {
    if (this.emulatorView === EmulatorView.GRID) {
      this.emulatorView = EmulatorView.LIST;
    } else {
      this.emulatorView = EmulatorView.GRID;
    }
  }

  // Grid
  private updateItemsPerPage(newValue: number | string) {
    let itemsPerPage: number = 0;
    if (newValue !== 'ALL') {
      itemsPerPage = typeof newValue === 'string' ? parseInt(newValue, 10) : newValue;
    } else {
      itemsPerPage = this.totalElements;
    }
    this.setItemsPerPageEmulator(itemsPerPage);
    this.setItemsPerPage(this.itemsPerPageEmulator);
    this.setActualPage(1);
    this.fetchData();
  }

  private nextPage() {
    if (this.actualPage + 1 <= this.totalPages) {
      this.setActualPage(this.actualPage + 1);
      this.fetchData();
    }
  }

  private previousPage() {
    if (this.actualPage - 1 >= 1) {
      this.setActualPage(this.actualPage - 1);
      this.fetchData();
    }
  }

  private async fetchData() {
    this.isLoadingEmulators = true;
    if (this.isBase) {
      await this.fetchEmulatorsForUser(this.currentUser!);
      this.isLoadingEmulators = false;
    } else {
      await this.fetchItemsPerPage(SearchType.EMULATOR);
      this.isLoadingEmulators = false;
    }
  }

  // List
  private getHeaders() {
    return [
      { text: this.$t('emulatorView.list.aircraft'), value: 'aircraftModel' },
      { text: this.$t('emulatorView.list.title'), value: 'title' },
      { text: this.$t('emulatorView.list.cloneOf'), value: 'cloneId' },
      { text: this.$t('emulatorView.list.actions'), value: 'actions' },
    ];
  }

  private async searchEmulator(search: any) {
    const self = this;
    if (this.delayTimer !== -1) {
      clearTimeout(this.delayTimer);
      this.delayTimer = -1;
    }
    this.delayTimer = window.setTimeout(() => {
      self.isLoadingEmulators = true;
      const searchItem: SearchObject = {
        type: SearchType.EMULATOR,
        param: search,
      };
      if (search !== '') {
        self.searchItemsReq(searchItem)
          .then(() => self.isLoadingEmulators = false);
      } else {
        self.fetchData();
      }
    }, 1000);
  }

}
