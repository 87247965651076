import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { User } from '@/models/AuthenticationModels';
import { Emulator } from '@/models/EmulatorModels';
import { SearchType } from '@/enums/globalUI';
import { SearchObject } from '@/models/GlobalModels';
import { CourseInfo, CourseTemplate } from '@/models/CoursesModels';

@Module({ namespaced: true, name: 'SearchModule' })
export default class SearchModule extends VuexModule {
  private $searchItems: User[] | Emulator[] | CourseTemplate[] | CourseInfo[] = [];
  private $last: boolean = false;
  private $totalElements: number = 0;
  private $totalPages: number = 0;
  private $actualPage: number = 1;
  private $itemsPerPage: number = 20;
  private $itemsPerPageOptions: string[] = ['5', '10', '20', '40', 'ALL'];

  public get searchItems(): User[] | Emulator[] | CourseTemplate[] | CourseInfo[] {
    return this.$searchItems;
  }

  public get last(): boolean {
    return this.$last;
  }

  public get totalElements(): number {
    return this.$totalElements;
  }

  public get totalPages(): number {
    return this.$totalPages;
  }

  public get actualPage(): number {
    return this.$actualPage;
  }

  public get itemsPerPage(): number {
    return this.$itemsPerPage;
  }

  public get itemsPerPageOptions(): string[] {
    return this.$itemsPerPageOptions;
  }

  @Mutation
  public setCurrentItems(searchItems: User[] | Emulator[] | CourseTemplate[] | CourseInfo[]) {
    this.$searchItems = searchItems;
  }

  @Mutation
  public isLast(last: boolean) {
    this.$last = last;
  }

  @Mutation
  public setTotalElements(totalElements: number) {
    this.$totalElements = totalElements;
  }

  @Mutation
  public setTotalPages(totalPages: number) {
    this.$totalPages = totalPages;
  }

  @Mutation
  public setActualPage(actualPage: number) {
    this.$actualPage = actualPage;
  }
  @Mutation
  public setItemsPerPage(itemsPerPage: number) {
    this.$itemsPerPage = itemsPerPage;
  }

  @Mutation
  public setItemsPerPageOptions(itemsPerPageOptions: string[]) {
    this.$itemsPerPageOptions = itemsPerPageOptions;
  }

  @Mutation
  public setState(state) {
    this.$searchItems = state.content;
    this.$last = state.last;
    this.$totalElements = state.totalElements;
    this.$totalPages = state.totalPages;
    this.$actualPage = state.number + 1;
  }

  @Action({ commit: 'setState' })
  public async fetchItemsPerPage(searchType: SearchType): Promise<void> {
    const request = `/${searchType}?size=${this.context.getters.itemsPerPage}&page=${(this.context.getters.actualPage - 1)}&sort=creationDate`;
    try {
      const { data } = await axios.get(request);
      return data;
    } catch (e) {
      // eslint-disable-next-line
      console.error('Cannot search', e);
    }
  }

  @Action({ commit: 'setState' })
  public async searchItemsReq(search: SearchObject): Promise<void> {
    const request = `/${search.type}/quicksearch/${search.param}`;
    try {
      const { data } = await axios.get(request);
      return data;
    } catch (e) {
      // eslint-disable-next-line
      console.error('Cannot search', e);
    }
  }

  @Action({ commit: 'setState' })
  public async fetchItemsPerSearchWord(info: { searchType: SearchType, params: string }): Promise<void> {
    // const request = `/${info.searchType}?size=100&page=0&${info.params}`;
    const request = `/${info.searchType}?size=100&page=0`;
    try {
      const { data } = await axios.get(request);
      return data;
    } catch (e) {
      // eslint-disable-next-line
      console.error('Cannot search', e);
    }
  }
}
