
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmulatorComponentsMap } from '@/models/EmulatorModels';
import CMCSideMenu from './CMCSideMenu.vue';

@Component({
  components: {
    CMCSideMenu,
  },
})
export default class CMCLayoutSideMenu extends Vue {
  @Prop({
    default: '',
  })
  private title!: string;
  @Prop({
    default: 0,
  })
  private currentPage!: number;
  @Prop({
    default: 0,
  })
  private totalPages!: number;
  @Prop({})
  private sideMenuData!: EmulatorComponentsMap;

  get paginationLabel(): string {
    let label: string = '';
    if (this.currentPage !== 0 && this.totalPages !== 0) {
      label = `${this.currentPage}/${this.totalPages}`;
    }
    return label;
  }
}
