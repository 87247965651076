















































































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Emulator, EmulatorComponent } from '@/models/EmulatorModels';
import CMCEmulator from '@/components/cmc/CMCEmulator.vue';
import { namespace } from 'vuex-class';
import TestModeSwitch from '@/components/cmc/controls/TestModeSwitch.vue';
import CMCCaptionEmulator from '@/components/cmc/caption-emulator/CMCCaptionEmulator.vue';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import { mixins } from 'vue-class-component';

const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    CMCEmulator,
    TestModeSwitch,
    CMCCaptionEmulator,
  },
})
export default class EmulatorView extends mixins(ResponsiveMixin) {
  @emulatorModule.Action
  public closeCurrentEmulator!: () => void;

  @Prop({
    required: true,
    default: false,
  })
  public isLoading!: boolean;

  @Prop({
    default: true,
  })
  public isMinimizable!: boolean;

  @Prop({
    required: true,
  })
  public currentEmulator!: Emulator | null;

  @Prop({
    required: true,
  })
  public currentEmulatorPane!: EmulatorComponent | null;

  @emulatorModule.Getter
  public onEndLesson!: boolean;

  public openTools: boolean = false;

  get isCMCReady(): boolean {
    return this.currentEmulator !== null
      && this.currentEmulatorPane !== null;
  }

  @Emit('handleCloseViewer')
  public async closeViewer(): Promise<void> {
    await this.closeCurrentEmulator();
  }

  @Watch('onEndLesson', { immediate: true, deep: true })
  public callHandleCloseViewer(): void {
    if (this.onEndLesson) {
      this.closeViewer();
    }
  }
}
