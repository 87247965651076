






































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export default class DateStatus extends Vue {

  @Prop({
    default: false,
  })
  private isDense!: boolean;

  @Prop({
    required: true,
  })
  private startDate!: string;

  @Prop({
    required: true,
  })
  private endDate!: string;

  private get statusLabel(): string {
    let label: string = '';

    if (moment(this.startDate).isAfter(Date.now())) {
      label = this.$t('dateStatus.notStarted') as string;
    } else if (moment(this.startDate).isBefore(Date.now())
      && moment(this.endDate).add(1, 'days').isAfter(Date.now())) {
      label = this.$t('dateStatus.inProgress') as string;
    } else if (moment(this.endDate).add(1, 'days').isBefore(Date.now())) {
      label = this.$t('dateStatus.hadEnded') as string;
    }
    return label;
  }

  private get statusClasses(): string {
    let classes: string = '';

    if (moment(this.startDate).isAfter(Date.now())) {
      classes = 'info--text darken-4';
    } else if (moment(this.startDate).isBefore(Date.now())
      && moment(this.endDate).add(1, 'days').isAfter(Date.now())) {
      classes = 'success--text';
    } else if (moment(this.endDate).add(1, 'days').isBefore(Date.now())) {
      classes = 'primary--text';
    }
    return classes;
  }

  private formattedDate(date: string): string {
    return moment(date).format('LL');
  }
}
