



























































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { UISnackBar } from '@/models/UIModels';
import { SignInUser } from '@/models/AuthenticationModels';
import RuleUtility from '@/utils/rules';
import LanguageSwitch from '@/components/shared/LanguageSwitch.vue';
import TheFooter from '@/components/shared/TheFooter.vue';
import { UserProfile } from '@/models/UserModels';
import UserMixin from '@/mixins/UserMixin';
import { mixins } from 'vue-class-component';

const auth = namespace('AuthModule');
const globalUI = namespace('GlobalUIModule');
const userModule = namespace('UserModule');

@Component({
  components: {
    LanguageSwitch,
    TheFooter,
  },
})
export default class SignIn extends mixins(UserMixin) {

  private get isProd(): boolean {
    return process.env.NODE_ENV === 'production';
  }

  @userModule.Action
  public fetchCurrentUser!: () => Promise<UserProfile>;

  @auth.Action
  public login!: (authData: SignInUser) => Promise<void>;

  @auth.Getter
  public isUserToCompleteNotNull!: boolean;

  @globalUI.Action
  public showSnackBar!: (payload: UISnackBar) => void;

  private ruleUtility = RuleUtility;

  private valid: boolean = false;
  private email: string = '';
  private password: string = '';

  public async validate(): Promise<void> {
    const formData = {
      email: this.email.toLowerCase(),
      password: this.password,
    };
    try {
      await this.login(formData);
      if (this.isAuthenticated && !this.isGuest) {
        await this.$router.replace(this.isAdmin ? '/user-manager' : '/dashboard');
      } else if(!this.isUserToCompleteNotNull){
        await this.$router.replace('/noaccess');
      }
    } catch (error) {
      // removing trace
    }
  }

  private reset(): void {
    (this.$refs.form as HTMLFormElement).reset();
  }
}
