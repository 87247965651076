import Vue from 'vue';
import Vuex from 'vuex';

import AuthModule from '@/store/modules/auth';
import EmulatorModule from '@/store/modules/emulator';
import EvaluationModule from '@/store/modules/evaluation';
import GlobalUIModule from '@/store/modules/globalUI';
import NavigationReportModule from '@/store/modules/navigationReport';
import { getModule } from 'vuex-module-decorators';
import UserModule from '@/store/modules/user';
import CoursesTemplateModule from '@/store/modules/courseTemplates';
import SearchModule from '@/store/modules/search';
import ImageModule from '@/store/modules/image';
import ExternalViewModule from '@/store/modules/externalView';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    AuthModule,
    GlobalUIModule,
    EmulatorModule,
    ExternalViewModule,
    EvaluationModule,
    UserModule,
    NavigationReportModule,
    CoursesTemplateModule,
    SearchModule,
    ImageModule,
  },
  state: {},
  mutations: {},
  getters: {},
  actions: {},
});

export default store;
export const authModule = getModule(AuthModule, store);
export const globalUIModule = getModule(GlobalUIModule, store);
export const emulatorModule = getModule(EmulatorModule, store);
export const evaluationModule = getModule(EvaluationModule, store);
export const userModule = getModule(UserModule, store);
export const navigationReportModule = getModule(NavigationReportModule, store);
export const coursesTemplateModule = getModule(CoursesTemplateModule, store);
export const searchModule = getModule(SearchModule, store);
export const mediaImageModule = getModule(ImageModule, store);
export const externalViewModule = getModule(ExternalViewModule, store);
