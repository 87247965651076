
































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ToolbarTitle extends Vue {

  @Prop({
    required: true,
  })
  public title!: string;

  @Prop({
    default: false,
  })
  public canEdit!: boolean;
}
