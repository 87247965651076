import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#42213D',
        accent: '#E6F14A',
        secondary: '#274B68',
        success: '#4AAD52',
        info: '#98C3EB',
        warning: '#EEB902',
        error: '#B3001B',
        active_background: '#dfdfdf',
        emulator: '#683257',
      },
      dark: {
        primary: '#2969F2',
        accent: '#3E8989',
        secondary: '#66635B',
        success: '#4AAD52',
        info: '#98C3EB',
        warning: '#EEB902',
        error: '#B3001B',
        active_background: '#dfdfdf',
        emulator: '#101e63',
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: 'fa',
  },
});

//  Extra List Colors to use by Sections
//  * Remove from here if you officially use it somewhere

// #283044 (GunMetal)
// #313B72 (Deep KOAMARU)
// #4B3F72 (Independance)
// #A49E8D (Grullo)
// #493548 (Purple Taupe)
