export interface User {
  id: string;
  enabled: boolean;
  roles: { [index: string]: string[] };
  email: string;
  givenName: string;
  studentId: string;
  privateEmulatorIds: string[];
  courseIds: string[];
  aircraftIds: string[];
  profileExists: boolean;
  creationDate?: Date;
  lastUpdate?: Date;
}

export interface UserProfile {
  firstName: string;
  lastName: string;
  avatar?: string;
  company: string;
  preferences?: Map<string, string>;
  isOnboardingDone: boolean;
}

export interface UpdateUserProfile {
  firstName: string;
  lastName: string;
  avatar?: string;
  company: string;
  preferences?: Map<string, string>;
  isOnboardingDone: boolean;
}

export interface UpdateUserParams {
  emulatorIds?: string[];
  courseIds?: string[];
  profile: UpdateUserProfile;
}

export interface UserPage {
  size: number;
  page: number;
  totalNumberOfElements: number;
  totalNumberOfPages: number;
  content: User[];
}

export interface UserSearchParams {
  pageSize: number;
  pageNumber: number;
  userId: string[];
  emulatorIds: string[];
  courseIds: string[];
}

export interface UserAction {
  type: UserActionType;
}

export enum UserActionType {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  DELETE = 'DELETE',
}

export interface CreateUserParams {
  username: string;
  temporaryPassword: string;
  studentId: string;
  roles: { [index: string]: string[] };
  isAlreadyCreated: boolean;
}

export interface UpdateUserRolesParams {
  userId: string;
  roles: { [index: string]: string[] };
}

export interface UpdateUserAircraftParams {
  userId: string;
  privateEmulatorIds: string[];
}

export interface AddUserRolesParams {
  userIds: string[];
  roles: { [index: string]: string[] };
}

export interface RemoveUserRolesParams {
  userIds: string[];
  roles: { [index: string]: string[] };
}

// User Manager (still some things above used by the User Manager)
export interface UserRoleLine {
  role: string;
  aircraft: string[];
}
