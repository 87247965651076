












































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import ResizableAddItemCard from '@/components/shared/ResizableAddItemCard.vue';
import EmulatorsListCardItem from '@/components/editor/emulatorsList/EmulatorsListCardItem.vue';
import { Emulator } from '@/models/EmulatorModels';
import PaginationFooter from '@/components/paginationFooter/PaginationFooter.vue';

@Component({
  components: {
    ResizableAddItemCard,
    EmulatorsListCardItem,
    PaginationFooter,
  },
})
export default class EmulatorGrid extends Vue {

  @Prop({
    required: true,
  })
  public canEdit!: boolean;

  @Prop({
    default: false,
  })
  public isCompact!: boolean;

  @Prop({
    required: true,
  })
  public searchItems!: Emulator[];

  @Prop({
    required: true,
  })
  public actualPage!: number;

  @Prop({
    required: true,
  })
  public selectItemPerPage!: number;

  @Prop({
    required: true,
  })
  public itemsPerPageOptions!: string[];

  @Prop({
    required: true,
  })
  public totalPages!: number;

  @Prop({
    required: true,
  })
  public totalElements!: number;

  @Emit('onAddItemHandler')
  private onAddItemHandler() {
    return;
  }

  @Emit('onPreviewHandler')
  private onPreviewHandler(emulator: Emulator) {
    return emulator;
  }

  @Emit('onDownloadHandler')
  private onDownloadHandler(emulator: Emulator) {
    return emulator;
  }

  @Emit('updateItemsPerPage')
  private updateItemsPerPage(newValue: string) {
    return newValue;
  }

  @Emit('nextPage')
  private nextPage() {
    return this.actualPage + 1;
  }

  @Emit('previousPage')
  private previousPage() {
    return this.actualPage + 1;
  }
}
