



















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { getImagePath, getLessonTypeKey } from '@/utils/labels_utils';
import { Lesson } from '@/models/CoursesModels';
import { Emulator, EmulatorVar } from '@/models/EmulatorModels';
import AircraftType from '@/enums/user/aircraft';
import { Session } from '@/models/EvaluationModels';
import TestModeSwitch from '@/components/cmc/controls/TestModeSwitch.vue';
import _ from 'lodash';
import { LessonType } from '@/enums/courses';

const coursesTemplateModule = namespace('CoursesTemplateModule');
const emulatorModule = namespace('EmulatorModule');
const evaluationModule = namespace('EvaluationModule');

@Component({
  components: {
    TestModeSwitch,
  },
})
export default class CMCCaptionEmulator extends Vue {
  @Prop({
    required: true,
  })
  public currentEmulator!: Emulator;

  @coursesTemplateModule.Action
  public fetchLessonById!: (lessonId: string) => Promise<Lesson | null>;

  @emulatorModule.Getter
  public currentEmulatorState!: EmulatorVar[];

  @emulatorModule.Mutation
  public setOnEndLesson!: (isEndLessonClicked: boolean) => void;

  @emulatorModule.Action
  public changeCurrentState!: (state: EmulatorVar[]) => Promise<void>;

  @emulatorModule.Action
  public closeCurrentEmulator!: () => void;

  @evaluationModule.Getter
  public session!: Session;

  @evaluationModule.Action
  public updateSession!: (sessionObj: Session) => Promise<Session>;

  public lesson: Lesson | null = null;
  public dialog: boolean = false;

  private aircraftType = AircraftType;
  private switchValue: boolean = false;
  private TEST_MODE: string = 'TEST_MODE';

  public created() {
    if (this.session.lessonId) {
      this.fetchLessonById(this.session.lessonId).then((lesson) => this.lesson = lesson);
    }
    this.getTestModeState();
  }

  public get aircraftModel(): string {
    return this.currentEmulator && this.currentEmulator.aircraftModel ?
      this.aircraftType.getAircraftLabelByType(`${this.currentEmulator.aircraftModel}`) : '';
  }

  public get imagePath(): string {
    return getImagePath(this.currentEmulator);
  }

  public endLessonClick(): void {
    if (this.lesson && this.lesson.type !== LessonType.EXPLORATORY) {
      this.dialog = true;
    } else {
      this.endLesson();
    }
  }

  public async endLesson(): Promise<void> {
    const sessionState = { ...this.session, lessonCompletedOn: new Date(), lessonId: `${this.session.lessonId}` };
    await this.updateSession(sessionState)
      .then(() => {
        this.dialog = false;
        this.setOnEndLesson(true);
      });
  }

  private getTestModeState() {
    const state = _.filter(this.currentEmulatorState, ['name', this.TEST_MODE]);
    if (state.length === 1) {
      this.switchValue = state[0].value;
    }
  }

  private get lessonLabel(): string {
    return this.$t(getLessonTypeKey(this.lesson!.type)) as string;
  }
}
