






































import {Component} from 'vue-property-decorator';
import {Emulator} from '@/models/EmulatorModels';
import {namespace} from 'vuex-class';
import Loader from '@/components/shared/Loader.vue';
import EmulatorsList from '@/components/editor/emulatorsList/EmulatorsList.vue';
import EmulatorViewer from '@/components/viewers/EmulatorViewer.vue';
import UserMixin from '@/mixins/UserMixin';
import CoursesList from '@/components/coursesPlanner/lists/CoursesList.vue';
import {CourseInfo} from '@/models/CoursesModels';
import CoursesViewer from '@/components/dashboard/CoursesViewer.vue';
import NoDataAlert from '@/components/shared/NoDataAlert.vue';
import { mixins } from 'vue-class-component';

const coursesTemplateModule = namespace('CoursesTemplateModule');

@Component({
  components: {
    EmulatorsList,
    Loader,
    EmulatorViewer,
    CoursesList,
    CoursesViewer,
    NoDataAlert,
  },
})
export default class DashboardHome extends mixins(UserMixin) {
  public isEmulatorOpen: boolean = false;
  public currentEmulatorId: string = '';

  @coursesTemplateModule.Getter
  public isLoadingTemplates!: boolean;

  @coursesTemplateModule.Action
  public fetchCoursesInfos!: () => Promise<void>;

  @coursesTemplateModule.Mutation
  public setCoursesInfos!: (coursesInfos: CourseInfo[]) => Promise<void>;

  @coursesTemplateModule.Getter
  public coursesInfos!: CourseInfo[];

  public onPreviewHandler(emulator: Emulator): void {
    this.currentEmulatorId = emulator.id;
    this.isEmulatorOpen = true;
  }

  public onCloseViewer(): void {
    this.isEmulatorOpen = false;
    this.currentEmulatorId = '';
  }

  private created(): void {
    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    await this.fetchCoursesInfos();
  }

  private beforeDestroy(): void {
    this.setCoursesInfos([]);
  }
}
