

























































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CMCDataTable from '@/components/cmc/controls/CMCDataTable.vue';
import CMCIndicatorStatus from '@/components/cmc/controls/cmcIndicatorStatus/CMCIndicatorStatus.vue';
import { EmulatorComponent, EmulatorStyle } from '@/models/EmulatorModels';
import { itemHasStyles } from '@/utils/common';
import { EmulatorComponentType } from '@/enums/emulator';
import CMCTcasSystem from '@/components/cmc/controls/cmcTcasSystem/CMCTcasSystem.vue';

@Component({
  components: {
    CMCDataTable,
    CMCIndicatorStatus,
    CMCTcasSystem,
  },
})
export default class CMCTcasSystemPane extends Vue {
  private limitColumns: number = 9;

  @Prop({ required: true })
  private currentEmulatorPane!: EmulatorComponent;

  public getStyles(styles: EmulatorStyle): any {
    return itemHasStyles(styles);
  }

  public isTable(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE);
  }

  public isTcas(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TCAS);
  }

  public isTableHeader(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE_HEADER);
  }

  public isTableBody(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE_BODY);
  }

  public isRowItem(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TABLE_ROW_ITEM);
  }

  public isIndicator(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.INDICATOR ||
      type === EmulatorComponentType.ROUND_INDICATOR ||
      type === EmulatorComponentType.RECTANGLE_INDICATOR);
  }

  public isText(type: EmulatorComponentType): boolean {
    return (type === EmulatorComponentType.TEXT);
  }

  public getColSpan(style: any): number {
    return style?.css?.columnSpan || 0;
  }
}
