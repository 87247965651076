




































































































import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EmulatorComponentStatus } from '@/enums/emulator';
import { EmulatorComponent } from '@/models/EmulatorModels';
import { itemHasStatus } from '@/utils/common';
import ViewConditionsMixin from '@/components/cmc/mixins/ViewConditionsMixin';
import ClickNotSupportedMixin from '@/components/cmc/mixins/ClickNotSupportedMixin';
import KeyboardNavigationMixin from '@/components/cmc/mixins/KeyboardNavigationMixin';
import { mixins } from 'vue-class-component';

const emulatorModule = namespace('EmulatorModule');

@Component({})
export default class CMCMainMenuPane extends mixins(
  ViewConditionsMixin,
  ClickNotSupportedMixin,
  KeyboardNavigationMixin,
) {

  @emulatorModule.Mutation
  public setExpandedMenuItems!: (tree: any[]) => void;

  @Prop({
    required: true,
  })
  public currentEmulatorPane!: EmulatorComponent;

  public created(): void {
    this.setExpandedMenuItems([]);
  }

  public buttonClickHandler(input: EmulatorComponent): void {
    this.$emit('actionTriggered', this.getOverriddenTriggers(input));
  }

  public isDisabled(item: EmulatorComponent): boolean {
    return itemHasStatus(item, EmulatorComponentStatus.DISABLED);
  }

  public canDisplay(component: EmulatorComponent): boolean {
    if (component.viewConditions) {
      return this.evaluateViewConditions(component.viewConditions);
    }
    return true;
  }
}
