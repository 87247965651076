import { render, staticRenderFns } from "./CourseEmulatorsListItem.vue?vue&type=template&id=24e4149d&scoped=true&"
import script from "./CourseEmulatorsListItem.vue?vue&type=script&lang=ts&"
export * from "./CourseEmulatorsListItem.vue?vue&type=script&lang=ts&"
import style0 from "./CourseEmulatorsListItem.vue?vue&type=style&index=0&id=24e4149d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24e4149d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VIcon,VImg,VListGroup,VListItem,VListItemAction,VListItemAvatar,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VSwitch})
